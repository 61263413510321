import React from 'react'

const ScrollToTopButton = () => {
    return (
        <>
            {/* start::scroll-top-btn  */}
            <div className="scroll-top-btn transition-all opacity-0">
                <button type="button" className="bg-blue-500 p-2 fixed z-50 bottom-0 hover:-translate-y-2 transition-all right-0 m-10 rounded-full" aria-label="Scroll To Top">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12" />
                    </svg>
                </button>
            </div>
            {/* end::scroll-top-btn  */}
        </>
    )
}

export default ScrollToTopButton