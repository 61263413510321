import React, { useState } from 'react'
import './style.css';
import Alert from '../Alert';
import axios from 'axios';

const Newsletter = () => {
    const [email, setEmail] = useState("");

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 3000);
    }

    const Subscribe = () => {
        if (email.trim() !== "" && validateEmail(email.trim())) {
            //handleMessage({ type: 'danger', message: "You have subscribed for newsletter service", show: true });

            const json = JSON.stringify({ "email": email.trim() });
            const url = `${process.env.REACT_APP_BASE_API}/api.php?action=subscribe_newsletter`;

            axios.post(url, JSON.stringify({ params: json }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.data.success === "true") {
                    setEmail("");
                    handleMessage({ type: 'success', message: response.data.message, show: true });
                }
                else {
                    handleMessage({ type: 'danger', message: response.data.message, show: true });
                }
            }).catch((error) => {
                console.error(`Error: ${error}`);
            });
        }
        else {
            handleMessage({ type: 'danger', message: "Please provide your email", show: true });
        }
    }

    const validateEmail = (email) => {
        if (!email) {
            handleMessage({ type: 'danger', message: "Please provide your email", show: true });//errors.email = 'Required'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            handleMessage({ type: 'danger', message: "Please provide valid email address", show: true });//errors.email = 'Invalid email address'
        }
        else
            return true;
    }

    return (
        <>
            {/* start::newsletter  */}
            {/* <section>
                {alert.show && (<div className="mx-auto w-[405px] py-4">
                    <Alert type={alert.type} message={alert.message} show={alert.show} />
                </div>)}
                <div className="mx-auto">
                    <div className="grid lg:grid-cols-2 grid-cols-1 items-center">
                        <div className="lg:text-left text-center aos-init py-11 px-10" data-aos="fade-up" data-aos-duration={800} style={{ backgroundColor: "#1570ef" }}>
                            <div className='mx-auto' style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <h2 className="lg:text-3xl text-2xl font-medium capitalize mb-3 text-gray-100 dark:text-gray-100">
                                    Subscribe Our newsletter
                                </h2>
                                <p className="text-base font-normal mb-10 text-gray-100 dark:text-gray-200">
                                    Get important update to your email.
                                </p>
                                <div className="inline-flex bg-white w-[482px] max-w-full dark:bg-foreground text-gray-400 p-2.5 rounded-lg shadow-front-4 card">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-w-5 w-5 mx-2 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                    </svg>
                                    <input type="email" placeholder="Enter your email" className="focus:outline-none w-full dark:text-gray-100 dark:bg-foreground" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    <button type="button" className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-[11px] rounded-lg ml-0 lg:ml-10" onClick={() => Subscribe()}>
                                        Subscribe
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div data-aos="fade-up" data-aos-duration={800} className="aos-init" style={{ backgroundColor: "#fbda4b" }}>
                            <img className="max-w-full w-[405px] mx-auto" src="/img/newsletter.svg" alt="" />                            
                        </div>
                    </div>
                </div>
            </section> */}
            {/* end::newsletter  */}

            {/* <section>
                <div className="mx-auto">
                    <div className="grid lg:grid-cols-2 grid-cols-1 items-center">
                        <div className="lg:text-left text-center aos-init py-11 px-10" data-aos="fade-up" data-aos-duration={800} style={{ backgroundColor: "#fbda4b" }}>
                            <div className='mx-auto' style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                <h5 className="lg:text-3xl text-2xl font-medium capitalize mb-3 text-gray-800 dark:text-gray-800">
                                    Subscribe Our newsletter
                                </h5>
                                <p className="text-base font-normal mb-10 text-gray-200 dark:text-gray-200">
                                    Get important update to your email.
                                </p>
                                <div className="inline-flex bg-white w-[482px] max-w-full dark:bg-foreground text-gray-400 p-2.5 rounded-lg shadow-front-4 card">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-w-5 w-5 mx-2 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                    </svg>
                                    <input type="email" placeholder="Enter your email" className="focus:outline-none w-full dark:text-gray-100 dark:bg-foreground" />
                                    <button type="button" className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-[11px] rounded-lg ml-0 lg:ml-10">
                                        Subscribe
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div data-aos="fade-up" data-aos-duration={800} className="aos-init" style={{ backgroundColor: "#1570ef" }}>
                            <img className="max-w-full w-[405px] mx-auto" src="/img/newsletter.svg" alt="" />
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section>
                <div className="mx-auto">
                    <div className="grid lg:grid-cols-2 grid-cols-1 items-center">
                        <div className="lg:text-left text-center aos-init py-10 px-10 col-span-1" data-aos="fade-up" data-aos-duration={800} style={{ backgroundColor: "#1570ef" }}>
                            <div className='mx-auto' style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "1px" }}>
                                <h5 className="lg:text-3xl text-2xl font-medium capitalize mb-3 text-gray-100 dark:text-gray-100">
                                    Subscribe Our newsletter
                                </h5>
                                <p className="text-base font-normal text-gray-100 dark:text-gray-200">
                                    Get important update to your email.
                                </p>
                            </div>
                        </div>
                        <div data-aos="fade-up" data-aos-duration={800} className="col-span-1 py-11 px-10" style={{ backgroundColor: "#fbda4b" }}>                            
                            <div className="max-w-full w-[405px] mx-auto">
                                <div className="inline-flex bg-white w-[482px] max-w-full dark:bg-foreground text-gray-400 p-2.5 rounded-lg shadow-front-4 card">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-w-5 w-5 mx-2 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                    </svg>
                                    <input type="email" placeholder="Enter your email" className="focus:outline-none w-full dark:text-gray-100 dark:bg-foreground" />
                                    <button type="button" className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-[11px] rounded-lg ml-0 lg:ml-10">
                                        Subscribe
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section>
                {alert.show && (<div className="mx-auto w-[405px] py-4">
                    <Alert type={alert.type} message={alert.message} show={alert.show} />
                </div>)}
                <div className="mx-auto">
                    <div className="grid md:grid-cols-2 items-center" data-aos="fade-up" data-aos-duration={800} style={{ backgroundColor: "#1570ef" }}>
                        <div className="lg:text-left text-center aos-init custom-padding col-span-1">{/**py-11 px-10**/}
                            <div className='mx-auto newsletter-text'>
                                <h2 className="lg:text-3xl text-2xl font-medium capitalize mb-3 text-gray-100 dark:text-gray-100" style={{ marginBottom: ".79rem" }}>
                                    Subscribe Our newsletter
                                </h2>
                                <p className="text-base font-normal text-gray-100 dark:text-gray-200">
                                    Get important update to your email.
                                </p>
                            </div>
                        </div>
                        <div className="col-span-1 custom-padding">{/**py-11 px-10**/}
                            {/* <img  src="/img/newsletter.svg" alt="" /> */}
                            {/* <img className="max-w-full w-[405px] mx-auto card" src="/img/newsletter.png" alt="" /> */}
                            {/* <div className="mx-auto"> */}
                                <div className="inline-flex bg-white dark:bg-foreground text-gray-400 p-2.5 mx-auto rounded-lg shadow-front-4 card newsletter-input">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-w-5 w-5 mx-2 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                    </svg>
                                    <input type="email" placeholder="Enter your email" className="focus:outline-none w-full dark:text-gray-100 dark:bg-foreground" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    <button type="button" className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-[11px] rounded-lg ml-0 lg:ml-10" onClick={() => Subscribe()}>
                                        Subscribe
                                    </button>
                                </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Newsletter