import React from 'react'

const PageContentContainer = (props) => {
  return (
    <>
      {/* <div className="font-inter dark:bg-background">
        {props.children}
      </div> */}
      <div className="font-inter dark:bg-background"> {/**font-inter dark:bg-background sticky-header-first-content */}
        {props.children}
      </div>
    </>
  )
}

export default PageContentContainer