import React from 'react'

const AboutUsHero = () => {
    return (
        <div className="pt-10 container mx-auto pb-10">
            {/* <div className="lg:inline-flex flex flex-col justify-center border border-white rounded-lg bg-white bg-opacity-60 backdrop-filter backdrop-blur-lg py-5 px-5"> */}
            <div className="rounded-lg shadow-fornt-2 dark:bg-foreground py-10 px-10 bg-white bg-opacity-70 backdrop-filter backdrop-blur-lg mb-7">
                <div className="text-xl font-medium mb-4 text-gray-700 dark:text-gray-100">
                    About Us
                </div>
                <p className="text-gray-500 dark:text-gray-400 text-md mb-4">
                    Greetings from Opioid Rehab, a haven of healing and hope for anyone struggling with opioid addiction. Our goal is to lead each person on the road to recovery and a better future by providing all-encompassing, compassionate treatment that is individualized to meet their specific requirements.
                </p>

                <div className="text-lg font-medium mb-2 text-gray-700 dark:text-gray-100">
                    Our Method
                </div>
                <p className="text-gray-500 dark:text-gray-400 text-md mb-4">
                    We at Opioid Rehab are aware that beating opioid addiction is a difficult road. Because of this, we have created a treatment plan that addresses the mental, emotional, and physical components of addiction. Our team of seasoned experts is committed to providing assistance and direction at every turn.
                </p>

                <div className="text-lg font-medium mb-2 text-gray-700 dark:text-gray-100">
                    Why Opt for Us?
                </div>
                <p className="text-gray-500 dark:text-gray-400 text-md mb-4">
                    We at Opioid Rehab are a community of hope, understanding, and support rather than merely a treatment facility. Our team's dedication, evidence-based treatments, and compassionate care make us a reliable ally on your road to recovery. One step at a time, it is our commitment to helping you recover your life.
                </p>
                <p className="text-gray-500 dark:text-gray-400 text-md mb-4">
                    Opioid Rehab USA offers a range of services for individuals seeking recovery. They provide detox, residential, medication-assisted, virtual, and outpatient treatment. The detox program provides a safe, supervised environment for detoxification, while the residential treatment offers a structured environment for recovery.
                </p>
                <p className="text-gray-500 dark:text-gray-400 text-md mb-4">
                    Medication-assisted treatment combines FDA-approved medications with counseling and behavioral therapies to reduce cravings and withdrawal symptoms. Virtual treatment allows individuals to receive care from home, while outpatient treatment offers personalized care plans and flexible scheduling. Also, payment assistance programs are available to ensure access to quality care.
                </p>
            </div>
            {/* </div> */}
        </div>
    )
}

export default AboutUsHero