import React from 'react'
import {base64_encode} from '../../func';
import { Link } from 'react-router-dom';

const RecentPosts = ({ recentPosts, loading }) => {
    // console.log("recentPosts", recentPosts);
    // console.log("loading", loading);

    const createLoadingLayout = () => {
        return (
            <div className="shadow-front-2 dark:bg-foreground p-6 rounded-lg mb-8">
                <div className="mb-6 skeleton h-6"></div>

                <ul>
                    {[...Array(5)].map((u, index) => (
                        <li className="flex items-center mb-4" key={index}>
                            <div className="h-0 w-0 mr-2 rounded border-x-8 rotate-90 border-x-transparent border-b-[10px] border-b-gray-500 dark:border-b-gray-300" />
                            <div className='h-6 w-full skeleton' />
                        </li>
                    ))}
                </ul>
            </div>
        )
    }

    const createRecentPosts = () => {
        return (
            <div className="shadow-front-2 dark:bg-foreground p-6 rounded-lg mb-8">
                <h4 className="text-base font-medium mb-6 text-gray-700 dark:text-gray-100">
                    Recent Posts
                </h4>
                <ul>
                    {recentPosts.map((recentPost, index) => (
                        <li className="flex items-center text-gray-500 dark:text-gray-300 mb-4" key={index}>
                            <div className="h-0 w-0 mr-2 rounded border-x-8 rotate-90 border-x-transparent border-b-[10px] border-b-gray-500 dark:border-b-gray-300" />
                            <Link to={`/post/${base64_encode(recentPost.post_id.toString())}/${recentPost.post_slug}`} className='text-gray-700 dark:text-gray-100 hover:text-blue-500'>{recentPost.post_title}</Link>                            
                        </li>
                    ))}                    
                </ul>
            </div>
        )
    }

    return (
        loading ? createLoadingLayout(): createRecentPosts()
    )
}

export default RecentPosts