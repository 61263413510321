import React from 'react'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PageContentContainer from '../../components/PageContentContainer';

import ScrollToTopButton from '../../components/ScrollToTopButton';

// import businesses_list from "../../../business";
import FeaturedListing from '../../components/FeaturedListing';
import RehabSearchForm from '../../components/RehabSearchForm';

const ResidentialTreatment = () => {

  return (
    <>
      <Header />

      <PageContentContainer>

        <>
          <section className="dark:bg-background mb-12">
            <div className="relative">
              <img className="w-full object-cover h-52" src="/img/cover-2.png" alt="" />
              <div className="absolute top-1/2 w-full -translate-y-1/2 text-center mb-4">
                <h1 className="capitalize text-4xl text-white font-semibold">Residential Treatment</h1>
                <p className="text-white mt-2">Opioid and Heroin Addiction Treatment</p>
              </div>
            </div>
          </section>

          <div className="container mx-auto px-5">
            <div className="grid grid-cols-3 gap-7 pb-10">

              <div className="lg:col-span-2 col-span-3">
                <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7">
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-2.jpg" alt="" /> */}
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-2.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-3.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-4.jpg" alt="" /> */}
                  <div className="p-6 border-b border-gray-200 dark:border-gray-800">
                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      Hazelden Betty Ford's Response to the Opioid Epidemic—Comprehensive Opioid Response with the Twelve Steps (COR-12®)
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Our specialized COR-12 program includes opiate-specific group therapy, educational sessions and individual therapy. Our comprehensive approach also keeps you engaged in rehab activities over a longer period of time than most addiction treatment programs, and includes family involvement, recovery support groups and accountability as integral parts of COR-12 services. We know from experience that this combination of enhanced opioid rehab services, medication-assisted treatment, clinical interventions and active engagement over a longer period of time offers the best chance for long-term recovery.
                    </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      In order to plan the most effective substance use disorder treatment for each patient, our clinicians consider many factors, such as:
                    </p>

                    <ul className=" mb-5">
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          How long you’ve been using opioids, heroin or other opiate drugs
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          The amount and frequency of your substance use
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Whether you use alcohol other drugs in addition to opioids
                        </div>
                      </li>
                    </ul>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">These are just a few of the considerations that provide us with important information about each patient’s specific situation. In planning a rehab program tailored to meet your individual needs, we will also take into account your medical condition and any other physical or mental health issues you are experiencing.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">A quick note about opioid withdrawal symptoms: Many people with opioid dependence avoid going to rehab due to fears about the side effects of withdrawal. Don’t let such fears stop you from getting the help you need and deserve. Our clinicians know how to expertly guide you in safely and successfully managing the physical challenges of opioid withdrawal.</p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      How Do You Know if You’re Addicted to Opioids?
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">While there isn’t a blood test or other lab work to diagnose addiction, there are distinctive behavioral indicators that the disease has taken hold. If you obsess about getting the drug and using the drug and then spend the remainder of your time recovering from the effects of substance abuse, you’re probably looking at addiction. Other telltale signs include compromising your values, behaving in ways that put yourself or others at risk, and experiencing negative consequences in your relationships and other aspects of your life because of your use.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">If you're unsure about the extent of your problem, take a short inventory to get a better sense of how much your drug use is impacting your life.</p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      How Is Opiate Addiction Treated?
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Heroin and opioid treatment programs and services vary by provider and by the type and level of services needed to effectively address your particular situation. Here are some key components of successful opioid addiction treatment programs:</p>

                    <ul className=" mb-5">
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Medical detox
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Assessments including medical, mental health and substance abuse history
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Mental health services
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Medical services
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Medication-assisted treatment
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Group and one-to-one chemical health services
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Wellness and fitness
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Twelve Step Facilitation
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Nutritional counseling
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Individualized planning
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Family services
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Spiritual care
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Educational and experiential workshops
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Post-rehab planning
                        </div>
                      </li>
                    </ul>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">It’s also important to know that your pace through rehab won’t be identical to those around you. While there are common milestones in healing from opioid use disorder, your recovery path will be your own—based on your specific situation, challenges and needs. One or more of the following rehab levels may be recommended for you:</p>

                    <ul className=" mb-5">
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>Inpatient</b>—24/7 staffing and programming</div>
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>High-Intensity Outpatient Program</b>—20 or more hours of programming a week for four or more days per week with possible on-site sober housing options</div>
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>Intensive Outpatient Program (IOP)</b>—Usually starts with 12 hours of programming for four days per week but can lessen over time as you progress in your recovery</div>
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>Continuing Care</b>—This can be anywhere from one to eight hours a week depending on your needs</div>
                        </div>
                      </li>
                    </ul>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      How Long Is Rehab for Opioid and Heroin Addiction?
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      The length of time you participate in an opioid treatment program will be based on your individual needs. Our clinicians will work with you, your family and your insurance provider to come up with the best plan for you. Like diabetes or hypertension, addiction is a chronic disease. Regaining and maintaining your health means learning to manage your symptoms, first within the structure and support of a rehab setting and eventually in your home environment where you’ll be in charge of your sobriety.
                    </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      The latest scientific research on recovery from drug abuse identifies ongoing involvement in recovery-focused activities as the best predictor of long-term sobriety. Active engagement is especially important during your first 18 months of recovery when the risk of relapse is most intense.
                    </p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      How Much Does Treatment for Opiate and Heroin Addiction Cost?
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      The cost of treating opiate addiction depends on the provider you choose, the level of care recommended and your length of time in rehab. Your cost will also depend on whether you have insurance coverage for rehab or you are paying out-of-pocket. Hazelden Betty Ford is an in-network provider with most insurance carriers. The majority of our patients access insurance benefits to help cover the cost of treatment. Insurance policies and benefits vary greatly. As a non-profit treatment center, the Hazelden Betty Ford Foundation provides Patient Financial Assistance funds when available, on a limited basis, to help offset costs for qualifying patients. Learn more about insurance coverage options.
                    </p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      What Is the Best Treatment for Heroin Addiction?
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      In 2015, in recognition of the nation's emerging opioid addiction crisis—including an unprecedented epidemic of opioid overdose deaths —clinicians at Hazelden Betty Ford developed the Comprehensive Opioid Response with the Twelve Steps (COR-12®) program, a medication-assisted adjunct to our evidence-based Twelve Step treatment programs.
                    </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      If you are diagnosed with opioid use disorder, COR-12 may be recommended by your Hazelden Betty Ford clinical team to:
                    </p>

                    <ul className=" mb-5">
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Ease the discomfort of opioid withdrawal with the use of Suboxone (Buprenorphine)
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Reduce cravings
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Help you engage more successfully in rehab programming and activities
                        </div>
                      </li>
                    </ul>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      With COR-12, our immediate goal is to help you get through the discomfort of opioid withdrawal and reduce drug cravings. Further, we aim to provide our patients with personalized care that embraces multiple pathways to recovery and promotes sustained engagement in treatment to enhance their progress towards healing from addiction and life-long recovery.
                    </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Opioid treatment at Hazelden Betty Ford begins with a clinical team of addiction specialists who will develop a plan to address your specific recovery needs and challenges. In designing your rehab plan, this multidisciplinary team will consider multiple variables, including:
                    </p>

                    <ul className=" mb-5">
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Substances used
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Physical health
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Mental health
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Family relationships
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Gender
                        </div>
                      </li>
                    </ul>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      How Are Opiate Withdrawal and Addiction Treated?
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      At Hazelden Betty Ford, medications are used to ease withdrawal symptoms, if clinically indicated. Our medical experts will work with you to make withdrawal and detox as comfortable as possible.
                    </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Once your medical condition is stabilized, your clinical team will recommend the best COR-12 pathway for you. We provide three rehab paths for opioid use disorder:
                    </p>

                    <ul className=" mb-5">
                      <li className="mb-2">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <span className="mr-2">1.</span>
                          <div className="flex flex-col">
                            <b>No Medication</b>
                            You will be gradually tapered off of Suboxone® over a one- to two-week period while in residential rehab. You will participate in the same treatment therapies, activities and groups as other patients in the COR-12 program.
                          </div>
                        </div>
                      </li>
                      <li className="mb-2">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <span className="mr-2">2.</span>
                          <div className="flex flex-col">
                            <b>Buprenorphine/Naloxone (taken on a daily basis)</b>
                            Buprenorphine/naloxone (Suboxone®) is a combination of two medications in one film, which dissolves under the tongue. Buprenorphine is a “partial opioid agonist,” meaning it can block opioid withdrawal symptoms and cravings but does not have all the effects of other opioids. If taken by injection, the naloxone in Suboxone® prevents any euphoric effects or breathing problems. You can experience opioid withdrawal symptoms when you stop taking buprenorphine/naloxone but not as severely as you would if withdrawing from heroin use or other opioid/opiate drug use.
                          </div>
                        </div>
                      </li>
                      <li className="mb-2">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <span className="mr-2">3.</span>
                          <div className="flex flex-col">
                            <b>Extended-Release Naltrexone (injected every four weeks)</b>
                            Naltrexone is an “opioid receptor antagonist,” meaning it blocks the effect of opioids. If you use opioids while naltrexone is in your system, you will not get high. Naltrexone itself has no euphoric effects and does not cause dependence, withdrawal symptoms or breathing problems. It has been shown to reduce cravings and the likelihood of relapse. Extended-release naltrexone (Vivitrol®) is a formulation of naltrexone that is injected and slowly released.
                          </div>
                        </div>
                      </li>
                    </ul>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Many treatment centers use Methadone for opioid use disorder. Learn why Hazelden Betty Ford uses Suboxone rather than Methadone for opioid use disorder.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Do you have questions about medication-assisted treatment for heroin, opioid or opiate addiction? Read our FAQs on medication-assisted treatment.</p>

                    {/* <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      Opioid Addiction Treatment Facilities
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Hazelden Betty Ford opioid treatment programs and services are offered at the following locations: Rancho Mirage, California; Naples, Florida; Center City, Plymouth and St. Paul, Minnesota; Beaverton and Newberg, Oregon.</p> */}

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      Should We Keep Narcan in Our Home?
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">According to the National Institute on Drug Abuse (NIDA) and the Substance Abuse and Mental Health Services Administration (SAMHSA), having Narcan on hand could be a lifesaver for families who have a loved one in recovery from opioid use disorder. Narcan is the brand name for a drug called Naloxone, which blocks the effects of an opioid overdose.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">People who are dependent on opioid drugs face unique challenges that can undermine their ability to achieve long-term recovery. Anxiety, depression and intense craving for opioids can continue for months, even years. These dynamics create a high risk for accidental overdose and death during relapse. When people with opioid dependence stop using—for days, weeks or even years—and then pick up again, their tolerance for the drug changes so that an amount they could previously tolerate can become a lethal dose.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Find more information and helpful resources about Naloxone, including Narcan, at <a className='text-blue-500 hiver:text-blue-800' href="">NIDA</a>.</p>

                    {/* <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam vel ea sunt molestias facere quidem! Unde, repellendus dolores neque voluptatibus id, sunt impedit, nobis molestiae quisquam aut aliquid rem sed.
                      Doloremque, voluptatum voluptas iure facere, laudantium molestiae sint quam corporis illum ad amet recusandae vitae ea, accusamus dicta! Expedita accusantium quo reprehenderit! Repellendus quod eos tenetur nesciunt deleniti, cumque enim.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda facilis eum aliquam delectus deleniti corrupti nulla veniam cumque velit, veritatis eos quia dolorum enim ut quidem? Beatae officia aliquam voluptas!
                      Dolorum quia at maxime aliquam distinctio, molestias ratione nesciunt quod cupiditate laudantium aspernatur consequatur eaque vero id eius rerum libero dolore nobis modi. Dicta, illum architecto expedita explicabo laboriosam perferendis.
                      Debitis maiores pariatur enim voluptatum? Optio ipsa dignissimos, dolore, debitis adipisci veniam sed et labore, odio ab incidunt nihil officiis temporibus ut nemo aliquid ad eos rerum qui deserunt ipsam!</p> */}
                  </div>
                </div>
              </div>

              <div className="lg:col-span-1 col-span-3">
                <RehabSearchForm />

                {/* {featuredListing()} */}
                {<FeaturedListing items={5}/>}

              </div>

            </div>
          </div>

        </>

        <ScrollToTopButton />

        <Footer />
      </PageContentContainer>

    </>
  )
}

export default ResidentialTreatment