import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";

import Alert from '../../../components/Alert';
import AdminSideBar from '../../../components/AdminSideBar';
import AdminHeader from '../../../components/AdminHeader';

import { base64_decode, getCookie, checkRole, roleBasedBreadCrumbUrl } from '../../../func';

const ChangePassword = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [token, setToken] = useState('');

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    useEffect(() => {
        if (getCookie("token") !== undefined && getCookie("token") !== null) {
            setToken(getCookie("token"));
        }
        else {
            // navigate(`/admin/login`, { replace: true });
            navigate(`/login`, { replace: true });
        }
    }, [])

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 5000);
    }

    const changePassword = () => {
        if (password.trim() !== "" && confirmpassword.trim() !== "" && password === confirmpassword) {
            const json = JSON.stringify({ "token": token, "newpassword": password.trim() });

            axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=changepassword`, JSON.stringify({ params: json }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.data.success === "true") {
                    handleMessage({ type: 'success', message: response.data.message, show: true });

                    setPassword('');
                    setConfirmPassword('');
                }
                else {
                    handleMessage({ type: 'danger', message: response.data.message, show: true });
                }
            }).catch((error) => {
                console.error(`Error: ${error}`);
            });
        }
        else {
            if (password.trim() === "" && confirmpassword.trim() === "")
                handleMessage({ type: 'danger', message: "Please provide password and confirmation password", show: true });
            else if (password.trim() !== "" && confirmpassword.trim() !== "" && password.trim() !== confirmpassword.trim())
                handleMessage({ type: 'danger', message: "Paassword and confirmation password do not match", show: true });
            else if (password.trim() === "")
                handleMessage({ type: 'danger', message: "Please provide password", show: true });
            else if (confirmpassword.trim() === "")
                handleMessage({ type: 'danger', message: "Please provide confirmation password", show: true });
        }
    }

    const cancelChangePassword = () => {
        setPassword('');
        setConfirmPassword('');

        if (checkRole(getCookie("token")) === "admin") {
            navigate(`/admin/dashboard`, { replace: true });
        }
        else {
            navigate(`/admin/add_listing`, { replace: true });
        }
    }

    return (
        <>
            <AdminSideBar />
            <AdminHeader />

            <div className="dark:bg-background lg:ml-64 ml-0 mt-20 min-h-[calc(100vh-80px)] p-7">

                <section className="dark:bg-background">
                    <div className="container mx-auto">
                        <div className="flex text-gray-400 text-sm items-center mb-4">
                            {/* {
                                base64_decode(getCookie("token")).split("|")[3] === "admin"
                                    ?
                                    (<Link to={"/admin/dashboard"} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                                    :
                                    (<Link to={"/admin/add_listing"} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                            } */}
                            {
                                getCookie("token") !== null && (<Link to={roleBasedBreadCrumbUrl(base64_decode(getCookie("token")).split("|")[3])} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                            }
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 mx-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                            </svg>
                            <span>Change Password</span>
                        </div>
                        <Alert type={alert.type} message={alert.message} show={alert.show} />

                        <div className="bg-white shadow-front-2 dark:bg-foreground rounded-lg p-6 mb-7">
                            <h4 className="text-gray-700 dark:text-gray-300 text-xl font-semibold mb-3">Change Password</h4>
                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mb-7">
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Password <sup className="text-red-500">*</sup></label>
                                    <input type="password" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="Enter new password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Confirm Password <sup className="text-red-500">*</sup></label>
                                    <input type="password" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="Enter confirmation password" value={confirmpassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                </div>
                            </div>

                            <div className="flex flex-wrap sm:justify-start justify-center">
                                <button type="button" className="px-6 py-5 bg-blue-500 hover:bg-blue-600 shadow-glow-blue text-white rounded-lg mr-4 mb-4" onClick={() => changePassword()}>Change Password</button>
                                <button type="button" className="px-6 py-[17px] mb-4 border border-gray-200 dark:border-gray-800 text-gray-500 dark:text-gray-200 rounded-lg mr-4" onClick={() => cancelChangePassword()}>Cancel</button>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </>
    )
}

export default ChangePassword