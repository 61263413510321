import React, { useState } from 'react'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PageContentContainer from '../../components/PageContentContainer';

import ScrollToTopButton from '../../components/ScrollToTopButton';

// import businesses_list from "../../../business";
import FeaturedListing from '../../components/FeaturedListing';
import RehabSearchForm from '../../components/RehabSearchForm';

const VirtualTreatment = () => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Header />

      <PageContentContainer>

        <>
          <section className="dark:bg-background mb-12">
            <div className="relative">
              <img className="w-full object-cover h-52" src="/img/cover-2.png" alt="" />
              <div className="absolute top-1/2 w-full -translate-y-1/2 text-center mb-4">
                <h1 className="capitalize text-4xl text-white font-semibold">Virtual Treatment</h1>
                {/* <p className="text-white mt-2">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p> */}
              </div>
            </div>
          </section>

          <div className="container mx-auto px-5">
            <div className="grid grid-cols-3 gap-7 pb-10">

              <div className="lg:col-span-2 col-span-3">
                <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7">
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-4.jpg" alt="" /> */}
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-2.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-3.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-4.jpg" alt="" /> */}
                  <div className="p-6 border-b border-gray-200 dark:border-gray-800">
                    {/* <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequuntur quo perspiciatis deleniti debitis aspernatur eos voluptatibus veritatis, labore excepturi similique nisi quod repudiandae aliquid laborum fuga quos pariatur delectus quae.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi voluptates accusantium molestias magnam beatae modi dolores corrupti aliquam saepe fugit, vero repudiandae praesentium eum iste quia numquam ipsum, reiciendis consectetur!
                      Illo velit distinctio magnam corrupti quasi vero reprehenderit. Ab assumenda enim ipsam quia velit facere, maxime cumque eveniet eligendi ea officiis! Optio totam sit animi hic dolorum obcaecati illum asperiores.
                      Impedit deserunt illo accusantium? Nobis ex modi cum hic sed distinctio possimus placeat quos nam aut. Laborum obcaecati quis, rem exercitationem eum aliquam non? At magnam placeat molestias cum tempora!
                      Rerum sint praesentium voluptas deserunt officia nobis, porro recusandae necessitatibus omnis vero beatae perspiciatis expedita aperiam reiciendis eaque nostrum quis consequuntur iste eum ad sequi corrupti! Explicabo nobis sit praesentium.</p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam vel ea sunt molestias facere quidem! Unde, repellendus dolores neque voluptatibus id, sunt impedit, nobis molestiae quisquam aut aliquid rem sed.
                      Doloremque, voluptatum voluptas iure facere, laudantium molestiae sint quam corporis illum ad amet recusandae vitae ea, accusamus dicta! Expedita accusantium quo reprehenderit! Repellendus quod eos tenetur nesciunt deleniti, cumque enim.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda facilis eum aliquam delectus deleniti corrupti nulla veniam cumque velit, veritatis eos quia dolorum enim ut quidem? Beatae officia aliquam voluptas!
                      Dolorum quia at maxime aliquam distinctio, molestias ratione nesciunt quod cupiditate laudantium aspernatur consequatur eaque vero id eius rerum libero dolore nobis modi. Dicta, illum architecto expedita explicabo laboriosam perferendis.
                      Debitis maiores pariatur enim voluptatum? Optio ipsa dignissimos, dolore, debitis adipisci veniam sed et labore, odio ab incidunt nihil officiis temporibus ut nemo aliquid ad eos rerum qui deserunt ipsam!</p> */}

                    <div className="grid lg:grid-cols-2 md:grid-cols-2 lg:gap-0 gap-7 mt-8 mb-20 p-6 rounded-lg shadow-front-3" style={{ backgroundImage: "url(https://journeypure.com/wp-content/themes/JourneyPure22/img/quest_bg.png)", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "top center", backgroundPositionX: "center", backgroundPositionY: "top" }}>

                      <div className='grid-cols-1'>
                        <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-800 mb-6">
                          Have Questions?<br />Ask Our Expert Doctors.
                        </h5>

                        <p className="text-gray-500 text-base dark:text-gray-500">Drug, Alcohol & Mental Health Questions Answered by the Doctors at JourneyPure.</p>
                      </div>

                      <div className='grid-cols-1 flex h-full items-end'>
                        <button type="button" className="bg-blue-500 text-white hover:bg-blue-600 rounded-lg w-full p-3.5">Ask Your Question Today</button>
                      </div>

                    </div>


                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 text-center mb-6">
                      What Happens Now?
                    </h5>

                    <div className="grid lg:grid-cols-3 md:grid-cols-3 lg:gap-0 gap-7 mt-8 mb-20 p-6 rounded-lg shadow-front-3 bg-slate-100">

                      <div className='col-span-2'>
                        {/* <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                          Have Questions?<br />Ask Our Expert Doctors.
                        </h5>

                        <p className="text-gray-500 text-base dark:text-gray-300">Drug, Alcohol & Mental Health Questions Answered by the Doctors at JourneyPure.</p> */}

                        <ul>
                          <li className="mb-4">
                            <div className="flex text-gray-500 font-normal text-md">
                              <svg xmlns="http://www.w3.org/2000/svg" width={26} height={20} style={{ marginTop: "2px", marginRight: "10px", transform: "rotate(0deg)" }}>
                                <path style={{ stroke: "none", fillRule: "nonzero", fill: "#000", fillOpacity: 1, }} d="M8.996 16.668a2.315 2.315 0 0 1-3.266 0L.676 11.609a2.303 2.303 0 0 1 0-3.261 2.303 2.303 0 0 1 3.262 0l3.011 3.011c.23.227.598.227.824 0l8.157-8.156a2.308 2.308 0 0 1 3.265 3.262Zm0 0" />
                              </svg>
                              Getting help is as easy as picking up the phone.
                            </div>
                          </li>
                          <li className="mb-4">
                            <div className="flex text-gray-500 font-normal text-md">
                              <svg xmlns="http://www.w3.org/2000/svg" width={26} height={20} style={{ marginTop: "2px", marginRight: "10px", transform: "rotate(0deg)" }}>
                                <path style={{ stroke: "none", fillRule: "nonzero", fill: "#000", fillOpacity: 1, }} d="M8.996 16.668a2.315 2.315 0 0 1-3.266 0L.676 11.609a2.303 2.303 0 0 1 0-3.261 2.303 2.303 0 0 1 3.262 0l3.011 3.011c.23.227.598.227.824 0l8.157-8.156a2.308 2.308 0 0 1 3.265 3.262Zm0 0" />
                              </svg>
                              We will answer all your questions and provide an assessment.
                            </div>
                          </li>
                          <li className="mb-4">
                            <div className="flex text-gray-500 font-normal text-md">
                              <svg xmlns="http://www.w3.org/2000/svg" width={26} height={20} style={{ marginTop: "2px", marginRight: "10px", transform: "rotate(0deg)" }}>
                                <path style={{ stroke: "none", fillRule: "nonzero", fill: "#000", fillOpacity: 1, }} d="M8.996 16.668a2.315 2.315 0 0 1-3.266 0L.676 11.609a2.303 2.303 0 0 1 0-3.261 2.303 2.303 0 0 1 3.262 0l3.011 3.011c.23.227.598.227.824 0l8.157-8.156a2.308 2.308 0 0 1 3.265 3.262Zm0 0" />
                              </svg>
                              We will help arrange for you to come to the program and begin recovery.
                            </div>
                          </li>
                        </ul>

                      </div>

                      <div className='col-span-1 flex flex-col h-full items-center justify-center'>
                        <div className="mb-4 border py-2 px-4 rounded-lg bg-white">
                          <p className="text-gray-500 text-xl dark:text-gray-500 flex justify-center">Call Us</p>
                          <p className="text-gray-500 text-2xl dark:text-gray-500 flex justify-center">(123) 456-7890</p>
                        </div>
                        <button type="button" className="bg-blue-500 text-white hover:bg-blue-600 rounded-lg w-full p-3.5" onClick={() => setShow(!show)}>Or, Get a Call Back</button>
                      </div>


                      {show && (<div className='col-span-3'>
                        <div className='mt-5 p-4 border-gray-200 rounded-lg shadow-front-4'>
                          <p className="text-black text-lg dark:text-gray-500 flex justify-center">Enter your phone number and get a call usually within 5 minutes.</p>

                          {/* <div className='flex flow-row'>

                          </div> */}
                          <div className="inline-flex bg-white w-full dark:bg-foreground text-gray-400 p-2.5 rounded-lg shadow-front-4 w-[482px] max-w-full mt-4" style={{ display: "flex", marginLeft: "auto", marginRight: "auto" }}>
                            {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-w-5 w-5 mx-2 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                            </svg> */}
                            <input type="phone" placeholder="Phone Number" className="focus:outline-none w-full dark:text-gray-100 dark:bg-foreground" />
                            <button type="button" className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-[11px] rounded-lg ml-0 lg:ml-2">Submit</button>
                          </div>
                        </div>
                      </div>)}

                    </div>
                  </div>
                </div>
              </div>

              <div className="lg:col-span-1 col-span-3">
                <RehabSearchForm />

                {/* {featuredListing()} */}
                {<FeaturedListing items={5}/>}

              </div>

            </div>
          </div>

        </>

        <ScrollToTopButton />

        <Footer />
      </PageContentContainer>

    </>
  )
}

export default VirtualTreatment