import React from 'react'

import MobileNav from '../MobileNav';
import DesktopNav from '../DesktopNav';

const Header = (props) => {
  return (
    <>
      <MobileNav />
      <DesktopNav type={props.type}/>
    </>
  )
}

export default Header