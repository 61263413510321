import React, { useEffect, useRef } from 'react'
import './alert.css';

const Alert = ({ type, message, show }) => {
    const myRef = useRef(null);
    var yourHeight = 100;

    useEffect(() => {
        if (show)
            executeScroll();
    }, [show])

    const executeScroll = () => {
        if (myRef.current !== null && myRef.current !== undefined) {
            myRef.current.scrollIntoView();
            // now account for fixed header
            setTimeout(() => {
                var scrolledY = window.scrollY;

                if (scrolledY) {
                    window.scroll(0, scrolledY - yourHeight);
                }
            }, 1000)
        }
    };

    return (
        <>
            {(show && type === "success") && (
                <div className="alert alert-success" role="alert" ref={myRef}>
                    {message}
                </div>
            )}
            {(show && type === "danger") && (
                <div className="alert alert-danger" role="alert" ref={myRef}>
                    {message}
                </div>
            )}
            {(show && type === "warning") && (
                <div className="alert alert-warning" role="alert" ref={myRef}>
                    {message}
                </div>
            )}
            {(show && type === "info") && (
                <div className="alert alert-info" role="alert" ref={myRef}>
                    {message}
                </div>
            )}
        </>
    )
}

export default Alert