import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useParams, useNavigate } from "react-router-dom";

import Header from '../../components/Header';
import PageContentContainer from '../../components/PageContentContainer';
import Newsletter from '../../components/Newsletter';
import ScrollToTopButton from '../../components/ScrollToTopButton';
import Footer from '../../components/Footer';
import RecentPosts from '../../components/RecentPosts';
import RehabSearchForm from '../../components/RehabSearchForm';
import FeaturedListing from '../../components/FeaturedListing';

import { Helmet } from 'react-helmet';

const Post = () => {
    const navigate = useNavigate();
    let params = useParams();

    const [post, setPost] = useState({});
    const [recentPosts, setRecentPosts] = useState([]);

    const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     if (params.postid !== undefined && params.postid !== null && params.postid.toString().trim() !== "")
    //         fetchPostDetails(params.postid);
    //     else {
    //         navigate(`/blog`, { replace: true });
    //     }
    // }, [])

    useEffect(() => {
        if (params.postid !== undefined && params.postid !== null && params.postid.toString().trim() !== "") {
            fetchPostDetails(params.postid);

            <Helmet>
                {/* <title>My Page Title</title>
                <meta name="description" content="This is a description of my page" />
                <meta name="keywords" content="react, meta tags, seo" />
                <meta name="author" content="Your Name" />
                <meta property="og:title" content="My Page Title" />
                <meta property="og:description" content="This is a description of my page" />
                <meta property="og:image" content="https://example.com/image.jpg" />
                <meta property="og:url" content="https://example.com/my-page" />
                <meta name="twitter:title" content="My Page Title" />
                <meta name="twitter:description" content="This is a description of my page" />
                <meta name="twitter:image" content="https://example.com/image.jpg" />
                <meta name="twitter:card" content="summary_large_image" /> */}
                <link rel="canonical" href={`https://opioid-rehab.com/post/${params.postid}/${params.slug}`} />
            </Helmet>
        }
        else {
            navigate(`/blog`, { replace: true });
        }
    }, [params.postid, params.slug])

    const fetchPostDetails = (postid) => {
        const json = JSON.stringify({ "postid": postid });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=post_details`; //development

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                // let post = response.data.post;
                // console.log("post: ", post);
                setPost(response.data.post);
                setRecentPosts(response.data.recent);
                setLoading(false);
            }
            else {
                navigate(`/404`, { replace: true });
                // handleMessage({ type: 'danger', message: response.data.message, show: true });
                setLoading(false);
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric" }
        return new Date(dateString).toLocaleDateString(undefined, options)
    }

    return (
        <>
            <Header type={"else"} />{/**home (for home page), else (for all other pages) */}

            <PageContentContainer>
                <>
                    <section className="dark:bg-background mb-12">
                        <div className="relative">
                            <img className="w-full object-cover h-52" src="/img/cover-2.png" alt="" />
                            <div className="absolute top-1/2 w-full -translate-y-1/2 text-center mb-4">
                                {loading
                                    ?
                                    (<center><div className='skeleton h-12 w-96'></div></center>)
                                    :
                                    (<h1 className="capitalize text-4xl text-white font-semibold">{post.post_title}</h1>)
                                }
                                {/* <p className="text-white">See our all real estate blogs.</p> */}
                            </div>
                        </div>
                    </section>
                    <section className="dark:bg-background lg:px-0 px-5 pb-20">
                        <div className="container mx-auto px-5">
                            <div className="grid grid-cols-3 gap-7 pb-10">
                                <div className="lg:col-span-2 col-span-3">
                                    <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7">
                                        {loading
                                            ?
                                            (<div className='skeleton h-96'></div>)
                                            :
                                            (<img className="w-full rounded" src={`${process.env.REACT_APP_BASE_API}/${post.post_picture}`} alt={`${post.post_title}`} />)
                                        }
                                        <div className="p-6 border-b border-gray-200 dark:border-gray-800">
                                            {loading
                                                ?
                                                (<div className='skeleton h-8 mb-6'></div>)
                                                :
                                                (<h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                                                    {post.post_title}
                                                </h5>)
                                            }
                                            <div className="flex justify-between flex-wrap mb-9">
                                                <div className="flex items-center mb-4 mr-4">
                                                    <div className="bg-gray-200 dark:bg-gray-800 mr-3 w-9 h-9 rounded-full flex-shrink-0 flex justify-center items-center">
                                                        <svg className="h-5 w-5 text-gray-500 dark:text-gray-400" width={17} height={18} viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M15.1235 17V15.2222C15.1235 14.2793 14.7515 13.3749 14.0893 12.7081C13.4271 12.0413 12.5291 11.6667 11.5926 11.6667H4.53087C3.59443 11.6667 2.69633 12.0413 2.03417 12.7081C1.372 13.3749 1 14.2793 1 15.2222L1 17" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M8.06174 8.11111C10.0118 8.11111 11.5926 6.51923 11.5926 4.55556C11.5926 2.59188 10.0118 1 8.06174 1C6.11169 1 4.53087 2.59188 4.53087 4.55556C4.53087 6.51923 6.11169 8.11111 8.06174 8.11111Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        <h6 className="text-gray-500 font-normal dark:text-gray-400 text-sm">
                                                            Posted By
                                                        </h6>
                                                        {loading
                                                            ?
                                                            (<div className='skeleton h-4'></div>)
                                                            :
                                                            (<p className="text-gray-700 dark:text-gray-100 text-sm font-medium">
                                                                {post.author}
                                                            </p>)
                                                        }
                                                    </div>
                                                </div>
                                                <div className="flex items-center mb-4 mr-4">
                                                    <div className="bg-gray-200 dark:bg-gray-800 mr-3 w-9 h-9 rounded-full flex-shrink-0 flex justify-center items-center">
                                                        <svg className="h-5 w-5 text-gray-500 dark:text-gray-400" width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5.08334 2H9.58334V0.75C9.58334 0.335938 9.91772 0 10.3333 0C10.749 0 11.0833 0.335938 11.0833 0.75V2H12.3333C13.4365 2 14.3333 2.89531 14.3333 4V14C14.3333 15.1031 13.4365 16 12.3333 16H2.33334C1.22866 16 0.333344 15.1031 0.333344 14V4C0.333344 2.89531 1.22866 2 2.33334 2H3.58334V0.75C3.58334 0.335938 3.91772 0 4.33334 0C4.74897 0 5.08334 0.335938 5.08334 0.75V2ZM1.83334 14C1.83334 14.275 2.05709 14.5 2.33334 14.5H12.3333C12.6083 14.5 12.8333 14.275 12.8333 14V6H1.83334V14Z" fill="currentColor" />
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        <h6 className="text-gray-500 dark:text-gray-400 text-sm">
                                                            Publish in
                                                        </h6>
                                                        {loading
                                                            ?
                                                            (<div className='skeleton h-4'></div>)
                                                            :
                                                            (<p className="text-gray-700 dark:text-gray-100 text-sm font-medium">
                                                                {formatDate(post.post_datecreated)}
                                                            </p>)
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                                                Almost every industry is currently struggling with supply chain
                                                disruptions. This also applies to the rent U.S. housing market,
                                                where buyer demand far exceeds housing supply.
                                            </p>
                                            <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                                                Purchaser demand is very strong right now. The National
                                                Association of Realtors (NAR) just released their latest Existing
                                                Home Sales Report which reveals that sales surged in January.
                                                Existing home sales rose to a seasonally adjusted annual rate of
                                                6.5 million – an increase of 6.7% from the prior month, with sales
                                                up in all regions. However, there’s one big challenge.
                                            </p>
                                            <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                                                Inventory Is at an All-Time Low
                                            </h4>
                                            <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                                                Because purchaser demand is so high, the market is running out of
                                                available homes for sale. The above-mentioned report states that
                                                the current months’ supply of inventory of homes for sale has
                                                fallen to 1.6 months. This prompts Lawrence Yun, Chief Economist
                                                at NAR, to say:
                                            </p>
                                            <h4 className="text-lg italic text-gray-700 dark:text-gray-100 font-medium mb-5">
                                                “The inventory of homes on the market remains woefully depleted,
                                                and in fact is currently at an all-time low.”
                                            </h4>
                                            <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                                                Earlier this month, realtor.com released their inventory data for
                                                January. It helps confirm this point. Here’s a graph comparing
                                                inventory levels for January over the last six years:
                                            </p>
                                            <h1>Apex chart</h1>
                                            <p className="text-gray-500 text-base dark:text-gray-300 mb-8">
                                                As the graph shows, new listings coming on the market have
                                                decreased over the last four years (shown in blue in the graph).
                                                The graph also reveals that carry-over inventory has plummeted in
                                                recent years. This is because listings are now sold so quickly,
                                                they don’t stay on the market long enough to carry over
                                                month-to-month (shown in green in the graph). In other words,
                                                homes are not staying on the market for months as they had prior
                                                to the pandemic.
                                            </p> */}
                                            {loading
                                                ?
                                                (<div className='skeleton h-96'></div>)
                                                :
                                                (<div className='blog-content' dangerouslySetInnerHTML={{ __html: `${post.post_content}` }}></div>)
                                            }
                                            <p className="text-gray-500 dark:text-gray-400 text-sm mt-4 mb-4">
                                                Share to:
                                            </p>
                                            <div className="flex flex-wrap">
                                                {loading
                                                    ?
                                                    (<><div className='skeleton h-10 w-10 mr-5 rounded-full'></div><div className='skeleton h-10 w-10 mr-5 rounded-full'></div><div className='skeleton h-10 w-10 rounded-full'></div></>)
                                                    :
                                                    (<>
                                                        <a href={`https://twitter.com/intent/tweet?url=${process.env.REACT_APP_BASE_API + "/" + params.postid + "/" + post.post_slug}&via=Opioid-Rehab`} className="text-gray-400 hover:text-blue-500 dark:hover:text-blue-500 mr-5" target='_blank' rel="noreferrer">
                                                            <svg className="w-7 h-7" width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.03344 16.563C13.0697 16.563 16.3722 11.5608 16.3722 7.22423C16.3722 7.0836 16.3691 6.93985 16.3628 6.79923C17.0052 6.33463 17.5597 5.75916 18 5.09985C17.4017 5.36605 16.7664 5.53991 16.1159 5.61548C16.8009 5.20493 17.3137 4.55997 17.5594 3.80017C16.915 4.18203 16.2104 4.4514 15.4756 4.59673C14.9806 4.0707 14.326 3.7224 13.6131 3.60569C12.9003 3.48898 12.1688 3.61036 11.5318 3.95105C10.8949 4.29175 10.3879 4.83279 10.0892 5.49053C9.7906 6.14827 9.71695 6.88606 9.87969 7.58985C8.575 7.52438 7.29862 7.18546 6.13332 6.59505C4.96802 6.00465 3.9398 5.17595 3.11531 4.16267C2.69627 4.88515 2.56804 5.74008 2.75669 6.55372C2.94534 7.36735 3.43671 8.07862 4.13094 8.54298C3.60975 8.52643 3.09998 8.38611 2.64375 8.1336V8.17423C2.64328 8.93242 2.9054 9.66738 3.38554 10.2542C3.86568 10.841 4.53422 11.2434 5.2775 11.393C4.7947 11.5251 4.28799 11.5443 3.79656 11.4492C4.0063 12.1013 4.41438 12.6716 4.96385 13.0805C5.51331 13.4895 6.17675 13.7167 6.86156 13.7305C5.69895 14.6437 4.26278 15.1391 2.78438 15.1367C2.52219 15.1363 2.26027 15.1203 2 15.0886C3.5019 16.0522 5.24902 16.5639 7.03344 16.563Z" fill="currentColor" />
                                                            </svg>
                                                        </a>
                                                        <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${process.env.REACT_APP_BASE_API + "/" + params.postid + "/" + post.post_slug}&title=${post.post_title}&summary=Check It Out`} className="text-gray-400 hover:text-blue-500 dark:hover:text-blue-500 mr-5" target='_blank' rel="noreferrer">
                                                            <svg className="w-7 h-7" width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M16.8156 2.5625H3.18125C2.52812 2.5625 2 3.07813 2 3.71563V17.4062C2 18.0438 2.52812 18.5625 3.18125 18.5625H16.8156C17.4688 18.5625 18 18.0438 18 17.4094V3.71563C18 3.07813 17.4688 2.5625 16.8156 2.5625ZM6.74687 16.1969H4.37188V8.55937H6.74687V16.1969ZM5.55938 7.51875C4.79688 7.51875 4.18125 6.90312 4.18125 6.14375C4.18125 5.38438 4.79688 4.76875 5.55938 4.76875C6.31875 4.76875 6.93437 5.38438 6.93437 6.14375C6.93437 6.9 6.31875 7.51875 5.55938 7.51875ZM15.6344 16.1969H13.2625V12.4844C13.2625 11.6 13.2469 10.4594 12.0281 10.4594C10.7937 10.4594 10.6062 11.425 10.6062 12.4219V16.1969H8.2375V8.55937H10.5125V9.60313H10.5437C10.8594 9.00313 11.6344 8.36875 12.7875 8.36875C15.1906 8.36875 15.6344 9.95 15.6344 12.0063V16.1969Z" fill="currentColor" />
                                                            </svg>
                                                        </a>
                                                        <a href={`https://www.facebook.com/sharer.php?href=${process.env.REACT_APP_BASE_API + "/" + params.postid + "/" + post.post_slug}&display=popup`} className="text-gray-400 hover:text-blue-500 dark:hover:text-blue-500 mr-5" target='_blank' rel="noreferrer">
                                                            <svg className="w-7 h-7" width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M18 10.5625C18 6.14422 14.4183 2.5625 10 2.5625C5.58172 2.5625 2 6.14422 2 10.5625C2 14.5555 4.92547 17.8652 8.75 18.4653V12.875H6.71875V10.5625H8.75V8.8C8.75 6.795 9.94438 5.6875 11.7717 5.6875C12.6467 5.6875 13.5625 5.84375 13.5625 5.84375V7.8125H12.5538C11.56 7.8125 11.25 8.42922 11.25 9.0625V10.5625H13.4688L13.1141 12.875H11.25V18.4653C15.0745 17.8652 18 14.5555 18 10.5625Z" fill="currentColor" />
                                                            </svg>
                                                        </a>
                                                    </>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="lg:col-span-1 col-span-3">

                                    <RecentPosts recentPosts={recentPosts} loading={loading} />

                                    <RehabSearchForm />

                                    <FeaturedListing items={5} />

                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <div className="scroll-top-btn transition-all opacity-0">
                        <button type="button" className="bg-blue-500 p-2 fixed z-50 bottom-0 hover:-translate-y-2 transition-all right-0 m-10 rounded-full" aria-label="Scroll To Top">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12" />
                            </svg>
                        </button>
                    </div> */}
                </>

                <Newsletter />

                <ScrollToTopButton />

                <Footer />
            </PageContentContainer>
        </>
    )
}

export default Post