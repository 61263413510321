import React, { useEffect, useState } from 'react'
import axios from 'axios';

import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import PageContentContainer from '../../components/PageContentContainer';
import Newsletter from '../../components/Newsletter';
import ScrollToTopButton from '../../components/ScrollToTopButton';
import Footer from '../../components/Footer';

import RecentPosts from '../../components/RecentPosts';
import FeaturedListing from '../../components/FeaturedListing';

import { base64_encode } from '../../func';

const Blog = () => {
    const [posts, setPosts] = useState([]);
    const [recentPosts, setRecentPosts] = useState([]);

    const [loading, setLoading] = useState(true);
    const [hideLoadMore, setHideLoadMore] = useState(false);

    useEffect(() => {
        fetchBlogPosts();
    }, []);

    const fetchBlogPosts = () => {
        const json = JSON.stringify({ "offset": 0, "limit": 10 });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_all_post`; //development

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                setPosts(response.data.posts);
                setRecentPosts(response.data.recent);
                setLoading(false);
            }
            else {
                setPosts([]);
                setLoading(false);
                setHideLoadMore(true);
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const loadMore = () => {
        const json = JSON.stringify({ "limit": 10, "offset": posts.length });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_all_post`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                var temp = [...posts, ...response.data.posts]
                setPosts(temp);
                setHideLoadMore(false);
            }
            else {
                setHideLoadMore(true);
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const removeTags = (str) => {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        return str.replace(/(<([^>]+)>)/ig, '');
    }

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric" }
        return new Date(dateString).toLocaleDateString(undefined, options)
    }

    const createLoadingLayout = () => {
        return (
            [...Array(10)].map((u, index) => (
                <div className="lg:col-span-2" key={index}>
                    <div className="rounded-lg shadow-front-1 [&>div]:hover:shadow-front-3 dark:bg-foreground group">

                        <div className='skeleton h-64 rounded-t-lg'></div>

                        <div className="p-8 dark:bg-foreground rounded-b-lg border-b-lg shadow-front-1">

                            <div className="text-2xl font-semibold text-gray-700 dark:text-gray-100 mb-4 group-hover:text-blue-500 skeleton h-8"></div>

                            <div className="text-sm font-normal text-gray-500 mb-7 dark:text-gray-400 skeleton h-14"></div>

                            <div className="flex justify-between">
                                <div className="text-sm font-normal dark:text-gray-400 skeleton w-32 h-6"></div>

                                <div className="text-sm font-normal dark:text-gray-400 skeleton w-32 h-6"></div>

                                {/* <div className="flex">
                                    <div className="flex mr-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400 group-hover:text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                        </svg>
                                        <span className="text-sm text-gray-500 dark:text-gray-400">245</span>
                                    </div>
                                    <div className="flex mr-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400 group-hover:text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                                        </svg>
                                        <span className="text-sm text-gray-500 dark:text-gray-400">245</span>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            ))
        )
    }

    const createBlogPosts = () => {
        return (
            posts.map((post, index) => (
                <div className="lg:col-span-2" key={index}>
                    <div className="rounded-lg shadow-front-1 [&>div]:hover:shadow-front-3 dark:bg-foreground group">
                        <Link to={`/post/${base64_encode(post.post_id.toString())}/${post.post_slug}`}>
                            <img className="w-full h-64 object-cover rounded-t-lg" src={`${process.env.REACT_APP_BASE_API}/${post.post_picture}`} alt={`${post.post_title}`} />
                        </Link>
                        <div className="p-8 dark:bg-foreground rounded-b-lg border-b-lg shadow-front-1">
                            <div style={{ height: "6rem", overflow: "hidden" }}>
                                <Link to={`/post/${base64_encode(post.post_id.toString())}/${post.post_slug}`} className="text-2xl font-semibold text-gray-700 dark:text-gray-100 mb-4 group-hover:text-blue-500">
                                    {post.post_title}
                                </Link>
                            </div>
                            <div className="text-sm font-normal text-gray-500 mb-7 dark:text-gray-400" style={{ height: "3.6rem", overflow: "hidden" }}>
                                {`${removeTags(post.post_content).split(/\s+/).slice(0, 15).join(" ")}...`}
                            </div>
                            <div className="flex justify-between">
                                <div className="flex items-center mr-4">
                                    <div className="bg-gray-200 dark:bg-gray-800 mr-3 w-9 h-9 rounded-full flex-shrink-0 flex justify-center items-center">
                                        <svg className="h-5 w-5 text-gray-500 dark:text-gray-400" width={17} height={18} viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.1235 17V15.2222C15.1235 14.2793 14.7515 13.3749 14.0893 12.7081C13.4271 12.0413 12.5291 11.6667 11.5926 11.6667H4.53087C3.59443 11.6667 2.69633 12.0413 2.03417 12.7081C1.372 13.3749 1 14.2793 1 15.2222L1 17" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M8.06174 8.11111C10.0118 8.11111 11.5926 6.51923 11.5926 4.55556C11.5926 2.59188 10.0118 1 8.06174 1C6.11169 1 4.53087 2.59188 4.53087 4.55556C4.53087 6.51923 6.11169 8.11111 8.06174 8.11111Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                    <div>
                                        <h6 className="text-gray-500 font-normal dark:text-gray-400 text-sm">Posted By</h6>
                                        <p className="text-gray-700 dark:text-gray-100 text-sm font-medium">{post.author}</p>
                                    </div>
                                </div>

                                <div className="flex items-center mr-4">
                                    <div className="bg-gray-200 dark:bg-gray-800 mr-3 w-9 h-9 rounded-full flex-shrink-0 flex justify-center items-center">
                                        <svg className="h-5 w-5 text-gray-500 dark:text-gray-400" width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.08334 2H9.58334V0.75C9.58334 0.335938 9.91772 0 10.3333 0C10.749 0 11.0833 0.335938 11.0833 0.75V2H12.3333C13.4365 2 14.3333 2.89531 14.3333 4V14C14.3333 15.1031 13.4365 16 12.3333 16H2.33334C1.22866 16 0.333344 15.1031 0.333344 14V4C0.333344 2.89531 1.22866 2 2.33334 2H3.58334V0.75C3.58334 0.335938 3.91772 0 4.33334 0C4.74897 0 5.08334 0.335938 5.08334 0.75V2ZM1.83334 14C1.83334 14.275 2.05709 14.5 2.33334 14.5H12.3333C12.6083 14.5 12.8333 14.275 12.8333 14V6H1.83334V14Z" fill="currentColor" />
                                        </svg>
                                    </div>
                                    <div>
                                        <h6 className="text-gray-500 dark:text-gray-400 text-sm">Publish in</h6>
                                        <p className="text-gray-700 dark:text-gray-100 text-sm font-medium">{formatDate(post.post_datecreated)}</p>
                                    </div>
                                </div>
                                {/* <div className="text-sm font-semibold dark:text-gray-400">
                                    {post.author}
                                </div>
                                <div className="text-sm font-semibold dark:text-gray-400">
                                    {formatDate(post.post_datecreated)}
                                </div> */}
                                {/* <div className="flex">
                                    <div className="flex mr-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400 group-hover:text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                        </svg>
                                        <span className="text-sm text-gray-500 dark:text-gray-400">245</span>
                                    </div>
                                    <div className="flex mr-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400 group-hover:text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                                        </svg>
                                        <span className="text-sm text-gray-500 dark:text-gray-400">245</span>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            ))
        )
    }

    return (
        <>
            <Header type={"else"} />{/**home (for home page), else (for all other pages) */}

            <PageContentContainer>
                <>
                    <section className="dark:bg-background mb-20">
                        <div className="relative">
                            <img className="w-full object-cover h-52" src="img/cover.png" alt="" />
                            <div className="absolute top-1/2 w-full -translate-y-1/2 text-center mb-4">
                                <h1 className="capitalize text-4xl text-white font-semibold">
                                    Our Blogs Lists
                                </h1>
                                <p className="text-white">See our all blogs.</p>
                            </div>
                        </div>
                    </section>
                    <section className="dark:bg-background lg:px-0 px-5 pb-20">
                        <div className="container mx-auto">
                            <div className="grid lg:grid-cols-6 gap-7">
                                <div className="lg:col-span-4 col-span-6">
                                    <div className="grid lg:grid-cols-4 gap-7 mb-8">
                                        {loading ? createLoadingLayout() : createBlogPosts()}
                                        {/* <div className="lg:col-span-2">
                                            <div className="rounded-lg shadow-front-1 [&>div]:hover:shadow-front-3 dark:bg-foreground group">
                                                <a href="blog-details-page.html">
                                                    <img className="w-full h-64 object-cover rounded-t-lg" src="img/Image/landing-page-image/house.jpg" alt="" />
                                                </a>
                                                <div className="p-8 dark:bg-foreground rounded-b-lg border-b-lg shadow-front-1">
                                                    <a href="blog-details-page.html" className="text-2xl font-semibold text-gray-700 dark:text-gray-100 mb-4 group-hover:text-blue-500">
                                                        Musical Event House Today
                                                    </a>
                                                    <div className="text-sm font-normal text-gray-500 mb-7 dark:text-gray-400">
                                                        Nation yet I century way of the into he gone day time. And
                                                        installer.
                                                    </div>
                                                    <div className="flex justify-between">
                                                        <div className="text-sm font-normal dark:text-gray-400">
                                                            22 Feb, 2022
                                                        </div>
                                                        <div className="flex">
                                                            <div className="flex mr-5">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400 group-hover:text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                                                </svg>
                                                                <span className="text-sm text-gray-500 dark:text-gray-400">245</span>
                                                            </div>
                                                            <div className="flex mr-0">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400 group-hover:text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                                                                </svg>
                                                                <span className="text-sm text-gray-500 dark:text-gray-400">245</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="lg:col-span-2">
                                            <div className="rounded-lg shadow-front-1 [&>div]:hover:shadow-front-3 dark:bg-foreground group">
                                                <a href="blog-details-page.html">
                                                    <img className="w-full h-64 object-cover rounded-t-lg" src="img/Image/resturant/1.jpg" alt="" />
                                                </a>
                                                <div className="p-8 dark:bg-foreground rounded-b-lg border-b-lg shadow-front-1">
                                                    <a href="blog-details-page.html" className="text-2xl font-semibold text-gray-700 dark:text-gray-100 mb-4 group-hover:text-blue-500">
                                                        Musical Event House Today
                                                    </a>
                                                    <div className="text-sm font-normal text-gray-500 mb-7 dark:text-gray-400">
                                                        Nation yet I century way of the into he gone day time. And
                                                        installer.
                                                    </div>
                                                    <div className="flex justify-between">
                                                        <div className="text-sm font-normal dark:text-gray-400">
                                                            22 Feb, 2022
                                                        </div>
                                                        <div className="flex">
                                                            <div className="flex mr-5">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400 group-hover:text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                                                </svg>
                                                                <span className="text-sm text-gray-500 dark:text-gray-400">245</span>
                                                            </div>
                                                            <div className="flex mr-0">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400 group-hover:text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                                                                </svg>
                                                                <span className="text-sm text-gray-500 dark:text-gray-400">245</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="lg:col-span-2">
                                            <div className="rounded-lg shadow-front-1 [&>div]:hover:shadow-front-3 dark:bg-foreground group">
                                                <a href="blog-details-page.html">
                                                    <img className="w-full h-64 object-cover rounded-t-lg" src="img/Image/event-landing-page/concert.jpg" alt="" />
                                                </a>
                                                <div className="p-8 dark:bg-foreground rounded-b-lg border-b-lg shadow-front-1">
                                                    <a href="blog-details-page.html" className="text-2xl font-semibold text-gray-700 dark:text-gray-100 mb-4 group-hover:text-blue-500">
                                                        Musical Event House Today
                                                    </a>
                                                    <div className="text-sm font-normal text-gray-500 mb-7 dark:text-gray-400">
                                                        Nation yet I century way of the into he gone day time. And
                                                        installer.
                                                    </div>
                                                    <div className="flex justify-between">
                                                        <div className="text-sm font-normal dark:text-gray-400">
                                                            22 Feb, 2022
                                                        </div>
                                                        <div className="flex">
                                                            <div className="flex mr-5">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400 group-hover:text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                                                </svg>
                                                                <span className="text-sm text-gray-500 dark:text-gray-400">245</span>
                                                            </div>
                                                            <div className="flex mr-0">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400 group-hover:text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                                                                </svg>
                                                                <span className="text-sm text-gray-500 dark:text-gray-400">245</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="lg:col-span-2">
                                            <div className="rounded-lg shadow-front-1 [&>div]:hover:shadow-front-3 dark:bg-foreground group">
                                                <a href="blog-details-page.html">
                                                    <img className="w-full h-64 object-cover rounded-t-lg" src="img/Image/landing-page-image/house.jpg" alt="" />
                                                </a>
                                                <div className="p-8 dark:bg-foreground rounded-b-lg border-b-lg shadow-front-1">
                                                    <a href="blog-details-page.html" className="text-2xl font-semibold text-gray-700 dark:text-gray-100 mb-4 group-hover:text-blue-500">
                                                        Musical Event House Today
                                                    </a>
                                                    <div className="text-sm font-normal text-gray-500 mb-7 dark:text-gray-400">
                                                        Nation yet I century way of the into he gone day time. And
                                                        installer.
                                                    </div>
                                                    <div className="flex justify-between">
                                                        <div className="text-sm font-normal dark:text-gray-400">
                                                            22 Feb, 2022
                                                        </div>
                                                        <div className="flex">
                                                            <div className="flex mr-5">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400 group-hover:text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                                                </svg>
                                                                <span className="text-sm text-gray-500 dark:text-gray-400">245</span>
                                                            </div>
                                                            <div className="flex mr-0">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400 group-hover:text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                                                                </svg>
                                                                <span className="text-sm text-gray-500 dark:text-gray-400">245</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="lg:col-span-2">
                                            <div className="rounded-lg shadow-front-1 [&>div]:hover:shadow-front-3 dark:bg-foreground group">
                                                <a href="blog-details-page.html">
                                                    <img className="w-full h-64 object-cover rounded-t-lg" src="img/Image/resturant/1.jpg" alt="" />
                                                </a>
                                                <div className="p-8 dark:bg-foreground rounded-b-lg border-b-lg shadow-front-1">
                                                    <a href="blog-details-page.html" className="text-2xl font-semibold text-gray-700 dark:text-gray-100 mb-4 group-hover:text-blue-500">
                                                        Musical Event House Today
                                                    </a>
                                                    <div className="text-sm font-normal text-gray-500 mb-7 dark:text-gray-400">
                                                        Nation yet I century way of the into he gone day time. And
                                                        installer.
                                                    </div>
                                                    <div className="flex justify-between">
                                                        <div className="text-sm font-normal dark:text-gray-400">
                                                            22 Feb, 2022
                                                        </div>
                                                        <div className="flex">
                                                            <div className="flex mr-5">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400 group-hover:text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                                                </svg>
                                                                <span className="text-sm text-gray-500 dark:text-gray-400">245</span>
                                                            </div>
                                                            <div className="flex mr-0">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400 group-hover:text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                                                                </svg>
                                                                <span className="text-sm text-gray-500 dark:text-gray-400">245</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="lg:col-span-2">
                                            <div className="rounded-lg shadow-front-1 [&>div]:hover:shadow-front-3 dark:bg-foreground group">
                                                <a href="blog-details-page.html">
                                                    <img className="w-full h-64 object-cover rounded-t-lg" src="img/Image/event-landing-page/concert.jpg" alt="" />
                                                </a>
                                                <div className="p-8 dark:bg-foreground rounded-b-lg border-b-lg shadow-front-1">
                                                    <a href="blog-details-page.html" className="text-2xl font-semibold text-gray-700 dark:text-gray-100 mb-4 group-hover:text-blue-500">
                                                        Musical Event House Today
                                                    </a>
                                                    <div className="text-sm font-normal text-gray-500 mb-7 dark:text-gray-400">
                                                        Nation yet I century way of the into he gone day time. And
                                                        installer.
                                                    </div>
                                                    <div className="flex justify-between">
                                                        <div className="text-sm font-normal dark:text-gray-400">
                                                            22 Feb, 2022
                                                        </div>
                                                        <div className="flex">
                                                            <div className="flex mr-5">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400 group-hover:text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                                                </svg>
                                                                <span className="text-sm text-gray-500 dark:text-gray-400">245</span>
                                                            </div>
                                                            <div className="flex mr-0">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400 group-hover:text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                                                                </svg>
                                                                <span className="text-sm text-gray-500 dark:text-gray-400">245</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    {!hideLoadMore && (<div className="flex lg:justify-start justify-center items-center">
                                        <button className="bg-blue-500 hover:bg-blue-600 rounded-full text-white px-8 py-3 mx-auto" onClick={loadMore}>Load More</button>
                                    </div>)}
                                    {/* pagination  */}
                                    {/* pagination  */}
                                    {/* <div className="flex lg:justify-start justify-center items-center">
                                        <a href="#" className="rounded-md w-8 h-8 flex justify-center items-center border border-gray-200 mr-4"><svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" /></svg></a>
                                        <a href="#" className="rounded-md w-8 h-8 flex justify-center items-center border border-gray-200 bg-blue-500 text-white mr-4">1</a>
                                        <a href="#" className="rounded-md w-8 h-8 flex justify-center items-center border border-gray-200 hover:bg-blue-500 dark:hover:bg-blue-500 dark:hover:text-white text-gray-500 dark:text-gray-300 hover:text-white mr-4">2</a>
                                        <a href="#" className="rounded-md w-8 h-8 flex justify-center items-center border border-gray-200 mr-4"><svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" /></svg></a>
                                    </div> */}
                                </div>
                                <div className="lg:col-span-2 col-span-6">
                                    {<RecentPosts recentPosts={recentPosts} loading={loading} />}

                                    {/* <div className="shadow-front-2 dark:bg-foreground p-6 rounded-lg mb-8">
                                        <div className="flex justify-between mb-6">
                                            <h4 className="text-base font-medium text-gray-700 dark:text-gray-100">
                                                Feature Listings
                                            </h4>
                                            <a href="#" className="text-gray-500 dark:text-gray-400 text-sm">More</a>
                                        </div>
                                        <a href="#" className="flex mb-6">
                                            <img className="w-24 h-auto flex-shrink-0 rounded-lg mr-4" src="img/Image/landing-page-image/building-2.jpg" alt="" />
                                            <div>
                                                <h6 className="text-gray-700 dark:text-gray-300 font-medium mb-2">
                                                    Duplex Apartment with Pool
                                                </h6>
                                                <div className="bg-gray-100 dark:bg-gray-900 rounded-full px-3 py-1 inline-flex items-center">
                                                    <svg className="w-3 h-auto text-green-500 mr-2" width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.6498 2.39995H7.3498V1.64995C7.3498 1.40151 7.55043 1.19995 7.7998 1.19995C8.04918 1.19995 8.2498 1.40151 8.2498 1.64995V2.39995H8.9998C9.66168 2.39995 10.1998 2.93714 10.1998 3.59995V9.59995C10.1998 10.2618 9.66168 10.8 8.9998 10.8H2.9998C2.33699 10.8 1.7998 10.2618 1.7998 9.59995V3.59995C1.7998 2.93714 2.33699 2.39995 2.9998 2.39995H3.7498V1.64995C3.7498 1.40151 3.95043 1.19995 4.1998 1.19995C4.44918 1.19995 4.6498 1.40151 4.6498 1.64995V2.39995ZM2.6998 9.59995C2.6998 9.76495 2.83405 9.89995 2.9998 9.89995H8.9998C9.1648 9.89995 9.2998 9.76495 9.2998 9.59995V4.79995H2.6998V9.59995Z" fill="currentColor" />
                                                    </svg>
                                                    <span className="text-xs font-medium dark:text-gray-400">Instant Booking</span>
                                                </div>
                                            </div>
                                        </a>
                                        <a href="#" className="flex mb-6">
                                            <img className="w-24 h-auto flex-shrink-0 rounded-lg mr-4" src="img/Image/event-landing-page/concert.jpg" alt="" />
                                            <div>
                                                <h6 className="text-gray-700 dark:text-gray-300 font-medium mb-2">
                                                    Tomorrowland Concert
                                                </h6>
                                                <div className="bg-gray-100 dark:bg-gray-900 rounded-full px-3 py-1 inline-flex items-center">
                                                    <svg className="w-3 h-auto text-green-500 mr-2" width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.6498 2.39995H7.3498V1.64995C7.3498 1.40151 7.55043 1.19995 7.7998 1.19995C8.04918 1.19995 8.2498 1.40151 8.2498 1.64995V2.39995H8.9998C9.66168 2.39995 10.1998 2.93714 10.1998 3.59995V9.59995C10.1998 10.2618 9.66168 10.8 8.9998 10.8H2.9998C2.33699 10.8 1.7998 10.2618 1.7998 9.59995V3.59995C1.7998 2.93714 2.33699 2.39995 2.9998 2.39995H3.7498V1.64995C3.7498 1.40151 3.95043 1.19995 4.1998 1.19995C4.44918 1.19995 4.6498 1.40151 4.6498 1.64995V2.39995ZM2.6998 9.59995C2.6998 9.76495 2.83405 9.89995 2.9998 9.89995H8.9998C9.1648 9.89995 9.2998 9.76495 9.2998 9.59995V4.79995H2.6998V9.59995Z" fill="currentColor" />
                                                    </svg>
                                                    <span className="text-xs font-medium dark:text-gray-400">Instant Booking</span>
                                                </div>
                                            </div>
                                        </a>
                                        <a href="#" className="flex">
                                            <img className="w-24 h-auto flex-shrink-0 rounded-lg mr-4" src="img/Image/landing-page-image/palace.jpg" alt="" />
                                            <div>
                                                <h6 className="text-gray-700 dark:text-gray-300 font-medium mb-2">
                                                    Luxary Hotel For Rent
                                                </h6>
                                                <div className="bg-gray-100 dark:bg-gray-900 rounded-full px-3 py-1 inline-flex items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-auto text-yellow-500 mr-1" viewBox="0 0 20 20" fill="currentColor">
                                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                    </svg>
                                                    <span className="text-xs font-medium dark:text-gray-400">Instant Booking</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div> */}

                                    {<FeaturedListing items={5} />}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <div className="scroll-top-btn transition-all opacity-0">
                        <button type="button" className="bg-blue-500 p-2 fixed z-50 bottom-0 hover:-translate-y-2 transition-all right-0 m-10 rounded-full" aria-label="Scroll To Top">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12" />
                            </svg>
                        </button>
                    </div> */}
                </>

                <Newsletter />

                <ScrollToTopButton />

                <Footer />
            </PageContentContainer>
        </>
    )
}

export default Blog