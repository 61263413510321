import React, { useState, useEffect, useRef } from 'react'
import "./style.css"
import axios from 'axios';

import { Link } from 'react-router-dom';

const ListingByArea = () => {
    const [listing, setListing] = useState([]);
    const [statesWithListing, setStatesWithListing] = useState([]);
    const [loading, setLoading] = useState(false);

    // const [usStates, setUSStates] = useState([
    //     "Alabama",
    //     "Alaska",
    //     "Arizona",
    //     "Arkansas",
    //     "California",
    //     "Colorado",
    //     "Connecticut",
    //     "Delaware",
    //     "Florida",
    //     "Georgia",
    //     "Hawaii",
    //     "Idaho",
    //     "Illinois",
    //     "Indiana",
    //     "Iowa",
    //     "Kansas",
    //     "Kentucky",
    //     "Louisiana",
    //     "Maine",
    //     "Maryland",
    //     "Massachusetts",
    //     "Michigan",
    //     "Minnesota",
    //     "Mississippi",
    //     "Missouri",
    //     "Montana",
    //     "Nebraska",
    //     "Nevada",
    //     "New Hampshire",
    //     "New Jersey",
    //     "New Mexico",
    //     "New York",
    //     "North Carolina",
    //     "North Dakota",
    //     "Ohio",
    //     "Oklahoma",
    //     "Oregon",
    //     "Pennsylvania",
    //     "Rhode Island",
    //     "South Carolina",
    //     "South Dakota",
    //     "Tennessee",
    //     "Texas",
    //     "Utah",
    //     "Vermont",
    //     "Virginia",
    //     "Washington",
    //     "West Virginia",
    //     "Wisconsin",
    // ])

    // eslint-disable-next-line
    const [usStatesWithCode, setUSStatesWithCode] = useState([
        { "code": "AL", "name": "Alabama" },
        { "code": "AK", "name": "Alaska" },
        { "code": "AZ", "name": "Arizona" },
        { "code": "AR", "name": "Arkansas" },
        { "code": "CA", "name": "California" },
        { "code": "CO", "name": "Colorado" },
        { "code": "CT", "name": "Connecticut" },
        { "code": "DE", "name": "Delaware" },
        { "code": "DC", "name": "District Of Columbia" },
        { "code": "FL", "name": "Florida" },
        { "code": "GA", "name": "Georgia" },
        { "code": "HI", "name": "Hawaii" },
        { "code": "ID", "name": "Idaho" },
        { "code": "IL", "name": "Illinois" },
        { "code": "IN", "name": "Indiana" },
        { "code": "IA", "name": "Iowa" },
        { "code": "KS", "name": "Kansas" },
        { "code": "KY", "name": "Kentucky" },
        { "code": "LA", "name": "Louisiana" },
        { "code": "ME", "name": "Maine" },
        { "code": "MD", "name": "Maryland" },
        { "code": "MA", "name": "Massachusetts" },
        { "code": "MI", "name": "Michigan" },
        { "code": "MN", "name": "Minnesota" },
        { "code": "MS", "name": "Mississippi" },
        { "code": "MO", "name": "Missouri" },
        { "code": "MT", "name": "Montana" },
        { "code": "NE", "name": "Nebraska" },
        { "code": "NV", "name": "Nevada" },
        { "code": "NH", "name": "New Hampshire" },
        { "code": "NJ", "name": "New Jersey" },
        { "code": "NM", "name": "New Mexico" },
        { "code": "NY", "name": "New York" },
        { "code": "NC", "name": "North Carolina" },
        { "code": "ND", "name": "North Dakota" },
        { "code": "OH", "name": "Ohio" },
        { "code": "OK", "name": "Oklahoma" },
        { "code": "OR", "name": "Oregon" },
        { "code": "PA", "name": "Pennsylvania" },
        { "code": "PR", "name": "Puerto Rico" },
        { "code": "RI", "name": "Rhode Island" },
        { "code": "SC", "name": "South Carolina" },
        { "code": "SD", "name": "South Dakota" },
        { "code": "TN", "name": "Tennessee" },
        { "code": "TX", "name": "Texas" },
        { "code": "UT", "name": "Utah" },
        { "code": "VT", "name": "Vermont" },
        { "code": "VA", "name": "Virginia" },
        { "code": "VI", "name": "Virgin Islands" },
        { "code": "WA", "name": "Washington" },
        { "code": "WV", "name": "West Virginia" },
        { "code": "WI", "name": "Wisconsin" },
        { "code": "WY", "name": "Wyoming" }
    ])

    useEffect(() => {
        fetchListingByAreaCount();
        setLoading(true);
        // eslint-disable-next-line
    }, [])

    const LazyImage = (imageProps) => {
        const [shouldLoad, setShouldLoad] = useState(false);
        const placeholderRef = useRef(null);

        useEffect(() => {
            if (!shouldLoad && placeholderRef.current) {
                const observer = new IntersectionObserver(([{ intersectionRatio }]) => {
                    if (intersectionRatio > 0) {
                        setShouldLoad(true);
                    }
                });
                observer.observe(placeholderRef.current);
                return () => observer.disconnect();
            }
        }, [shouldLoad, placeholderRef]);

        return (shouldLoad
            ? <img {...imageProps} alt=""/>
            : <div className="img-placeholder" ref={placeholderRef} />
        );
    };

    const fetchListingByAreaCount = () => {
        const json = JSON.stringify({ "limit": 100 });

        axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_listing_by_area_count`, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                //console.log(response.data.listing);
                setListing(response.data.listing);

                //console.log("response.data.listing", response.data.listing);
                //console.log("usStatesWithCode", usStatesWithCode);

                let temp = [];
                response.data.listing.forEach(element => {
                    let { code, name } = usStatesWithCode.filter(e => e.code === element.state)[0];
                    temp.push({ "code": code, "name": name, "count": element.count });
                });
                //console.log("temp", temp);
                setStatesWithListing(temp);
                setLoading(false);
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
            setLoading(false);
        });
    }

    const nFormatter = (num, digits) => {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "G" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function (item) {
            return num >= item.value;
        });
        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
    }

    return (
        <>
            { /* start::listing-area  */}
            <section className="lg:py-20 border-b border-gray-200 dark:border-foreground dark:bg-background" >
                <div className="container mx-auto lg:p-0 p-5" >
                    <div className="flex lg:flex-row flex-col lg:text-left text-center flex-wrap items-center justify-between mb-12" >
                        <div className="mb-4 lg:mb-0 aos-init aos-animate" data-aos="fade-up" >
                            <h2 className="text-2xl lg:text-3xl font-medium capitalize mb-2 dark:text-gray-100" >listing by area </h2>
                            <p className="text-sm lg:text-base text-gray-500 font-normal dark:text-gray-400" >A selection of listing verified for quality </p>
                        </div>
                        {
                            /* <div data-aos="fade-up" className="aos-init aos-animate">
                                                        <a href="listing-list.html" className="text-gray-500 dark:text-gray-400 hover:text-blue-500 flex items-center">
                                                            Explore More
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 ml-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                                            </svg>
                                                        </a>
                                                    </div> */
                        }
                    </div>
                    <div className="grid grid-cols-2 lg:grid-cols-6 gap-4" > {/** grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-3 */}
                        {
                            (!loading && listing.length > 0 && statesWithListing.length > 0) && statesWithListing.map((s, index) => (
                                <Link to={`/search/${s.code}-${s.name.replace(" ", "-").toLowerCase()}`} className="relative group overflow-hidden rounded-lg aos-init aos-animate listingByAreaCard" data-aos="fade-up" data-aos-duration={800} key={index} > {/** style={{ minHeight: "255px", marginBottom: "2rem", marginRight: "2rem" }} */}
                                    {/* style={{ width: "180px", height: "280px", marginBottom: "2rem" }} */}
                                    {/* <img className="w-full h-full object-cover rounded-lg group-hover:scale-105 transition-all" src={`/img/States/${s.name}.jpg`} alt="" /> */}
                                    <LazyImage className={'w-full h-full object-cover rounded-lg group-hover:scale-105 transition-all'} src={`/img/States/${s.name}.webp`}/>
                                    <div className="absolute bottom-0 top-1/2 flex items-start flex-col justify-end left-0 pb-5 rounded-b-lg pt-2 card-linear-gradient w-full" style={{ paddingLeft: "5px" }}>
                                        <div className="flex items-start" >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 text-orange-400 mr-2 mt-0" viewBox="0 0 20 20" fill="currentColor" >
                                                <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                                            </svg>
                                            <div >
                                                <div className="text-md text-white font-semibold" > {s.name} </div>
                                                <p className="text-sm text-gray-100" > {nFormatter(s.count)} Listing available </p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ))
                        }
                        {
                            (loading && [...Array(6)].map((u, i) => (
                                <div className="relative group overflow-hidden rounded-lg aos-init aos-animate listingByAreaCard" data-aos="fade-up" data-aos-duration={800} key={i} > {/** style={{ minHeight: "255px", marginBottom: "2rem", marginRight: "2rem" }} */}
                                    {/* style={{ width: "180px", height: "280px", marginBottom: "2rem" }} */}
                                    <div className="w-full h-full object-cover rounded-lg group-hover:scale-105 transition-all skeleton" />
                                    <div className="absolute bottom-0 top-1/2 flex items-start flex-col justify-end left-0 pb-5 rounded-b-lg pt-2 card-linear-gradient w-full" style={{ paddingLeft: "5px" }}>
                                        <div className="flex items-start w-full">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 text-gray-300 mr-2 mt-0" viewBox="0 0 20 20" fill="currentColor" >
                                                <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                                            </svg>
                                            <div className="w-full h-12">
                                                <div className="text-md text-white font-semibold skeleton h-6 w-32 mb-1"></div>
                                                <p className="text-sm text-gray-100 skeleton h-6 w-32"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                            )
                        }
                    </div>
                    {
                        /* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7">
                                                <Link to={`/search/los-angeles`} className="relative group overflow-hidden rounded-lg aos-init aos-animate" data-aos="fade-up" data-aos-duration={800}>
                                                    <img className="w-full h-96 object-cover rounded-lg group-hover:scale-105 transition-all" src="/img/Image/landing-page-image/building.jpg" alt="" />
                                                    <div className="absolute bottom-0 top-1/2 flex items-start flex-col justify-end left-0 pb-5 pl-5 rounded-b-lg pt-2 card-linear-gradient w-full">
                                                        <div className="flex items-start">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 text-orange-400 mr-2 mt-1" viewBox="0 0 20 20" fill="currentColor">
                                                                <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                                                            </svg>
                                                            <div>
                                                                <div className="text-lg text-white font-semibold">Los Angeles</div>
                                                                <p className="text-14 text-gray-100">10 Listing available</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <Link to={`/search/bar-harbor`} className="relative rounded-lg overflow-hidden group aos-init aos-animate" data-aos="fade-up" data-aos-duration={800}>
                                                    <img className="w-full h-96 object-cover rounded-lg group-hover:scale-105 transition-all" src="/img/Image/landing-page-image/building-2.jpg" alt="" />
                                                    <div className="absolute bottom-0 top-1/2 flex items-start flex-col justify-end left-0 pb-5 pl-5 rounded-b-lg pt-2 card-linear-gradient w-full">
                                                        <div className="flex items-start">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 text-orange-400 mr-2 mt-1" viewBox="0 0 20 20" fill="currentColor">
                                                                <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                                                            </svg>
                                                            <div>
                                                                <div className="text-lg text-white font-semibold">Bar Harbor</div>
                                                                <p className="text-14 text-gray-100">30 Listing available</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <Link to={`/search/new-jersey`} className="relative rounded-lg overflow-hidden group aos-init aos-animate" data-aos="fade-up" data-aos-duration={800}>
                                                    <img className="w-full h-96 object-cover rounded-lg group-hover:scale-105 transition-all" src="/img/Image/landing-page-image/building-3.jpg" alt="" />
                                                    <div className="absolute bottom-0 top-1/2 flex items-start flex-col justify-end left-0 pb-5 pl-5 rounded-b-lg pt-2 card-linear-gradient w-full">
                                                        <div className="flex items-start">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 text-orange-400 mr-2 mt-1" viewBox="0 0 20 20" fill="currentColor">
                                                                <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                                                            </svg>
                                                            <div>
                                                                <div className="text-lg text-white font-semibold">New Jersey</div>
                                                                <p className="text-14 text-gray-100">20 Listing available</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <Link to={`/search/new-york`} className="relative rounded-lg overflow-hidden group aos-init aos-animate" data-aos="fade-up" data-aos-duration={800}>
                                                    <img className="w-full h-96 object-cover rounded-lg group-hover:scale-105 transition-all" src="/img/Image/landing-page-image/building-4.jpg" alt="" />
                                                    <div className="absolute bottom-0 top-1/2 flex items-start flex-col justify-end left-0 pb-5 pl-5 rounded-b-lg pt-2 card-linear-gradient w-full">
                                                        <div className="flex items-start">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 text-orange-400 mr-2 mt-1" viewBox="0 0 20 20" fill="currentColor">
                                                                <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                                                            </svg>
                                                            <div>
                                                                <div className="text-lg text-white font-semibold">New York</div>
                                                                <p className="text-14 text-gray-100">20 Listing available</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div> */
                    }
                </div>
            </section>
            { /* end::listing-area  */}
        </>
    )
}

export default ListingByArea