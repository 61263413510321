import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Alert from '../../../components/Alert';

import AdminSideBar from '../../../components/AdminSideBar';
import AdminHeader from '../../../components/AdminHeader';

import { Link, useNavigate, useParams } from "react-router-dom";
import { getCookie, checkRole, base64_encode, base64_decode, openInNewTab, roleBasedBreadCrumbUrl } from '../../../func';

import { EditorState, ContentState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';

let token = '';

// const toolbarOptions = {
//     options: ["inline", "fontSize", "image", "emoji"],
//     inline: {
//         options: ["bold", "italic", "underline", "strikethrough"],
//     },
// };

const toolbarOptions = {
    inline: { inDropdown: true },
    list: { inDropdown: true },
    textAlign: { inDropdown: true },
    link: { inDropdown: true },
    fontSize: {
        // icon: fontSize,
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
    },
    blockType: {
        inDropdown: true,
        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
    },
    embedded: {
        // icon: embedded,
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        embedCallback: undefined,
        defaultSize: {
            height: 'auto',
            width: 'auto',
        },
    },
    image: {
        // icon: image,
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        urlEnabled: true,
        uploadEnabled: true,
        alignmentEnabled: true,
        uploadCallback: undefined,
        previewImage: false,
        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
        alt: { present: false, mandatory: false },
        defaultSize: {
            height: 'auto',
            width: 'auto',
        },
    }
    // history: { inDropdown: true },
}

const EditPost = () => {
    const navigate = useNavigate();
    let params = useParams();

    const [postId, setPostId] = useState("");
    const [postTitle, setPostTitle] = useState("");
    const [postCategory, setPostCategory] = useState("");
    const [postContent, setPostContent] = useState("");
    const [postTitleCurrentImage, setTitleCurrentImage] = useState("");
    const [postStatus, setPostStatus] = useState("");

    const [postTitleImage, setTitleImage] = useState([]);
    const [loading, setLoading] = useState(true);

    const [myPost, setMyPost] = useState([]);

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    const [contentState, setContentState] = useState(EditorState.createEmpty()); // ContentState JSON

    useEffect(() => {
        token = getCookie("token");

        if (token === null) {
            // navigate(`/admin/login`, { replace: true });
            navigate(`/login`, { replace: true });
        }
        else {
            if (getCookie("token") !== undefined && getCookie("token") !== null) {
                //user is already loggedin
                if (checkRole(getCookie("token")) === "admin" || checkRole(getCookie("token")) === "author") {
                    if (params.postid !== undefined && params.postid !== null && params.postid.toString().trim() !== "")
                        fetchPostDetails(params.postid);
                    else {
                        navigate(`/admin/add_post`, { replace: true });
                    }
                }
                else
                    navigate(`/admin/add_listing`, { replace: true });
            }
        }
    }, []);

    useEffect(() => {
        //console.log("convertToRaw: ", convertToRaw(contentState.getCurrentContent()));
        //console.log("draftToHtml: ", draftToHtml(convertToRaw(contentState.getCurrentContent())));
        setPostContent(draftToHtml(convertToRaw(contentState.getCurrentContent())));
    }, [contentState]);

    // useEffect(()=>{
    //     handleMessage({ type: 'danger', message: "Please provide listing title!", show: true });
    // },[]);

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 3000);
    }

    const changeHandler = (e) => {
        let files = e.target.files;
        //console.log("files: ", files);

        var allFiles = [];
        for (var i = 0; i < files.length; i++) {

            let file = files[i];

            let ext = '';
            switch (file.type) {
                case "image/x-png":
                    ext = "png"
                    break;
                case "image/png":
                    ext = "png"
                    break;
                case "image/jpeg":
                    ext = "jpg"
                    break;
                case "image/webp":
                    ext = "webp"
                    break;
                default:
                    break;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let fileInfo = {
                    name: file.name.replace(`.${ext}`, ''),
                    type: file.type,
                    extention: ext,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result,
                    file: file,
                };

                allFiles.push(fileInfo);

                if (allFiles.length == files.length) {
                    //console.log(allFiles);
                    //if (this.props.multiple) this.props.onDone(allFiles);
                    //else this.props.onDone(allFiles[0]);
                    setTitleImage(allFiles);
                    setTitleCurrentImage("");
                }
            }
        }
    }

    const removeImage = (i) => {
        let array = [...postTitleImage];
        array.splice(i, 1);
        setTitleImage(array);
    }

    const fetchPostDetails = (postid) => {
        const json = JSON.stringify({ "postid": postid });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=post_details`; //development

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                let post = response.data.post;
                setMyPost(post);

                //console.log(post);
                setLoading(false);
                setPostId(post.post_id);
                setPostTitle(post.post_title);
                setPostCategory(post.category);
                // setPostContent(post.post_content);
                setTitleCurrentImage(post.post_picture);
                setPostStatus(post.post_publish);

                const contentBlock = htmlToDraft(post.post_content);
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                setContentState(editorState);
            }
            else {
                handleMessage({ type: 'danger', message: response.data.message, show: true });
            }
            setLoading(false);
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const updatePost = () => {
        let someThingChanged = false;
        if ((postTitle.trim !== "" && postTitle.trim() !== myPost.post_title.trim()) || (postCategory.trim() !== "" && postCategory.trim() !== myPost.category.trim()) || (postContent.trim() !== "" && postContent.trim() !== myPost.post_content.trim()) || (postTitleImage.length > 0) || (postStatus >= 0 && postStatus !== myPost.post_publish))
            someThingChanged = true;

        if (someThingChanged) {
            if (postId.toString().trim() !== "" && postTitle.trim() !== "" && postCategory.trim() !== "" && postContent.trim() !== "" && (postTitleCurrentImage !== "" || postTitleImage.length > 0) && postStatus >= 0) {

                const json = JSON.stringify({ "postid": postId.toString().trim(), "posttitle": postTitle.trim(), "category": postCategory.trim(), "postcontent": postContent.trim(), "token": token, "titleimage": (postTitleImage.length > 0 ? postTitleImage : postTitleCurrentImage), "status": postStatus });
                const url = `${process.env.REACT_APP_BASE_API}/api.php?action=update_post`; //development

                axios.post(url, JSON.stringify({ params: json }), {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then((response) => {
                    if (response.data.success === "true") {
                        setMyPost(response.data.posts);
                        handleMessage({ type: 'success', message: response.data.message, show: true });
                        cancelPost();

                        setTimeout(() => {
                            navigate(`/admin/add_post`, { replace: true });
                        }, 3000)
                    }
                    else {
                        handleMessage({ type: 'danger', message: response.data.message, show: true });
                    }
                    setLoading(false);
                }).catch((error) => {
                    console.error(`Error: ${error}`);
                });

            }
            else {
                if (postTitle.trim() === "" && postCategory.trim() === "" && postContent.trim() === "" && postTitleImage.length === 0 && postStatus === "")
                    handleMessage({ type: 'danger', message: "Please provide post title, category, content, title image and post status!", show: true });
                else if (postTitle.trim() === "")
                    handleMessage({ type: 'danger', message: "Please provide post title!", show: true });
                else if (postCategory.trim() === "")
                    handleMessage({ type: 'danger', message: "Please provide post category!", show: true });
                else if (postContent.trim() === "")
                    handleMessage({ type: 'danger', message: "Please provide post content!", show: true });
                else if (postTitleImage.length === 0)
                    handleMessage({ type: 'danger', message: "Please provide post title image!", show: true });
                else if (postStatus.trim() === "")
                    handleMessage({ type: 'danger', message: "Please provide post status!", show: true });
            }
        } else {
            handleMessage({ type: 'info', message: "Nothing Changed! Please make changes to update post", show: true });
        }
    }

    const cancelPost = () => {
        setLoading(false);
        setPostId('');
        setPostTitle('');
        setPostCategory('');
        setPostContent('');
        setTitleCurrentImage('');
        setPostStatus('');
        setTitleImage([]);
    }

    return (
        <>
            <AdminSideBar />
            <AdminHeader />

            <div className="dark:bg-background lg:ml-64 ml-0 mt-20 min-h-[calc(100vh-80px)] p-7">

                <section className="dark:bg-background">
                    <div className="container mx-auto">
                        <div className="flex text-gray-400 text-sm items-center mb-4">
                            {/* {
                                base64_decode(getCookie("token")).split("|")[3] === "admin"
                                    ?
                                    (<Link to={"/admin/dashboard"} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                                    :
                                    (<Link to={"/admin/add_listing"} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                            } */}
                            {
                                getCookie("token") !== null && (<Link to={roleBasedBreadCrumbUrl(base64_decode(getCookie("token")).split("|")[3])} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                            }
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 mx-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                            </svg>
                            <span>Edit Post</span>
                        </div>

                        <Alert type={alert.type} message={alert.message} show={alert.show} />

                        <div className="bg-white shadow-front-2 dark:bg-foreground rounded-lg p-6 mb-7">
                            <h4 className="text-gray-700 dark:text-gray-300 text-xl font-semibold mb-3">Edit Your Post</h4>
                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mb-4">
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Post Title <sup className="text-red-500">*</sup></label>
                                    <input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="Type a post title" value={postTitle} onChange={(e) => setPostTitle(e.target.value)} />
                                </div>
                            </div>

                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mb-4">
                                {/* <div className="lg:col-span-2 col-span-2"><label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Address <sup className="text-red-500">*</sup></label><input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. Bloosom" defaultValue /></div>
                                <div className="lg:col-span-1 col-span-2"><label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Road or Street <sup className="text-red-500">*</sup></label><input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. 12 San Pedro Street" defaultValue /></div>
                                <div className="lg:col-span-1 col-span-2"><label className="text-base text-gray-700 dark:text-gray-200 block mb-3">City<sup className="text-red-500">*</sup></label><input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. 12 San Pedro Street" defaultValue /></div> */}
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Category<sup className="text-red-500">*</sup></label>
                                    <div className="relative">
                                        <select name="state" className="bg-white w-full border border-gray-200 dark:bg-foreground dark:border-gray-800 relative appearance-none rounded-lg px-4 py-[0.969rem] focus:outline-blue-300 text-gray-500 focus:outline-none" value={postCategory} onChange={(e) => setPostCategory(e.target.value)}>
                                            <option value={-1}>Select Category</option>
                                            <option value="education">Education</option>
                                            <option value="events">Events</option>
                                            <option value="news">News</option>
                                        </select>
                                        <svg width={10} height={6} className="w-3 h-3 text-gray-400 absolute right-3 top-1/2 -translate-y-1/2 z-10" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74713 0.525274 9.25335 0.525274 9.56583 0.826613C9.87832 1.12795 9.87832 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mb-4">
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Content <sup className="text-red-500">*</sup></label>
                                    {/* <textarea className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" rows={10} placeholder="Write your content here....." value={postContent} onChange={(e) => setPostContent(e.target.value)} /> */}
                                    <Editor
                                        // editorState={editorState}
                                        // onEditorStateChange={setEditorState}
                                        // defaultContentState={contentState}
                                        // onContentStateChange={setContentState}
                                        editorState={contentState}
                                        // onContentStateChange={setContentState}
                                        onEditorStateChange={setContentState}
                                        editorClassName="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0 mh-500"
                                        placeholder="Write your content here....."
                                        toolbar={toolbarOptions}
                                    />
                                </div>
                            </div>

                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mb-4">
                                <div className="col-span-2">
                                    <div>
                                        <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Title Images <sup className="text-red-500">*</sup></label>
                                        <label htmlFor="upload-img" className="flex flex-col justify-center items-center cursor-pointer bg-gray-100 dark:bg-background dark:border-gray-800 border border-gray-200 rounded-lg py-10">
                                            <svg className="mb-2" width={43} height={42} viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_780_19100)">
                                                    <path d="M9.95 35C4.73084 35 0.5 30.9688 0.5 26C0.5 22.075 3.13616 18.7375 6.81312 17.5063C6.80459 17.3375 6.8 17.1687 6.8 17C6.8 11.475 11.4987 7 17.3 7C21.1916 7 24.5844 9.01562 26.4022 12.0125C27.3997 11.3187 28.6072 11 29.9 11C33.3781 11 36.2 13.6312 36.2 17C36.2 17.7625 36.0491 18.4875 35.78 19.1625C39.6125 19.9 42.5 23.1313 42.5 27C42.5 31.4188 38.7397 35 34.1 35H9.95ZM15.1344 21.4375C14.5241 22.025 14.5241 22.975 15.1344 23.5063C15.7512 24.1438 16.7487 24.1438 17.3066 23.5063L19.925 21.1187V29.5C19.925 30.3312 20.6272 31 21.5 31C22.3728 31 23.075 30.3312 23.075 29.5V21.1187L25.6344 23.5063C26.2512 24.1438 27.2487 24.1438 27.8066 23.5063C28.4759 22.975 28.4759 22.025 27.8066 21.4375L22.5566 16.4375C21.9987 15.8562 21.0012 15.8562 20.3844 16.4375L15.1344 21.4375Z" fill="#98A2B3" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_780_19100">
                                                        <rect width={42} height={42} fill="white" transform="translate(0.5)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <h6 className="text-base font-medium text-gray-700 dark:text-gray-400 mb-4">Drag and Drop Image</h6>
                                            <div className="text-base text-gray-700 dark:text-gray-400 mb-4">OR</div>
                                            <div className="bg-blue-500 text-white p-4 rounded-lg">Browse Image</div>
                                            <input type="file" id="upload-img" className="hidden" onChange={changeHandler} accept="image/x-png,image/png,image/jpeg" />
                                        </label>
                                    </div>
                                    {postTitleCurrentImage === "" ?
                                        (<div style={{ display: "flex", overflow: "overlay", paddingBottom: "30px", paddingLeft: "8px" }}>
                                            {postTitleImage.map((image, index) => (
                                                <div className="mt-2 p-4 mr-2 card" style={{ display: "flex", alignItems: "center", minHeight: "100px" }} key={index}>
                                                    <img style={{ maxWidth: "120px" }} src={image["base64"]} />
                                                    <i className="fa fa-window-close ml-2" style={{ color: "red", marginBottom: "80px" }} onClick={() => removeImage(index)}></i>
                                                </div>
                                            ))}
                                        </div>)
                                        :
                                        (postTitleCurrentImage !== "" && (<div className="mt-2 p-4 mr-2 card" style={{ display: "flex", alignItems: "center", minHeight: "100px" }}>
                                            <img style={{ maxWidth: "120px" }} src={`${process.env.REACT_APP_BASE_API}/${postTitleCurrentImage}`} />
                                            <i className="fa fa-window-close ml-2" style={{ color: "red", marginBottom: "80px" }} onClick={() => setTitleImage("")}></i>
                                        </div>))
                                    }
                                </div>
                            </div>

                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mb-4">
                                {/* <div className="lg:col-span-2 col-span-2"><label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Address <sup className="text-red-500">*</sup></label><input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. Bloosom" defaultValue /></div>
                                <div className="lg:col-span-1 col-span-2"><label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Road or Street <sup className="text-red-500">*</sup></label><input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. 12 San Pedro Street" defaultValue /></div>
                                <div className="lg:col-span-1 col-span-2"><label className="text-base text-gray-700 dark:text-gray-200 block mb-3">City<sup className="text-red-500">*</sup></label><input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. 12 San Pedro Street" defaultValue /></div> */}
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Status<sup className="text-red-500">*</sup></label>
                                    <div className="relative">
                                        <select name="state" className="bg-white w-full border border-gray-200 dark:bg-foreground dark:border-gray-800 relative appearance-none rounded-lg px-4 py-[0.969rem] focus:outline-blue-300 text-gray-500 focus:outline-none" value={postStatus} onChange={(e) => setPostStatus(e.target.value)}>
                                            <option value={-1}>Select Status</option>
                                            <option value={0}>Pending</option>
                                            <option value={1}>Publish</option>
                                        </select>
                                        <svg width={10} height={6} className="w-3 h-3 text-gray-400 absolute right-3 top-1/2 -translate-y-1/2 z-10" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74713 0.525274 9.25335 0.525274 9.56583 0.826613C9.87832 1.12795 9.87832 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="flex flex-wrap sm:justify-start justify-center">
                            <button type="button" className="px-6 py-5 bg-blue-500 hover:bg-blue-600 shadow-glow-blue text-white rounded-lg mr-4 mb-4" onClick={() => updatePost()}>Update Post</button>
                            <button type="button" className="px-6 py-[17px] mb-4 border border-gray-200 dark:border-gray-800 text-gray-500 dark:text-gray-200 rounded-lg mr-4" onClick={() => cancelPost()}>Cancel</button>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default EditPost