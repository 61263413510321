import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Alert from '../../../components/Alert';
import { Link, useNavigate } from 'react-router-dom'

import { getCookie, checkRole } from '../../../func';

const ForgetPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    useEffect(() => {
        if (getCookie("token") !== undefined && getCookie("token") !== null) {
            //user is already loggedin
            if (checkRole(getCookie("token")) === "admin")
                navigate(`/admin/dashboard`, { replace: true });
            else
                navigate(`/admin/add_listing`, { replace: true });
        }
    }, [])

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 5000);
    }

    const sendResetLink = (e) => {
        e.preventDefault();

        if (email.trim() !== "") {
            const json = JSON.stringify({ "email": email.trim() });

            axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=forgotpassword`, JSON.stringify({ params: json }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.data.success === "true") {
                    handleMessage({ type: 'success', message: response.data.message, show: true });
                    //console.log("token: ", response.data.token);

                    setTimeout(() => {
                        // navigate(`/admin/login`, { replace: true });
                        navigate(`/login`, { replace: true });
                    }, 2000);
                }
                else {
                    handleMessage({ type: 'danger', message: response.data.message, show: true });
                }
            }).catch((error) => {
                console.error(`Error: ${error}`);
            });
        }
        else {
            handleMessage({ type: 'danger', message: "Please provide your email to reset password", show: true });
        }
    }

    return (
        <section>
            <div className="mx-auto" style={{ backgroundColor: "#f5f5f5" }}>
                <div className="grid lg:grid-cols-1 grid-cols-1 gap-10 p-10 items-center rounded-lg">
                    {/* <img className="w-full lg:block hidden rounded-2xl" src="/img/sessions.png" alt="" style={{maxHeight: "500px", width: "auto"}} /> */}
                    {/* <div className='mx-auto'>
                        <Alert type={alert.type} message={alert.message} show={alert.show} />
                    </div> */}

                    <form className="mx-auto bg-white p-8" style={{ borderRadius: "0.5rem", maxWidth: "360px" }} onSubmit={sendResetLink}>
                        <div className="text-center mb-4">
                            <Alert type={alert.type} message={alert.message} show={alert.show} />
                            <Link to={"/"}>
                                <img className="w-20 mx-auto h-auto mb-4" src="/img/OR-logo.webp" alt="" />
                            </Link>
                            {/* <p className="text-gray-700 font-semibold text-lg mb-6">Accommo</p> */}
                            <h1 className="text-gray-700 text-2xl capitalize font-semibold mb-3">
                                forget your password
                            </h1>
                            {/* <p className="text-base text-gray-500 mb-7">
                                If you haven’t any account, please
                                <a href="/sign-up.html" className="text-blue-500 font-medium">Register</a>
                            </p> */}
                        </div>
                        <div>
                            <label className="text-gray-700 font-medium mb-1">Email <sup>*</sup></label>
                            <div className="relative mb-5">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-4 text-gray-400 absolute top-1/2 -translate-y-1/2 ml-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                </svg>
                                <input type="email" className="border pl-9 border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg dark:bg-foreground py-4 focus:outline-none w-full" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="flex justify-between flex-wrap mb-3">
                                <div className="mb-3">
                                    <label className="text-gray-500 font-normal">Already have account?</label>
                                </div>
                                <Link to={'/admin/forget_password'} className="text-red-500 mb-3">Login</Link>
                            </div>
                            <button type="sumbit" className="w-full flex justify-center items-center bg-blue-500 hover:bg-blue-600 rounded-lg text-white p-4 mb-2">
                                Send Password Reset Link
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        </section>
    )
}

export default ForgetPassword