import React from 'react'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PageContentContainer from '../../components/PageContentContainer';

// import ListingByArea from "../../components/ListingByArea";
// import TrendingListing from '../../components/TrendingListing';
//import ExploreByCategories from '../../components/ExploreByCategories';
import ClientsSaysAboutUs from '../../components/ClientsSaysAboutUs';
import OurBlogs from '../../components/OurBlogs';
// import Pricing from '../../components/Pricing';
import Newsletter from '../../components/Newsletter';
import ScrollToTopButton from '../../components/ScrollToTopButton';

const ContactUs = () => {
    return (
        <>
            <Header type={"contact-us"} />{/**home (for home page), else (for all other pages) */}

            <PageContentContainer>
                {/* <ListingByArea /> */}

                {/* <TrendingListing /> */}

                <ClientsSaysAboutUs />

                <OurBlogs />

                <Newsletter />

                <ScrollToTopButton />

                <Footer />
            </PageContentContainer>

        </>
    )
}

export default ContactUs