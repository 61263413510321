import React, { useState, useEffect } from 'react'
import AdminSideBar from '../../../components/AdminSideBar';
import AdminHeader from '../../../components/AdminHeader';

import axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { getCookie, checkRole, base64_decode, roleBasedBreadCrumbUrl } from '../../../func';
import Alert from '../../../components/Alert';

const AddReview = () => {
    const navigate = useNavigate();
    let params = useParams();

    const [listingid, setListingID] = useState(0);
    const [name, setName] = useState('');
    const [rating, setRating] = useState('');
    const [message, setMessage] = useState('');
    const [photo, setPhoto] = useState({});

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    useEffect(() => {
        if (getCookie("token") !== undefined && getCookie("token") !== null) {
            if (checkRole(getCookie("token")) === "admin" || checkRole(getCookie("token")) === "member") {
                if (params.listing_id !== undefined && params.listing_id !== null && params.listing_id.toString().trim() !== "")
                    setListingID(params.listing_id); //console.log(params.listing_id);
            }
            else if (checkRole(getCookie("token")) === "author") {
                navigate(`/admin/add_post`, { replace: true });
            }
            else {
                navigate(`/login`, { replace: true });
            }
        }
    }, [])


    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 10000);
    }

    const clearFields = () => {
        setName('');
        setRating('');
        setMessage('');
        setPhoto({});
    }

    const changeHandler = (e) => {
        let files = e.target.files;
        //console.log("files: ", files);

        var allFiles = [];
        for (var i = 0; i < files.length; i++) {

            let file = files[i];

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let fileInfo = {
                    name: file.name,
                    type: file.type,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result,
                    file: file,
                };

                allFiles.push(fileInfo);

                if (allFiles.length == files.length) {
                    console.log(allFiles);
                    //if (this.props.multiple) this.props.onDone(allFiles);
                    //else this.props.onDone(allFiles[0]);
                    setPhoto(allFiles);
                }
            }
        }
    }

    const addReview = () => {
        //handleMessage({ type: 'danger', message: "Please provide data in all fields", show: true });
        // console.log("listing_id: ", listingid);
        // console.log("name.trim(): ", name.trim());
        // console.log("rating.trim(): ", rating.trim());
        // console.log("message.trim(): ", message.trim());

        if (listingid !== 0 && name.trim() !== "" && rating.trim() !== "" && message.trim() !== "") {
            const json = JSON.stringify({ "listing_id": listingid, "name": name.trim(), "message": message.trim(), "photo": photo, "rating": rating.trim() });

            axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=add_review`, JSON.stringify({ params: json }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.data.success === "true") {
                    handleMessage({ type: 'success', message: response.data.message, show: true });

                    clearFields();
                }
                else {
                    handleMessage({ type: 'danger', message: response.data.message, show: true });
                }
            }).catch((error) => {
                console.error(`Error: ${error}`);
            });
        }
        else {
            handleMessage({ type: 'danger', message: "Please provide data in all fields", show: true });
        }
    }

    return (
        <>
            <AdminSideBar />
            <AdminHeader />

            <div className="dark:bg-background lg:ml-64 ml-0 mt-20 min-h-[calc(100vh-80px)] p-7">
                <section className="dark:bg-background">
                    <div className="container mx-auto">
                        <div className="flex text-gray-400 text-sm items-center mb-4">
                            {/* {
                                base64_decode(getCookie("token")).split("|")[3] === "admin"
                                    ?
                                    (<Link to={"/admin/dashboard"} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                                    :
                                    (<Link to={"/admin/add_listing"} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                            } */}
                            {
                                getCookie("token") !== null && (<Link to={roleBasedBreadCrumbUrl(base64_decode(getCookie("token")).split("|")[3])} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                            }
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 mx-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                            </svg>
                            <span>Add Review</span>
                        </div>
                        <Alert type={alert.type} message={alert.message} show={alert.show} />
                        <div className="bg-white shadow-front-2 dark:bg-foreground rounded-lg p-6">
                            <h4 className="text-gray-700 dark:text-gray-300 text-xl font-semibold">Add Review</h4>
                            <div className="flex justify-center mb-6">
                                <label htmlFor="profileUpload" className="cursor-pointer relative" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img className="w-32 h-32 rounded-full" src={photo[0] !== undefined ? photo[0]["base64"] : "/img/faces/Placeholder.png"} alt="" />
                                    <label htmlFor="profileUpload" className="flex items-center cursor-pointer justify-center mx-auto font-medium text-gray-700 dark:text-gray-300 dark:bg-gray-800 bg-gray-200 px-3 py-2 rounded-lg -mt-3 relative">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                                        </svg>Reviewer Photo</label>
                                    {/* <input id="profileUpload" type="file" className="hidden" /> */}
                                    <input type="file" id="profileUpload" className="hidden" onChange={changeHandler} accept="image/x-png,image/gif,image/jpeg" />
                                </label>
                            </div>
                            <div className="grid lg:grid-cols-2 gap-5">
                                <div>
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Reviewer Name</label>
                                    <input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-1" placeholder="e.g. John Doe" value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div>
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Rating</label>
                                    <input type="number" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-1" placeholder="i:e; 4" value={rating} onChange={(e) => setRating(e.target.value)} />
                                </div>
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Review</label>
                                    <textarea className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-1" id="exampleFormControlTextarea1" rows={5} placeholder="Review" value={message} onChange={(e) => setMessage(e.target.value)} />
                                </div>
                                <div className="col-span-2 flex flex-wrap items-center">
                                    <button type="button" className="bg-blue-500 hover:bg-blue-600 rounded-lg text-white px-10 py-2.5 mr-3 mb-4" onClick={() => addReview()}>
                                        Save
                                    </button>
                                    <button type="button" className="border border-gray-200 rounded-lg text-gray-500 dark:text-gray-300 mb-4 dark:border-gray-800 px-10 py-[9px] mr-3" onClick={() => clearFields()}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default AddReview