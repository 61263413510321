import React from 'react'

import Header from '../../components/Header';
import PageContentContainer from '../../components/PageContentContainer';
import Newsletter from '../../components/Newsletter';
import ScrollToTopButton from '../../components/ScrollToTopButton';
import Footer from '../../components/Footer';

const NotFound = () => {
    return (
        <>
            <Header type={"else"} />{/**home (for home page), else (for all other pages) */}

            <PageContentContainer>
                <>
                    <section className="dark:bg-background mb-12">
                        <div className="relative">
                            <img className="w-full object-cover h-52" src="/img/cover-2.png" alt="" />
                            <div className="absolute top-1/2 w-full -translate-y-1/2 text-center mb-4">
                                <h1 className="capitalize text-4xl text-white font-semibold">Resource Not Found</h1>
                            </div>
                        </div>
                    </section>
                    <section className="dark:bg-background lg:px-0 px-5 pb-20">
                        {/* <div className="container mx-auto">
                            <div className="grid lg:grid-cols-6 gap-7">
                                <div className="lg:col-span-6 col-span-6 " style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div className="grid lg:grid-cols-1 gap-7 h-32">
                                        <h1 style={{ fontSize: '8rem', fontWeight: 500 }}>404</h1>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div class="container mx-auto h-32 flex items-center justify-center">
                            <h1 style={{ fontSize: '8rem', fontWeight: 500 }}>404</h1>
                        </div>
                    </section>
                </>

                <Newsletter />

                <ScrollToTopButton />

                <Footer />
            </PageContentContainer>
        </>
    )
}

export default NotFound