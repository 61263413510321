import React, { useState, useRef } from 'react'
import Alert from '../Alert';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const regex = /(<([^>]+)>)/gi;

const ContactUsHeroForm = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [captchaValidated, setCaptchaValidated] = useState(false);
    const captcha = useRef(null);

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 3000);
    }

    const clearFields = () => {
        setName("");
        setEmail("");
        setMessage("");
    }

    const validateEmail = (email) => {
        if (!email) {
            handleMessage({ type: 'danger', message: "Please provide your email", show: true });//errors.email = 'Required'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            handleMessage({ type: 'danger', message: "Please provide valid email address", show: true });//errors.email = 'Invalid email address'
        }
        else
            return true;
    }

    const sendMessage = async () => {
        if (name.trim() !== "" && (email.trim() !== "" && validateEmail(email.trim())) && message.trim() !== "" && captchaValidated) {

            const json = JSON.stringify({ "name": name.trim(), "email": email.trim(), "message": message.trim() });
            const url = `${process.env.REACT_APP_BASE_API}/api.php?action=send_message`;

            axios.post(url, JSON.stringify({ params: json }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.data.success === "true") {
                    clearFields();
                    handleMessage({ type: 'success', message: "Thank you for contact us", show: true });
                }
                else {
                    handleMessage({ type: 'danger', message: response.data.message, show: true });
                }
            }).catch((error) => {
                console.error(`Error: ${error}`);
            });
        }
        else {
            if (name.trim() === "" && (email.trim() === "" || !validateEmail(email.trim())) && message.trim() === "")
                handleMessage({ type: 'danger', message: "Please provide your name, email and message", show: true });
            else if (name.trim() === "")
                handleMessage({ type: 'danger', message: "Please provide your name", show: true });
            else if (email.trim() === "")
                handleMessage({ type: 'danger', message: "Please provide your email", show: true });
            else if (message.trim() === "")
                handleMessage({ type: 'danger', message: "Please provide your message", show: true });
            else if (!captchaValidated)
                handleMessage({ type: 'danger', message: "Please solve captcha", show: true });
        }
    }

    const verifyToken = async (token) => {
        try {
            const json = JSON.stringify({ "reCAPTCHA_TOKEN": token });
            axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=verify_recaptcha`, JSON.stringify({ params: json }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.data.success === "true") {
                    setCaptchaValidated(true)
                }
                else {
                    handleMessage({ type: 'danger', message: response.data.message, show: true });
                }
            }).catch((error) => {
                console.error(`Error: ${error}`);
            });
        } catch (error) {
            console.log(error);
        }
    };

    const validateReCaptcha = () => {
        if (captcha.current.getValue()) {
            // console.log(captcha.current.getValue());
            // console.log(verifyToken(captcha.current.getValue()));
            verifyToken(captcha.current.getValue());
        }
    };

    return (
        <div className="pt-10 container mx-auto pb-10">
            <div className="lg:inline-flex flex flex-col justify-center border border-white rounded-lg bg-white bg-opacity-60 backdrop-filter backdrop-blur-lg py-5 px-5">
                <Alert type={alert.type} message={alert.message} show={alert.show} />
                <h1 className="capitalize text-3xl font-semibold mb-3 text-gray-800">
                    Contact Us
                </h1>
                <p className="capitalize text-gray-500 mb-4">
                    Need help! feel free to contact us
                </p>
                <input
                    type="text"
                    className="border border-gray-200 dark:border-gray-800 text-black dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[10px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-3"
                    placeholder="Please provide your name"
                    value={name} onChange={(e) => setName(e.target.value.replace(regex, ""))}
                />

                <input
                    type="email"
                    className="border border-gray-200 dark:border-gray-800 text-black dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[10px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-3"
                    placeholder="Please provide your email address"
                    value={email} onChange={(e) => setEmail(e.target.value.replace(regex, ""))}
                />

                <textarea
                    className="border border-gray-200 dark:border-gray-800 text-black dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[10px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-3"
                    rows="5"
                    placeholder="Please provide your message here"
                    value={message} onChange={(e) => setMessage(e.target.value.replace(regex, ""))}></textarea>

                <ReCAPTCHA
                    ref={captcha}
                    sitekey={SITE_KEY}
                    onChange={validateReCaptcha}
                    onExpired={() => setCaptchaValidated(false)}
                />

                {captchaValidated
                    ?
                    <button type="button" className="py-3 px-4 w-full bg-blue-500 hover:bg-blue-600 rounded-lg text-white my-4" onClick={() => sendMessage()}>Send Message</button>
                    :
                    <button type="button" className="py-3 px-4 w-full bg-blue-500 rounded-lg text-white my-4" disabled>Send Message</button>
                }

                {/* <button type="button" className="py-3 px-4 w-full bg-blue-500 hover:bg-blue-600 rounded-lg text-white my-4" onClick={() => sendMessage()}>Send Message</button> */}
            </div>
        </div>
    )
}

export default ContactUsHeroForm