import React from 'react'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PageContentContainer from '../../components/PageContentContainer';

import ScrollToTopButton from '../../components/ScrollToTopButton';

// import businesses_list from "../../../business";
import FeaturedListing from '../../components/FeaturedListing';
import RehabSearchForm from '../../components/RehabSearchForm';

const MedicationAssistedTreatment = () => {

  return (
    <>
      <Header />

      <PageContentContainer>

        <>
          <section className="dark:bg-background mb-12">
            <div className="relative">
              <img className="w-full object-cover h-52" src="/img/cover-2.png" alt="" />
              <div className="absolute top-1/2 w-full -translate-y-1/2 text-center mb-4">
                <h1 className="capitalize text-4xl text-white font-semibold">Medication Assisted Treatment</h1>
                <p className="text-white mt-2">Medication For Opioid Use Disorder (MOUD)</p>
              </div>
            </div>
          </section>

          <div className="container mx-auto px-5">
            <div className="grid grid-cols-3 gap-7 pb-10">

              <div className="lg:col-span-2 col-span-3">
                <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7">
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-3.jpg" alt="" /> */}
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-2.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-3.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-4.jpg" alt="" /> */}
                  <div className="p-6 border-b border-gray-200 dark:border-gray-800">
                    {/* <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit
                    </h5> */}

                    <div className="grid lg:grid-cols-2 md:grid-cols-2 lg:gap-0 gap-7 mt-8 mb-20">

                      <div className='grid-cols-1'>
                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5">There are many reasons that someone may be using opioids. More than <a className='text-blue-500 dark:text-gray-400 hover:text-blue-800' href="https://www.cdc.gov/opioids/Medication-Assisted-Treatment-Opioid-Use-Disorder-Study.html">2 million people in the U.S</a>. have an opioid use disorder (OUD) related to prescription opioids. Another quarter of a million people have an OUD related to heroin.</p>

                        <p className="text-gray-500 text-base dark:text-gray-300">For people who want to reduce or stop using opioids, evidence-based approaches are available to do this safely. Medications for opioid use disorder (MOUD) are one such approach, and they’re most effective for long-term success when someone chooses to start treatment (versus being coerced or mandated).</p>
                      </div>

                      <div className='grid-cols-1 flex h-full items-center'>
                        {/* <img src="https://www.thehopehouse.com/wp-content/uploads/2020/11/Client-speaking-with-doctor-in-opioid-rehab.jpg" width="730" height="475" alt="" class="lazyloaded" data-ll-status="loaded" /> */}
                        <img src="https://harmreduction.org/wp-content/uploads/2020/07/MOUD-01.png" alt="" class="w-full h-64 object-cover rounded-t-lg p-4" />
                      </div>

                    </div>

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      BENEFITS OF MEDICATIONS FOR OPIOID USE DISORDER
                    </h5>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      This evidence-based treatment has been proven to:
                    </h4>

                    <div className="grid lg:grid-cols-5 md:grid-cols-4 lg:gap-5 mt-8 mb-20">
                      <div className="flex items-center grid-cols-1 shadow-front-3 rounded-lg group bg-slate-100 dark:bg-background">
                        <p className="text-md font-medium text-gray-500 text-base dark:text-gray-300 p-4">
                          Support people who to choose to reduce or stop their opioid use
                        </p>
                      </div>
                      <div className="flex items-center grid-cols-1 shadow-front-3 rounded-lg group bg-slate-100 dark:bg-background">
                        <p className="text-md font-medium text-gray-500 text-base dark:text-gray-300 p-4">
                          Increase the likelihood that a person will continue to not use drugs
                        </p>
                      </div>
                      <div className="flex items-center grid-cols-1 shadow-front-3 rounded-lg group bg-slate-100 dark:bg-background">
                        <p className="text-md font-medium text-gray-500 text-base dark:text-gray-300 p-4">
                          Reduce opioid use and symptoms related to opioid use disorder
                        </p>
                      </div>
                      <div className="flex items-center grid-cols-1 shadow-front-3 rounded-lg group bg-slate-100 dark:bg-background">
                        <p className="text-md font-medium text-gray-500 text-base dark:text-gray-300 p-4">
                          Reduce the risk of infectious disease transmission
                        </p>
                      </div>
                      <div className="flex items-center grid-cols-1 shadow-front-3 rounded-lg group bg-slate-100 dark:bg-background">
                        <p className="text-md font-medium text-gray-500 text-base dark:text-gray-300 p-4">
                          Reduce the chances of an overdose related death
                        </p>
                      </div>
                    </div>


                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      Types of Medications for Opioid Use Disorder
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      There are two types of evidence-based medications used to treat opioid use disorder that we support: methadone and buprenorphine.
                    </p>

                    <div className="grid lg:grid-cols-2 md:grid-cols-2 mt-8 mb-8">
                      <div className="flex flex-col grid-cols-1 text-justify p-4 shadow-front-1 rounded-lg border border-gray-200 dark:border-gray-800 mr-1 bg-slate-100">
                        <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-1">
                          Buprenorphine
                        </h5>

                        <img src="https://harmreduction.org/wp-content/uploads/2020/07/MOUD-01.png" alt="" class="w-full h-48 object-cover rounded-t-lg mb-2" />

                        <p className="text-gray-500 text-base dark:text-gray-500 mb-5">What it is: a partial opioid agonist, meaning that it binds to those same opioid receptors but activates them less strongly than full agonists do.</p>
                        <p className="text-gray-500 text-base dark:text-gray-500 mb-5">How it works: reduces cravings and withdrawal symptoms without producing euphoria.</p>
                        <p className="text-gray-500 text-base dark:text-gray-500 mb-5">How to get it: can be prescribed by certified physicians through the Drug Addiction Treatment Act.</p>
                      </div>

                      <div className="flex flex-col grid-cols-1 text-justify p-4 shadow-front-1 rounded-lg border border-gray-200 dark:border-gray-800 mr-1 bg-slate-100">
                        <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-1">
                          Methadone
                        </h5>

                        <img src="https://harmreduction.org/wp-content/uploads/2020/08/MOUD-03.jpg" alt="" class="w-full h-48 object-cover rounded-t-lg mb-2" />

                        <p className="text-gray-500 text-base dark:text-gray-500 mb-5">What it is: synthetic opioid agonist that binds to opioid receptors</p>
                        <p className="text-gray-500 text-base dark:text-gray-500 mb-5">How it works: eliminates withdrawal symptoms and relieves cravings</p>
                        <p className="text-gray-500 text-base dark:text-gray-500 mb-5">How to get it: must be dispensed through specialized opioid treatment programs</p>
                      </div>
                    </div>


                    <h5 className="text-center text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6 mt-20">
                      Find Buprenorphine
                    </h5>

                    <p className="text-center text-gray-500 text-base dark:text-gray-300 mb-10">
                      Harm reduction organizations around the country are working to make medications for opioid use disorder accessible to anyone who needs them.
                    </p>

                    <div className="grid lg:grid-cols-2 md:grid-cols-2 mt-8 mb-8">
                      <div className="flex flex-col grid-cols-1 text-justify p-4 shadow-front-1 rounded-lg mr-1 bg-slate-100">
                        <p className="text-lg text-black dark:text-gray-500 mb-5">Local harm reduction organizations and syringe service programs can help connect you with services.</p>
                      </div>

                      <div className="flex flex-col grid-cols-1 text-justify p-4 shadow-front-1 rounded-lg mr-1 bg-slate-100">
                        <p className="text-lg text-black dark:text-gray-500 mb-5">SAMHSA’s buprenorphine finder is a directory of thousands of providers around the country who are authorized to treat opioid use disorder.</p>
                        <p className="text-md text-black dark:text-gray-500 mb-5"><a className="border-b" href="https://www.samhsa.gov/medication-assisted-treatment/practitioner-program-data/treatment-practitioner-locator">Find a provider near you.</a></p>
                        <p className="text-sm text-black dark:text-gray-500 mb-5">Please note that we have not vetted the providers on this list. You may want to call ahead to be sure that the information is still accurate.</p>
                      </div>
                    </div>

                    <div className='shadow-front-4 rounded-lg p-4 mt-20 bg-slate-100'>
                      <h5 className="text-center text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-800 pt-6">
                        Recent Wins
                      </h5>

                      <p className="text-center text-gray-500 text-base dark:text-gray-500 mb-10">
                        In 2019, National Harm Reduction Coalition partnered with <a className='text-blue-500 dark:text-gray-400 hover:text-blue-800' href="https://www.bridgetotreatment.org/">ED-Bridge</a> to expand access to buprenorphine in emergency room settings in California. Thanks to this effort, people seeking treatment for opioid use disorder can now access 24/7 treatment at 52 hospital sites on demand.
                      </p>
                    </div>

                    {/* <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequuntur quo perspiciatis deleniti debitis aspernatur eos voluptatibus veritatis, labore excepturi similique nisi quod repudiandae aliquid laborum fuga quos pariatur delectus quae.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi voluptates accusantium molestias magnam beatae modi dolores corrupti aliquam saepe fugit, vero repudiandae praesentium eum iste quia numquam ipsum, reiciendis consectetur!
                      Illo velit distinctio magnam corrupti quasi vero reprehenderit. Ab assumenda enim ipsam quia velit facere, maxime cumque eveniet eligendi ea officiis! Optio totam sit animi hic dolorum obcaecati illum asperiores.
                      Impedit deserunt illo accusantium? Nobis ex modi cum hic sed distinctio possimus placeat quos nam aut. Laborum obcaecati quis, rem exercitationem eum aliquam non? At magnam placeat molestias cum tempora!
                      Rerum sint praesentium voluptas deserunt officia nobis, porro recusandae necessitatibus omnis vero beatae perspiciatis expedita aperiam reiciendis eaque nostrum quis consequuntur iste eum ad sequi corrupti! Explicabo nobis sit praesentium.</p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam vel ea sunt molestias facere quidem! Unde, repellendus dolores neque voluptatibus id, sunt impedit, nobis molestiae quisquam aut aliquid rem sed.
                      Doloremque, voluptatum voluptas iure facere, laudantium molestiae sint quam corporis illum ad amet recusandae vitae ea, accusamus dicta! Expedita accusantium quo reprehenderit! Repellendus quod eos tenetur nesciunt deleniti, cumque enim.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda facilis eum aliquam delectus deleniti corrupti nulla veniam cumque velit, veritatis eos quia dolorum enim ut quidem? Beatae officia aliquam voluptas!
                      Dolorum quia at maxime aliquam distinctio, molestias ratione nesciunt quod cupiditate laudantium aspernatur consequatur eaque vero id eius rerum libero dolore nobis modi. Dicta, illum architecto expedita explicabo laboriosam perferendis.
                      Debitis maiores pariatur enim voluptatum? Optio ipsa dignissimos, dolore, debitis adipisci veniam sed et labore, odio ab incidunt nihil officiis temporibus ut nemo aliquid ad eos rerum qui deserunt ipsam!</p> */}
                  </div>
                </div>
              </div>

              <div className="lg:col-span-1 col-span-3">
                <RehabSearchForm />

                {/* {featuredListing()} */}
                {<FeaturedListing items={5}/>}

              </div>

            </div>
          </div>

        </>

        <ScrollToTopButton />

        <Footer />
      </PageContentContainer>

    </>
  )
}

export default MedicationAssistedTreatment