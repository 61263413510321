import React, { useState, useEffect, useRef, useMemo } from 'react'
import axios from 'axios';

import { Link } from 'react-router-dom';

import { base64_encode } from '../../func';

const useIsInViewport = (ref) => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setIsIntersecting(entry.isIntersecting),
            ),
        [],
    );

    useEffect(() => {
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);

    return isIntersecting;
}

// const useIntersection = (element, rootMargin) => {
//     const [isVisible, setState] = useState(false);

//     useEffect(() => {
//         const observer = new IntersectionObserver(
//             ([entry]) => {
//                 setState(entry.isIntersecting);
//             }, { rootMargin }
//         );

//         element.current && observer.observe(element.current);

//         return () => observer.unobserve(element.current);
//     }, []);

//     return isVisible;
// };

const OurBlogs = () => {
    const ref = useRef();
    const [posts, setPosts] = useState([]);

    const [hide, setHide] = useState(false);
    const [loading, setLoading] = useState(true);

    const inViewport = useIsInViewport(ref, '0px'); // Trigger as soon as the element becomes visible
    //const inViewport = useIntersection(ref, '0px'); // Trigger as soon as the element becomes visible
    //const inViewport = useIntersection(ref, '-200px'); // Trigger if 200px is visible from the element

    const LazyImage = (imageProps) => {
        const [shouldLoad, setShouldLoad] = useState(false);
        const placeholderRef = useRef(null);

        useEffect(() => {
            if (!shouldLoad && placeholderRef.current) {
                const observer = new IntersectionObserver(([{ intersectionRatio }]) => {
                    if (intersectionRatio > 0) {
                        setShouldLoad(true);
                    }
                });
                observer.observe(placeholderRef.current);
                return () => observer.disconnect();
            }
        }, [shouldLoad, placeholderRef]);

        return (shouldLoad
            ? <img {...imageProps} alt=""/>
            : <div className="img-placeholder" ref={placeholderRef} />
        );
    };

    const fetchBlogPosts = () => {
        const json = JSON.stringify({ "offset": 0, "limit": 5 });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_all_post`; //development

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                setPosts(response.data.posts);
                setLoading(false);
            }
            else {
                setPosts([]);
                setLoading(false);
                setHide(true);
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        }).finally(()=>{
            window["initializeSwiper"]();
        });
    }

    const removeTags = (str) => {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        return str.replace(/(<([^>]+)>)/ig, '');
    }

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric" }
        return new Date(dateString).toLocaleDateString(undefined, options)
    }

    const createLoadingLayout = () => {
        return (
            [...Array(10)].map((u, index) => (
                <div className="swiper-slide mt-4 lg:mb-8 swiper-slide-active aos-init" data-aos="fade-up" data-aos-duration={800} role="group" key={index}>
                    <div className="shadow-front-1 rounded-lg [&>div]:hover:shadow-front-3 group dark:bg-foreground">
                        <div className='rounded-t-lg skeleton w-full h-64'></div>
                        <div className="p-8 dark:bg-foreground rounded-b-lg shadow-front-1">
                            <div className='skeleton mb-2 h-8 w-full'></div>
                            <div className="skeleton mb-7 h-8 w-full"></div>
                            <div className="flex justify-between">
                                <div className="skeleton h-5 w-32"></div>
                                <div className="flex">
                                    <div className="flex mr-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                        </svg>
                                        <span className="skeleton h-5 w-8"></span>
                                    </div>
                                    <div className="flex mr-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                                        </svg>
                                        <span className="skeleton h-5 w-8"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))
        )
    }

    const createBlogPosts = () => {
        return (
            posts.map((post, index) => (
                <div className="swiper-slide mt-4 lg:mb-8 swiper-slide-active aos-init" data-aos="fade-up" data-aos-duration={800} role="group" key={index}>
                    <div className="shadow-front-1 rounded-lg [&>div]:hover:shadow-front-3 group dark:bg-foreground">
                        <Link to={`/post/${base64_encode(post.post_id.toString())}/${post.post_slug}`}>
                            {/* <img className="w-full h-64 object-cover rounded-t-lg" src={`${process.env.REACT_APP_BASE_API}/${post.post_picture}`} alt={`${post.post_title} title`} /> */}
                            <LazyImage className={'w-full h-64 object-cover rounded-t-lg'} src={`${process.env.REACT_APP_BASE_API}/${post.post_picture}`} alt={`${post.post_title} title`}/>
                        </Link>
                        <div className="p-8 dark:bg-foreground rounded-b-lg shadow-front-1">
                            <div style={{ height: "6rem", overflow: "hidden" }}>
                                <Link to={`/post/${base64_encode(post.post_id.toString())}/${post.post_slug}`} className="text-2xl group-hover:text-blue-500 font-semibold text-gray-700 dark:text-gray-100 mb-4">
                                    {post.post_title}
                                </Link>
                            </div>
                            <div className="text-sm text-gray-500 mb-7 dark:text-gray-400" style={{ height: "3.5rem", overflow: "hidden" }}>
                                {`${removeTags(post.post_content).split(/\s+/).slice(0, 15).join(" ")}...`}
                            </div>
                            <div className="flex justify-between">
                                <div className="flex items-center mr-4">
                                    <div className="bg-gray-200 dark:bg-gray-800 mr-3 w-9 h-9 rounded-full flex-shrink-0 flex justify-center items-center">
                                        <svg className="h-5 w-5 text-gray-500 dark:text-gray-400" width={17} height={18} viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.1235 17V15.2222C15.1235 14.2793 14.7515 13.3749 14.0893 12.7081C13.4271 12.0413 12.5291 11.6667 11.5926 11.6667H4.53087C3.59443 11.6667 2.69633 12.0413 2.03417 12.7081C1.372 13.3749 1 14.2793 1 15.2222L1 17" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M8.06174 8.11111C10.0118 8.11111 11.5926 6.51923 11.5926 4.55556C11.5926 2.59188 10.0118 1 8.06174 1C6.11169 1 4.53087 2.59188 4.53087 4.55556C4.53087 6.51923 6.11169 8.11111 8.06174 8.11111Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                    <div>
                                        <h6 className="text-gray-500 font-normal dark:text-gray-400 text-sm">Posted By</h6>
                                        <p className="text-gray-700 dark:text-gray-100 text-sm font-medium">{post.author.length <= 8 ? post.author : `${post.author.substring(0,8)}...`}</p>
                                    </div>
                                </div>

                                <div className="flex items-center mr-4">
                                    <div className="bg-gray-200 dark:bg-gray-800 mr-3 w-9 h-9 rounded-full flex-shrink-0 flex justify-center items-center">
                                        <svg className="h-5 w-5 text-gray-500 dark:text-gray-400" width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.08334 2H9.58334V0.75C9.58334 0.335938 9.91772 0 10.3333 0C10.749 0 11.0833 0.335938 11.0833 0.75V2H12.3333C13.4365 2 14.3333 2.89531 14.3333 4V14C14.3333 15.1031 13.4365 16 12.3333 16H2.33334C1.22866 16 0.333344 15.1031 0.333344 14V4C0.333344 2.89531 1.22866 2 2.33334 2H3.58334V0.75C3.58334 0.335938 3.91772 0 4.33334 0C4.74897 0 5.08334 0.335938 5.08334 0.75V2ZM1.83334 14C1.83334 14.275 2.05709 14.5 2.33334 14.5H12.3333C12.6083 14.5 12.8333 14.275 12.8333 14V6H1.83334V14Z" fill="currentColor" />
                                        </svg>
                                    </div>
                                    <div>
                                        <h6 className="text-gray-500 dark:text-gray-400 text-sm">Publish in</h6>
                                        <p className="text-gray-700 dark:text-gray-100 text-sm font-medium">{formatDate(post.post_datecreated)}</p>
                                    </div>
                                </div>
                                {/* <div className="text-sm font-normal dark:text-gray-400">
                                    22 Feb, 2022
                                </div>
                                <div className="flex">
                                    <div className="flex mr-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                        </svg>
                                        <span className="text-sm text-gray-500 dark:text-gray-400">245</span>
                                    </div>
                                    <div className="flex mr-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                                        </svg>
                                        <span className="text-sm text-gray-500 dark:text-gray-400">245</span>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            ))
        )
    }

    if (inViewport) {
        if (loading && posts.length === 0) {
            //console.log("loading posts");
            fetchBlogPosts();
        }
        //console.log('in viewport:', ref.current);
    }

    return (
        <>
            {/* start::blogs  */}
            <section className={!hide ? `lg:py-20 border-b border-gray-200 dark:border-foreground dark:bg-background` : `hidden`} ref={ref}>
                <div className="container mx-auto lg:p-0 p-5">
                    <div className="flex lg:flex-row flex-col lg:text-left text-center flex-wrap items-center justify-between mb-12">
                        <div className="lg:mb-0 mb-4">
                            <h1 className="text-2xl dark:text-gray-200 lg:text-3xl font-medium capitalize mb-2 aos-init" data-aos="fade-up" data-aos-duration={800}>
                                Our Blogs
                            </h1>
                            <p className="text-sm dark:text-gray-400 lg:text-base text-gray-500 font-normal aos-init" data-aos="fade-up" data-aos-duration={1000}>
                                See our all blogs{/* A selection of listing verified for quality */}
                            </p>
                        </div>
                        <div data-aos="fade-up" data-aos-duration={800} className="aos-init">
                            <Link to={"/blog"} type="button" className="text-gray-500 dark:text-gray-400 hover:text-blue-500 flex items-center">
                                Explore More
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 ml-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                </svg>
                            </Link>
                        </div>
                    </div>
                    {/* Slider main container */}
                    <div className="relative">
                        <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
                            {/* Additional required wrapper */}
                            <div className="swiper-wrapper relative h-full" id="swiper-wrapper-ccf481186e94618a" aria-live="polite" style={{ transform: 'translate3d(0px, 0px, 0px)' }}>
                                {/* Slides */}
                                {loading ? createLoadingLayout() : createBlogPosts()}
                                {/* <div className="swiper-slide mt-4 lg:mb-8 swiper-slide-active aos-init" data-aos="fade-up" data-aos-duration={800} role="group" aria-label="1 / 4" style={{ width: '385.333px', marginRight: '30px' }}>
                                    <div className="shadow-front-1 rounded-lg [&>div]:hover:shadow-front-3 group dark:bg-foreground">
                                        <a href="#">
                                            <img className="w-full h-64 object-cover rounded-t-lg" src="/img/Image/landing-page-image/house.jpg" alt="" />
                                        </a>
                                        <div className="p-8 dark:bg-foreground rounded-b-lg shadow-front-1">
                                            <a href="#" className="text-2xl group-hover:text-blue-500 font-semibold text-gray-700 dark:text-gray-100 mb-4">
                                                #1 Sell Your House Today
                                            </a>
                                            <div className="text-sm text-gray-500 mb-7 dark:text-gray-400">
                                                Nation yet I century way of the into he gone day time. And
                                                installer.
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="text-sm font-normal dark:text-gray-400">
                                                    22 Feb, 2022
                                                </div>
                                                <div className="flex">
                                                    <div className="flex mr-5">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                                        </svg>
                                                        <span className="text-sm text-gray-500 dark:text-gray-400">245</span>
                                                    </div>
                                                    <div className="flex mr-0">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                                                        </svg>
                                                        <span className="text-sm text-gray-500 dark:text-gray-400">245</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide mt-4 mb-8 swiper-slide-next aos-init" data-aos="fade-up" data-aos-duration={800} role="group" aria-label="2 / 4" style={{ width: '385.333px', marginRight: '30px' }}>
                                    <div className="rounded-lg shadow-front-1 [&>div]:hover:shadow-front-3 dark:bg-foreground group">
                                        <a href="#">
                                            <img className="w-full h-64 object-cover rounded-t-lg" src="/img/Image/event-landing-page/concert.jpg" alt="" />
                                        </a>
                                        <div className="p-8 dark:bg-foreground rounded-b-lg border-b-lg shadow-front-1">
                                            <a href="#" className="text-2xl font-semibold text-gray-700 dark:text-gray-100 mb-4 group-hover:text-blue-500">
                                                Musical Event House Today
                                            </a>
                                            <div className="text-sm text-gray-500 mb-7 dark:text-gray-400">
                                                Nation yet I century way of the into he gone day time. And
                                                installer.
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="text-sm font-normal dark:text-gray-400">
                                                    22 Feb, 2022
                                                </div>
                                                <div className="flex">
                                                    <div className="flex mr-5">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                                        </svg>
                                                        <span className="text-sm text-gray-500 dark:text-gray-400">245</span>
                                                    </div>
                                                    <div className="flex mr-0">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                                                        </svg>
                                                        <span className="text-sm text-gray-500 dark:text-gray-400">245</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide mt-4 mb-8 aos-init" data-aos="fade-up" data-aos-duration={800} role="group" aria-label="3 / 4" style={{ width: '385.333px', marginRight: '30px' }}>
                                    <div className="shadow-front-1 rounded-lg dark:bg-foreground [&>div]:hover:shadow-front-3 group">
                                        <a href="#">
                                            <img className="w-full h-64 object-cover rounded-t-lg" src="/img/Image/landing-page-image/dish.jpg" alt="" />
                                        </a>
                                        <div className="p-8 dark:bg-foreground shadow-front-1">
                                            <a href="#" className="text-2xl font-semibold text-gray-700 dark:text-gray-100 mb-4 group-hover:text-blue-500">
                                                #1 Sell Your House Today
                                            </a>
                                            <div className="text-sm text-gray-500 dark:text-gray-400 mb-7">
                                                Nation yet I century way of the into he gone day time. And
                                                installer.
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="text-sm font-normal dark:text-gray-400">
                                                    22 Feb, 2022
                                                </div>
                                                <div className="flex">
                                                    <div className="flex mr-5">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                                        </svg>
                                                        <span className="text-sm text-gray-500">245</span>
                                                    </div>
                                                    <div className="flex mr-0">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                                                        </svg>
                                                        <span className="text-sm text-gray-500">245</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide mt-4 mb-8 aos-init" data-aos="fade-up" data-aos-duration={800} role="group" aria-label="4 / 4" style={{ width: '385.333px', marginRight: '30px' }}>
                                    <div className="shadow-front-1 rounded-lg [&>div]:hover:shadow-front-3 group dark:bg-foreground">
                                        <a href="#">
                                            <img className="w-full h-64 object-cover rounded-t-lg" src="/img/Image/landing-page-image/house.jpg" alt="" />
                                        </a>
                                        <div className="p-8 dark:bg-foreground rounded-b-lg shadow-front-1">
                                            <a href="#" className="text-2xl group-hover:text-blue-500 font-semibold text-gray-700 dark:text-gray-100 mb-4">
                                                #1 Sell Your House Today
                                            </a>
                                            <div className="text-sm text-gray-500 mb-7 dark:text-gray-400">
                                                Nation yet I century way of the into he gone day time. And
                                                installer.
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="text-sm font-normal dark:text-gray-400">
                                                    22 Feb, 2022
                                                </div>
                                                <div className="flex">
                                                    <div className="flex mr-5">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                                        </svg>
                                                        <span className="text-sm text-gray-500 dark:text-gray-400">245</span>
                                                    </div>
                                                    <div className="flex mr-0">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mr-1 dark:text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                                                        </svg>
                                                        <span className="text-sm text-gray-500 dark:text-gray-400">245</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            {/* If we need pagination */}
                            <div className="swiper-pagination" />
                            {/* If we need navigation buttons */}
                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true" />
                        </div>
                        <div className="swiper-button-prev left-2 lg:-left-3 after:hidden swiper-button-disabled" tabIndex={0} role="button" aria-label="Previous slide" aria-controls="swiper-wrapper-cd31810942f34d10c7" aria-disabled="true">
                            <button type="button" className="bg-white dark:bg-foreground text-blue-500 hover:bg-blue-500 hover:text-white rounded-full p-3" aria-label="Previous slide">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                                </svg>
                            </button>
                        </div>
                        <div className="swiper-button-next absolute right-2 lg:-right-3 after:hidden" tabIndex={0} role="button" aria-label="Next slide" aria-controls="swiper-wrapper-cd31810942f34d10c7" aria-disabled="false">
                            <button type="button" className="bg-white dark:bg-foreground text-blue-500 hover:bg-blue-500 hover:text-white rounded-full p-3" aria-label="Next slide">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="relative w-24 mx-auto mt-10" />
                </div>
            </section>
            {/* end::blogs  */}
        </>
    )
}

export default OurBlogs