import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Link, useNavigate, useParams } from "react-router-dom";

import Alert from '../../../components/Alert';
import AdminSideBar from '../../../components/AdminSideBar';
import AdminHeader from '../../../components/AdminHeader';

import { validateEmail, validatePhone, validateUrl, base64_decode, getCookie, base64_encode, roleBasedBreadCrumbUrl } from '../../../func';

const Profile = () => {
    const navigate = useNavigate();
    let params = useParams();

    const [userId, setUserId] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [bio, setBio] = useState('');
    const [facebook, setFacebook] = useState('');
    const [twitter, setTwitter] = useState('');
    const [instagram, setInstagram] = useState('');
    const [linkedIn, setLinkedIn] = useState('');
    const [photo, setPhoto] = useState('');

    const [userDetails, setUserDetails] = useState({});

    const [image, setImage] = useState([]);

    const [editable, setEditable] = useState(false);

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    useEffect(() => {
        if (getCookie("token") !== undefined && getCookie("token") !== null) {
            if (params.user_token !== undefined && params.user_token !== null && params.user_token.toString().trim() !== "") {
                // const token_parts = window.atob(window.atob(params.user_token.trim())).split('|');
                // fetchProfileDetails(window.btoa(window.btoa(token_parts[0])));
                const token_parts = base64_decode(params.user_token.trim()).split('|');
                fetchProfileDetails(base64_encode(base64_encode(token_parts[0])));
            }
            else {
                const token_parts = base64_decode(getCookie("token").trim()).split('|');
                fetchProfileDetails(base64_encode(base64_encode(token_parts[0])));
            }
        }
        else {
            // navigate(`/admin/login`, { replace: true });
            navigate(`/login`, { replace: true });
        }
    }, [])

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 5000);
    }

    const fetchProfileDetails = (user_id) => {
        if (user_id.trim() !== "") {
            const json = JSON.stringify({ "user_id": user_id });

            axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_user_details`, JSON.stringify({ params: json }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.data.success === "true") {
                    //setUsers(response.data.users);
                    //setLoading(false);
                    setUserDetails(response.data.user)
                    setUserId(window.btoa(window.btoa(response.data.user["user_id"])));
                    setFirstName(response.data.user["first_name"]);
                    setLastName(response.data.user["last_name"]);
                    setAddress(response.data.user["address"]);
                    setPhone(response.data.user["phone"]);
                    setBio(response.data.user["about"]);
                    setFacebook(response.data.user["facebook"]);
                    setTwitter(response.data.user["twitter"]);
                    setInstagram(response.data.user["instagram"]);
                    setLinkedIn(response.data.user["linkedin"]);
                    setPhoto(response.data.user["photo"]);
                    //console.log(response.data.user);

                    const logged_in_user_id = base64_decode(getCookie("token").trim()).split('|')[0];
                    const profile_user_id = response.data.user["user_id"];

                    if (Number(logged_in_user_id) === Number(profile_user_id))
                        setEditable(true);
                    else
                        setEditable(false);
                }
            }).catch((error) => {
                console.error(`Error: ${error}`);
                //setLoading(true);
                setUserDetails({});
            });
        }
        else {
            handleMessage({ type: 'danger', message: "Invalid request", show: true });
        }
    }

    const changeHandler = (e) => {
        let files = e.target.files;
        //console.log("files: ", files);

        var allFiles = [];
        for (var i = 0; i < files.length; i++) {

            let file = files[i];

            let ext = '';
            switch (file.type) {
                case "image/x-png":
                    ext = "png"
                    break;
                case "image/png":
                    ext = "png"
                    break;
                case "image/jpeg":
                    ext = "jpg"
                    break;
                case "image/webp":
                    ext = "webp"
                    break;
                default:
                    break;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let fileInfo = {
                    name: file.name.replace(`.${ext}`, ''),
                    type: file.type,
                    extention: ext,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result,
                    file: file,
                };

                allFiles.push(fileInfo);

                if (allFiles.length === files.length) {
                    //console.log(allFiles);
                    //if (this.props.multiple) this.props.onDone(allFiles);
                    //else this.props.onDone(allFiles[0]);
                    setImage(allFiles);
                }
            }
        }
    }

    const updateProfile = () => {
        debugger;
        if (image.length > 0 || userDetails.first_name.trim() !== firstName.trim() || userDetails.last_name.trim() !== lastName.trim() || userDetails.address.trim() !== address.trim() || userDetails.phone.trim() !== phone.trim() || userDetails.about.trim() !== bio.trim() || userDetails.facebook.trim() !== facebook.trim() || userDetails.twitter.trim() !== twitter.trim() || userDetails.instagram.trim() !== instagram.trim() || userDetails.linkedin.trim() !== linkedIn.trim()) {

            const json = JSON.stringify({ "user_id": userId, "first_name": firstName, "last_name": lastName, "address": address, "phone": phone, "about": bio, "facebook": facebook, "twitter": twitter, "instagram": instagram, "linkedin": linkedIn, "photo": image[0] });

            axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=update_profile`, JSON.stringify({ params: json }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.data.success === "true") {
                    handleMessage({ type: 'success', message: "Profile updated successfully!", show: true });

                    resetFields();

                    fetchProfileDetails(userId);
                }
            }).catch((error) => {
                //console.error(`Error: ${error}`);
                //setLoading(true);
                handleMessage({ type: 'danger', message: "Something went wrong, try again later!", show: true });
            });
        }
        else {
            handleMessage({ type: 'danger', message: "Nothing change, Please update any field before saving", show: true });
        }
    }

    const resetFields = () => {
        // setUserId('');
        // setFirstName('');
        // setLastName('');
        // setAddress('');
        // setPhone('');
        // setBio('');
        // setFacebook('');
        // setTwitter('');
        // setInstagram('');
        // setLinkedIn('');
        // setPhoto('');
        setImage([]);

        setUserId(window.btoa(window.btoa(userDetails["user_id"])));
        setFirstName(userDetails["first_name"]);
        setLastName(userDetails["last_name"]);
        setAddress(userDetails["address"]);
        setPhone(userDetails["phone"]);
        setBio(userDetails["about"]);
        setFacebook(userDetails["facebook"]);
        setTwitter(userDetails["twitter"]);
        setInstagram(userDetails["instagram"]);
        setLinkedIn(userDetails["linkedin"]);
        setPhoto(userDetails["photo"]);
    }

    return (
        <>
            <AdminSideBar />
            <AdminHeader />

            <div className="dark:bg-background lg:ml-64 ml-0 mt-20 min-h-[calc(100vh-80px)] p-7">
                <section className="dark:bg-background">
                    <div className="container mx-auto">
                        <div className="flex text-gray-400 text-sm items-center mb-4">
                            {/* {
                                base64_decode(getCookie("token")).split("|")[3] === "admin"
                                    ?
                                    (<Link to={"/admin/dashboard"} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                                    :
                                    (<Link to={"/admin/add_listing"} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                            } */}
                            {
                                getCookie("token") !== null && (<Link to={roleBasedBreadCrumbUrl(base64_decode(getCookie("token")).split("|")[3])} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                            }
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 mx-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                            </svg>
                            <span>Profile</span>
                        </div>
                        <Alert type={alert.type} message={alert.message} show={alert.show} />
                        <div className="bg-white shadow-front-2 dark:bg-foreground rounded-lg p-6">
                            <div className='flex justify-between mb-3'>
                                <h4 className="text-gray-700 dark:text-gray-300 text-xl font-semibold">
                                    Profile Details
                                </h4>
                                <div className='flex justify-between items-center'>

                                    <div className="items-center text-center">
                                        {Boolean(userDetails.verified)
                                            ? (<div className="inline-flex items-center bg-green-100 text-xs font-medium text-green-500 px-3 py-1 rounded mr-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
                                                </svg>
                                                Verified
                                            </div>)
                                            :
                                            (<div className="bg-red-100 text-red-500 px-3 py-1 rounded mr-3 font-medium text-xs">
                                                Not-Verified
                                            </div>)}
                                    </div>

                                    <div className="items-center text-center">
                                        {Boolean(userDetails.status)
                                            ? (<div className="bg-green-100 text-xs font-medium text-green-500 px-3 py-1 rounded mr-3">
                                                Active
                                            </div>)
                                            :
                                            (<div className="bg-red-100 text-red-500 px-3 py-1 rounded mr-3 font-medium text-xs">
                                                De-actived
                                            </div>)
                                        }
                                    </div>

                                </div>
                            </div>
                            <div className="flex justify-center mb-12">
                                <label htmlFor="profileUpload" className="cursor-pointer relative">
                                    {
                                        image.length === 0
                                            ?
                                            (
                                                (userDetails.photo !== "" && userDetails.photo !== undefined)
                                                    ?
                                                    <img className="w-48 h-48 rounded-full" src={`${process.env.REACT_APP_BASE_API}${userDetails.photo.replace('/images/profiles/', '/images/profile_pic/')}`} alt="" />
                                                    :
                                                    <img className="w-48 h-48 rounded-full" src="/img/faces/Placeholder.png" alt="" />
                                            )
                                            :
                                            <img className="w-48 h-48 rounded-full" src={`${image[0]["base64"]}`} alt="" />
                                    }
                                    <label htmlFor="profileUpload" className="flex items-center cursor-pointer justify-center mx-auto font-medium text-gray-700 dark:text-gray-300 dark:bg-gray-800 bg-gray-200 px-3 py-2 rounded-lg -mt-3 relative">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                                        </svg>
                                        Upload Photo
                                    </label>
                                    {/* <input id="profileUpload" type="file" className="hidden" /> */}
                                    <input type="file" id="profileUpload" className="hidden" onChange={changeHandler} accept="image/x-png,image/gif,image/jpeg" multiple disabled={editable ? false : true} />
                                </label>
                            </div>
                            <h4 className="text-gray-700 dark:text-gray-300 text-xl font-semibold mb-7">
                                Personal Information
                            </h4>
                            <div className="grid lg:grid-cols-2 gap-7">
                                <div>
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">First Name</label>
                                    <input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-4" placeholder="first name" value={firstName.trim() !== "" ? firstName : ""} onChange={(e) => setFirstName(e.target.value)} disabled={editable ? false : true} />
                                </div>
                                <div>
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Last Name</label>
                                    <input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-4" placeholder="last name" value={lastName.trim() !== "" ? lastName : ""} onChange={(e) => setLastName(e.target.value)} disabled={editable ? false : true} />
                                </div>
                                <div>
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Address</label>
                                    <input type="address" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-4" placeholder="address" value={address.trim() !== "" ? address : ""} onChange={(e) => setAddress(e.target.value)} disabled={editable ? false : true} />
                                </div>
                                <div>
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Phone</label>
                                    <input type="phone" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-4" placeholder="phone" value={phone.trim() !== "" ? phone : ""} onChange={(e) => setPhone(e.target.value)} onBlur={() => phone.trim() !== "" ? (!validatePhone(phone) && setPhone('')) : null} disabled={editable ? false : true} />
                                </div>
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Bio</label>
                                    <textarea className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-4" rows={5} placeholder="short bio" value={bio.trim() !== "" ? bio : ""} onChange={(e) => setBio(e.target.value)} disabled={editable ? false : true} />
                                </div>
                                <div>
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Facebook</label>
                                    <input type="url" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-4" placeholder="Facebook" value={facebook.trim() !== "" ? facebook : ""} onChange={(e) => setFacebook(e.target.value)} onBlur={() => facebook.trim() !== "" ? (!validateUrl(facebook) && setFacebook('')) : null} disabled={editable ? false : true} />
                                </div>
                                <div>
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Twitter</label>
                                    <input type="url" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-4" placeholder="twitter" value={twitter.trim() !== "" ? twitter : ""} onChange={(e) => setTwitter(e.target.value)} onBlur={() => twitter.trim() !== "" ? (!validateUrl(twitter) && setTwitter('')) : null} disabled={editable ? false : true} />
                                </div>
                                <div>
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Instagram</label>
                                    <input type="url" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-4" placeholder="Instagram" value={instagram.trim() !== "" ? instagram : ""} onChange={(e) => setInstagram(e.target.value)} onBlur={() => instagram.trim() !== "" ? (!validateUrl(instagram) && setInstagram('')) : null} disabled={editable ? false : true} />
                                </div>
                                <div>
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Linkedin</label>
                                    <input type="url" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-4" placeholder="Linkedin" value={linkedIn.trim() !== "" ? linkedIn : ""} onChange={(e) => setLinkedIn(e.target.value)} onBlur={() => linkedIn.trim() !== "" ? (!validateUrl(linkedIn) && setLinkedIn('')) : null} disabled={editable ? false : true} />
                                </div>
                                <div className="col-span-2 flex flex-wrap items-center">
                                    {/* {editable ?
                                        (<button type="button" className="bg-blue-500 hover:bg-blue-600 rounded-lg text-white px-10 py-2.5 mr-3 mb-4" onClick={updateProfile}>
                                            Update Profile
                                        </button>)
                                        :
                                        (<button type="button" className="bg-blue-500 hover:bg-blue-600 rounded-lg text-white px-10 py-2.5 mr-3 mb-4">
                                            Update Profile
                                        </button>)
                                    } */}
                                    <button type="button" className={editable ? "bg-blue-500 hover:bg-blue-600 rounded-lg text-white px-10 py-2.5 mr-3 mb-4" : "bg-blue-200 rounded-lg text-white px-10 py-2.5 mr-3 mb-4"} onClick={updateProfile} disabled={editable ? false : true}>
                                        Update Profile
                                    </button>
                                    <button type="button" className="border border-gray-200 rounded-lg text-gray-500 dark:text-gray-300 mb-4 dark:border-gray-800 px-10 py-[9px] mr-3" onClick={resetFields}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}

export default Profile