import React, { useEffect } from 'react'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PageContentContainer from '../../components/PageContentContainer';

import ScrollToTopButton from '../../components/ScrollToTopButton';
import { appendScript, removeScript } from "../../func";

//import businesses_list from "../../../business";
import FeaturedListing from '../../components/FeaturedListing';
import RehabSearchForm from '../../components/RehabSearchForm';

const Detox = () => {

  useEffect(() => {
    appendScript("/js/plugins-core/handy-collapse.js", "handy-collapse");
    setTimeout(() => {
      appendScript("/js/plugins-script/handy-collapse.js", "handy-collapse-function");
    }, 100)

    return () => {
      removeScript("handy-collapse");
      removeScript("handy-collapse-function");
    }

  }, []);

  // const featuredListing = () => {
  //   return (
  //     <div className="shadow-front-2 dark:bg-foreground p-6 rounded-lg mb-7">
  //       <div className="flex justify-between mb-6">
  //         <h4 className="text-base font-medium text-gray-700 dark:text-gray-100">
  //           Feature Listings
  //         </h4>
  //         {/* <a href="#" className="text-gray-500 dark:text-gray-400 text-sm">More</a> */}
  //       </div>

  //       {
  //         businesses_list.map(({ alias, name, image_url, is_closed }, index) => (
  //           index < 5 && <Link to={`/details/${alias}`} key={index} className="flex mb-6">
  //             <img className="w-24 h-auto flex-shrink-0 rounded-lg mr-4" src={image_url} alt={name} />
  //             <div>
  //               <h6 className="text-gray-700 dark:text-gray-300 font-medium mb-2">
  //                 {name}
  //               </h6>
  //               <div className="dark:bg-gray-900 rounded-full justify-center inline-flex items-center">
  //                 {
  //                   !is_closed ?
  //                     (<span className="bg-green-600 text-xs font-medium text-white px-3 py-1 rounded-full">Open</span>)
  //                     :
  //                     (<span className="bg-red-100 text-xs font-medium dark:text-gray-400 px-3 py-1 rounded-full">Close</span>)
  //                 }
  //               </div>
  //               {/* <div className="bg-gray-100 dark:bg-gray-900 rounded-full px-3 py-1 inline-flex items-center">
  //                 <svg className="w-3 h-auto text-green-500 mr-2" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  //                   <path d="M4.6498 2.39995H7.3498V1.64995C7.3498 1.40151 7.55043 1.19995 7.7998 1.19995C8.04918 1.19995 8.2498 1.40151 8.2498 1.64995V2.39995H8.9998C9.66168 2.39995 10.1998 2.93714 10.1998 3.59995V9.59995C10.1998 10.2618 9.66168 10.8 8.9998 10.8H2.9998C2.33699 10.8 1.7998 10.2618 1.7998 9.59995V3.59995C1.7998 2.93714 2.33699 2.39995 2.9998 2.39995H3.7498V1.64995C3.7498 1.40151 3.95043 1.19995 4.1998 1.19995C4.44918 1.19995 4.6498 1.40151 4.6498 1.64995V2.39995ZM2.6998 9.59995C2.6998 9.76495 2.83405 9.89995 2.9998 9.89995H8.9998C9.1648 9.89995 9.2998 9.76495 9.2998 9.59995V4.79995H2.6998V9.59995Z" fill="currentColor"></path>
  //                 </svg>
  //                 <span className="text-xs font-medium dark:text-gray-400">Instant Booking</span>
  //               </div> */}
  //             </div>
  //           </Link>
  //         ))
  //       }

  //       {/* <a href="#" className="flex mb-6">
  //         <img className="w-24 h-auto flex-shrink-0 rounded-lg mr-4" src="img/Image/landing-page-image/building-2.jpg" alt="" />
  //         <div>
  //           <h6 className="text-gray-700 dark:text-gray-300 font-medium mb-2">
  //             Duplex Apartment with Pool
  //           </h6>
  //           <div className="bg-gray-100 dark:bg-gray-900 rounded-full px-3 py-1 inline-flex items-center">
  //             <svg className="w-3 h-auto text-green-500 mr-2" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  //               <path d="M4.6498 2.39995H7.3498V1.64995C7.3498 1.40151 7.55043 1.19995 7.7998 1.19995C8.04918 1.19995 8.2498 1.40151 8.2498 1.64995V2.39995H8.9998C9.66168 2.39995 10.1998 2.93714 10.1998 3.59995V9.59995C10.1998 10.2618 9.66168 10.8 8.9998 10.8H2.9998C2.33699 10.8 1.7998 10.2618 1.7998 9.59995V3.59995C1.7998 2.93714 2.33699 2.39995 2.9998 2.39995H3.7498V1.64995C3.7498 1.40151 3.95043 1.19995 4.1998 1.19995C4.44918 1.19995 4.6498 1.40151 4.6498 1.64995V2.39995ZM2.6998 9.59995C2.6998 9.76495 2.83405 9.89995 2.9998 9.89995H8.9998C9.1648 9.89995 9.2998 9.76495 9.2998 9.59995V4.79995H2.6998V9.59995Z" fill="currentColor"></path>
  //             </svg>

  //             <span className="text-xs font-medium dark:text-gray-400">Instant Booking</span>
  //           </div>
  //         </div>
  //       </a>
  //       <a href="#" className="flex mb-6">
  //         <img className="w-24 h-auto flex-shrink-0 rounded-lg mr-4" src="img/Image/event-landing-page/concert.jpg" alt="" />
  //         <div>
  //           <h6 className="text-gray-700 dark:text-gray-300 font-medium mb-2">
  //             Tomorrowland Concert
  //           </h6>
  //           <div className="bg-gray-100 dark:bg-gray-900 rounded-full px-3 py-1 inline-flex items-center">
  //             <svg className="w-3 h-auto text-green-500 mr-2" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  //               <path d="M4.6498 2.39995H7.3498V1.64995C7.3498 1.40151 7.55043 1.19995 7.7998 1.19995C8.04918 1.19995 8.2498 1.40151 8.2498 1.64995V2.39995H8.9998C9.66168 2.39995 10.1998 2.93714 10.1998 3.59995V9.59995C10.1998 10.2618 9.66168 10.8 8.9998 10.8H2.9998C2.33699 10.8 1.7998 10.2618 1.7998 9.59995V3.59995C1.7998 2.93714 2.33699 2.39995 2.9998 2.39995H3.7498V1.64995C3.7498 1.40151 3.95043 1.19995 4.1998 1.19995C4.44918 1.19995 4.6498 1.40151 4.6498 1.64995V2.39995ZM2.6998 9.59995C2.6998 9.76495 2.83405 9.89995 2.9998 9.89995H8.9998C9.1648 9.89995 9.2998 9.76495 9.2998 9.59995V4.79995H2.6998V9.59995Z" fill="currentColor"></path>
  //             </svg>

  //             <span className="text-xs font-medium dark:text-gray-400">Instant Booking</span>
  //           </div>
  //         </div>
  //       </a>
  //       <a href="#" className="flex">
  //         <img className="w-24 h-auto flex-shrink-0 rounded-lg mr-4" src="img/Image/landing-page-image/palace.jpg" alt="" />
  //         <div>
  //           <h6 className="text-gray-700 dark:text-gray-300 font-medium mb-2">
  //             Luxary Hotel For Rent
  //           </h6>
  //           <div className="bg-gray-100 dark:bg-gray-900 rounded-full px-3 py-1 inline-flex items-center">
  //             <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-auto text-yellow-500 mr-1" viewBox="0 0 20 20" fill="currentColor">
  //               <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
  //             </svg>

  //             <span className="text-xs font-medium dark:text-gray-400">Instant Booking</span>
  //           </div>
  //         </div>
  //       </a> */}
  //     </div>
  //   )
  // }

  return (
    <>
      <Header />

      <PageContentContainer>

        <>
          <section className="dark:bg-background mb-12">
            <div className="relative">
              <img className="w-full object-cover h-52" src="/img/cover-2.png" alt="" />
              <div className="absolute top-1/2 w-full -translate-y-1/2 text-center mb-4">
                <h1 className="capitalize text-4xl text-white font-semibold">Detox</h1>
                {/* <p className="text-white mt-2">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p> */}
              </div>
            </div>
          </section>

          <div className="container mx-auto px-5">
            <div className="grid grid-cols-3 gap-7 pb-10">

              <div className="lg:col-span-2 col-span-3">
                <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7">
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building.jpg" alt="" /> */}
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-2.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-3.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-4.jpg" alt="" /> */}
                  <div className="p-6 border-b border-gray-200 dark:border-gray-800">
                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      What Is Opioid Detox?
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">“Detoxification is a set of interventions aimed at managing acute intoxication and withdraw­al. It denotes a clearing of toxins from the body of the patient who is acutely intoxicated and/or dependent on substances of abuse. Detoxification seeks to minimize the physical harm caused by the abuse of substances.” – SAMHSA</p>

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      What to Expect in Detox
                    </h5>

                    <div className="grid lg:grid-cols-3 md:grid-cols-3 lg:gap-0 gap-7">
                      <div className="shadow-front-1 rounded-lg [&>div]:hover:shadow-front-3 group dark:bg-foreground p-2">
                        <img className="w-full h-40 object-cover rounded-t-lg" src="https://www.thehopehouse.com/wp-content/uploads/2021/01/Doctor-performing-an-assessment-on-a-client-in-opiod-detox.jpg.webp" alt="" />
                        <div className="p-2 dark:bg-foreground rounded-b-lg">

                          <button type="button" data-hc-control="content01" aria-expanded="false" className="py-2 w-full text-left accordion-border-bottom-none flex justify-between text-base lg:text-md font-medium text-gray-800 dark:text-gray-200" aria-controls="basicContent01">
                            <div className="text-md text-blue-500 font-semibold text-gray-700 dark:text-gray-100">Assessment</div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-800 dark:text-gray-400 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                            </svg>
                          </button>
                          <div id="basicContent01" className="border-b border-gray-200 dark:border-foreground dark:text-gray-200" data-hc-content="content01" aria-hidden="true" style={{ maxHeight: '0px', visibility: 'hidden', overflow: 'hidden' }}>
                            {/* <div className="pt-3 dark:text-gray-400 text-gray-500 leading-[24px]">
                          <b>[1]</b>. National Institute on Alcohol Abuse and Alcoholism. (2020). <a className='text-blue-500 font-bold hover:text-blue-500' href="https://www.niaaa.nih.gov/publications/brochures-and-fact-sheets/alcohol-facts-and-statistics">Alcohol Facts and Statistics</a>.
                        </div> */}

                            <div className="text-sm text-gray-500 mb-2 dark:text-gray-400 mt-2">Opioid detox begins with a medical assessment where clinicians meet with the client to better understand their unique problems. This meeting is typically related to drugs of abuse, family history, personal history, and more. Armed with the client’s unique needs, clinicians will develop a treatment program aimed at helping them detox from opioids.</div>
                          </div>

                        </div>
                      </div>

                      <div className="shadow-front-1 rounded-lg [&>div]:hover:shadow-front-3 group dark:bg-foreground p-2">
                        <img className="w-full h-40 object-cover rounded-t-lg" src="https://www.thehopehouse.com/wp-content/uploads/2021/02/patient-stabalizing-after-experiencing-opioid-withdrawal.jpg.webp" alt="" />
                        <div className="p-2 dark:bg-foreground rounded-b-lg">

                          <button type="button" data-hc-control="content02" aria-expanded="false" className="py-2 w-full text-left accordion-border-bottom-none flex justify-between text-base lg:text-md font-medium text-gray-800 dark:text-gray-200" aria-controls="basicContent02">
                            <div className="text-md text-blue-500 font-semibold text-gray-700 dark:text-gray-100">Stabilization/Monitoring</div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-800 dark:text-gray-400 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                            </svg>
                          </button>
                          <div id="basicContent02" className="border-b border-gray-200 dark:border-foreground dark:text-gray-200" data-hc-content="content02" aria-hidden="true" style={{ maxHeight: '0px', visibility: 'hidden', overflow: 'hidden' }}>
                            {/* <div className="pt-3 dark:text-gray-400 text-gray-500 leading-[24px]">
                          <b>[1]</b>. National Institute on Alcohol Abuse and Alcoholism. (2020). <a className='text-blue-500 font-bold hover:text-blue-500' href="https://www.niaaa.nih.gov/publications/brochures-and-fact-sheets/alcohol-facts-and-statistics">Alcohol Facts and Statistics</a>.
                        </div> */}

                            <div className="text-sm text-gray-500 mb-2 dark:text-gray-400 mt-2">After the evaluation is complete, clients are checked into detox where they are monitored by medical staff. As withdrawal symptoms arise, the detox team works to properly manage symptoms and keep clients safe.</div>
                          </div>

                        </div>
                      </div>

                      <div className="shadow-front-1 rounded-lg [&>div]:hover:shadow-front-3 group dark:bg-foreground p-2">
                        <img className="w-full h-40 object-cover rounded-t-lg" src="https://www.thehopehouse.com/wp-content/uploads/2021/01/group-conforting-each-other-in-opioid-detox-aftercare.jpg.webp" alt="" />
                        <div className="p-2 dark:bg-foreground rounded-b-lg">

                          <button type="button" data-hc-control="content03" aria-expanded="false" className="py-2 w-full text-left accordion-border-bottom-none flex justify-between text-base lg:text-md font-medium text-gray-800 dark:text-gray-200" aria-controls="basicContent03">
                            <div className="text-md text-blue-500 font-semibold text-gray-700 dark:text-gray-100">Aftercare</div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-800 dark:text-gray-400 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                            </svg>
                          </button>
                          <div id="basicContent03" className="border-b border-gray-200 dark:border-foreground dark:text-gray-200" data-hc-content="content03" aria-hidden="true" style={{ maxHeight: '0px', visibility: 'hidden', overflow: 'hidden' }}>
                            {/* <div className="pt-3 dark:text-gray-400 text-gray-500 leading-[24px]">
                          <b>[1]</b>. National Institute on Alcohol Abuse and Alcoholism. (2020). <a className='text-blue-500 font-bold hover:text-blue-500' href="https://www.niaaa.nih.gov/publications/brochures-and-fact-sheets/alcohol-facts-and-statistics">Alcohol Facts and Statistics</a>.
                        </div> */}

                            <div className="text-sm text-gray-500 mb-2 dark:text-gray-400 mt-2">Following the initial opioid detox program, clients have the best chance of long-term sobriety when enrolling in aftercare. For some that means taking time at an inpatient drug rehab, for others this means going to a weekly support group. It all depends on the specific client’s needs.</div>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="grid lg:grid-cols-2 md:grid-cols-2 lg:gap-0 gap-7 mt-8 mb-8 shadow-front-1 hover:shadow-front-4 rounded bg-slate-100">

                      <div className='grid-cols-1'>
                        <p className="flex h-full items-center text-gray-500 dark:text-gray-500 text-base dark:text-gray-300 p-4 pl-4">While proper aftercare is essential in all types of addiction, it is especially important for opioid users. Using an excessive amount or for a prolonged period of time will chemically alter the user’s mind and require significant commitment to overcome. For this reason, upwards of 35% of clients who undergo opioid detox end up transitioning to further treatment.</p>
                      </div>

                      <div className='grid-cols-1'>
                        {/* <img src="https://www.thehopehouse.com/wp-content/uploads/2020/11/Client-speaking-with-doctor-in-opioid-rehab.jpg" width="730" height="475" alt="" class="lazyloaded" data-ll-status="loaded" /> */}
                        <img src="https://www.thehopehouse.com/wp-content/uploads/2020/11/Client-speaking-with-doctor-in-opioid-rehab.jpg" alt="" class="w-full h-64 object-cover rounded-t-lg p-4" />
                      </div>

                    </div>

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      Where to Detox from Opioids
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Opioid detox is typically conducted in two types of settings based on the level of addiction and what someone can/is willing to commit to. Both options have pros and cons that you should weigh before making a decision — addiction specialists can help you decide the best approach.</p>

                    <div className="grid lg:grid-cols-2 md:grid-cols-2 mt-8 mb-8">

                      <div className='flex flex-col items-center grid-cols-1 text-center p-4 shadow-front-1 rounded-lg border border-gray-200 dark:border-gray-800 mr-1'>
                        <img src="https://www.thehopehouse.com/wp-content/uploads/2021/01/icon-depicting-inpatient-alcohol-rehab.png.webp" alt="" class="w-24 h-24 object-cover rounded-t-lg" />
                        <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                          Inpatient Detox
                        </h5>
                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5 pr-4">Inpatient detox can be done in a residential or hospital setting where clients check-in to the facility for a number of days and are released when their detox symptoms subside.</p>
                      </div>

                      <div className='flex flex-col items-center grid-cols-1 text-center p-4 shadow-front-1 rounded-lg border border-gray-200 dark:border-gray-800'>
                        <img src="https://www.thehopehouse.com/wp-content/uploads/2021/01/icon-depicting-outpatient-alcohol-rehab.png.webp" alt="" class="w-24 h-24 object-cover rounded-t-lg" />
                        <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                          Outpatient Detox
                        </h5>
                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5 pr-4">Outpatient detox is conducted in a doctor’s office where withdrawal symptoms are noted and treatment plans are adjusted accordingly. This is generally for less severe cases.</p>
                      </div>

                    </div>

                    {/* <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi voluptates accusantium molestias magnam beatae modi dolores corrupti aliquam saepe fugit, vero repudiandae praesentium eum iste quia numquam ipsum, reiciendis consectetur!
                      Illo velit distinctio magnam corrupti quasi vero reprehenderit. Ab assumenda enim ipsam quia velit facere, maxime cumque eveniet eligendi ea officiis! Optio totam sit animi hic dolorum obcaecati illum asperiores.
                      Impedit deserunt illo accusantium? Nobis ex modi cum hic sed distinctio possimus placeat quos nam aut. Laborum obcaecati quis, rem exercitationem eum aliquam non? At magnam placeat molestias cum tempora!
                      Rerum sint praesentium voluptas deserunt officia nobis, porro recusandae necessitatibus omnis vero beatae perspiciatis expedita aperiam reiciendis eaque nostrum quis consequuntur iste eum ad sequi corrupti! Explicabo nobis sit praesentium.</p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam vel ea sunt molestias facere quidem! Unde, repellendus dolores neque voluptatibus id, sunt impedit, nobis molestiae quisquam aut aliquid rem sed.
                      Doloremque, voluptatum voluptas iure facere, laudantium molestiae sint quam corporis illum ad amet recusandae vitae ea, accusamus dicta! Expedita accusantium quo reprehenderit! Repellendus quod eos tenetur nesciunt deleniti, cumque enim.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda facilis eum aliquam delectus deleniti corrupti nulla veniam cumque velit, veritatis eos quia dolorum enim ut quidem? Beatae officia aliquam voluptas!
                      Dolorum quia at maxime aliquam distinctio, molestias ratione nesciunt quod cupiditate laudantium aspernatur consequatur eaque vero id eius rerum libero dolore nobis modi. Dicta, illum architecto expedita explicabo laboriosam perferendis.
                      Debitis maiores pariatur enim voluptatum? Optio ipsa dignissimos, dolore, debitis adipisci veniam sed et labore, odio ab incidunt nihil officiis temporibus ut nemo aliquid ad eos rerum qui deserunt ipsam!</p> */}
                  </div>
                </div>
              </div>

              <div className="lg:col-span-1 col-span-3">
                <RehabSearchForm />

                {/* {featuredListing()} */}
                {<FeaturedListing items={5}/>}

              </div>

            </div>
          </div>

        </>

        <ScrollToTopButton />

        <Footer />
      </PageContentContainer>

    </>
  )
}

export default Detox