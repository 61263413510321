import React, {useState} from 'react'
import { useNavigate } from "react-router-dom";

import { us_states, us_city_state_list } from "../../../us_state_city_list";

const RehabSearchForm = () => {
    let navigate = useNavigate();

    const [selectedState, setSelectedState] = useState(-1);
    const [selectedCity, setSelectedCity] = useState(-1);

    const [stateCities, setStateCities] = useState([]);

    const handleStateSection = (e) => {
        if (e.target.value !== "-1") {
          setSelectedState(e.target.value);
          setStateCities(Object.fromEntries(Object.entries(us_city_state_list).filter(([key]) => key.includes(e.target.value)))[e.target.value]);
        }
      }
    
      const navigateToList = () => {
        // if (selectedState !== "-1" && selectedCity !== "-1")
        //   navigate(`/search/${selectedState.toLowerCase()}/${selectedCity.toLowerCase()}`);
        if (selectedState !== "-1" && selectedCity !== "-1") {
          //console.log(us_states.find(f=>f.name === selectedState).abbreviation);
          let temp = us_states.find(f => f.name === selectedState);
          if (temp !== undefined)
            navigate(`/search/${temp.abbreviation}-${selectedState.toLowerCase()}/${selectedCity.toLowerCase().replace(/\s+/g, '-')}`);
        }
      }

    return (
        <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7">
            <div className="border-b border-gray-200 dark:border-gray-800 p-6">
                <div className="text-lg font-medium text-gray-700 dark:text-gray-100">
                    Find Nearby Rehabilitation Center
                </div>
            </div>
            <div className="p-6">
                <div className="relative mb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-4 text-gray-400 absolute z-[1] top-1/2 -translate-y-1/2 ml-3" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
                    </svg>
                    <select name="states" className="bg-white w-full border border-gray-200 dark:bg-background dark:placeholder:text-gray-100 dark:text-gray-100 relative appearance-none rounded-lg pr-4 pl-9 py-[11px] focus:outline-blue-300 text-gray-400" onChange={(e) => handleStateSection(e)} aria-label="US States">
                        <option value="-1">State</option>
                        {
                            us_states.map(({ name }, index) => <option value={name} key={index}>{name}</option>)
                        }
                    </select>
                    <svg width="10" height="6" className="w-3 h-3 text-gray-400 absolute right-3 top-1/2 -translate-y-1/2 z-10" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74713 0.525274 9.25335 0.525274 9.56583 0.826613C9.87832 1.12795 9.87832 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor"></path>
                    </svg>
                </div>

                <div className="relative mb-9">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-4 text-gray-400 absolute z-[1] top-1/2 -translate-y-1/2 ml-3" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
                    </svg>
                    <select name="cities" className="bg-white w-full border border-gray-200 dark:bg-background dark:placeholder:text-gray-100 dark:text-gray-100 relative appearance-none rounded-lg pr-4 pl-9 py-[11px] focus:outline-blue-300 text-gray-400" onChange={(e) => setSelectedCity(e.target.value)} aria-label="US Cities">
                        <option value="-1">City</option>
                        {
                            stateCities.map((city, index) => <option value={city} key={index}>{city}</option>)
                        }
                    </select>
                    <svg width="10" height="6" className="w-3 h-3 text-gray-400 absolute right-3 top-1/2 -translate-y-1/2 z-10" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74713 0.525274 9.25335 0.525274 9.56583 0.826613C9.87832 1.12795 9.87832 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor"></path>
                    </svg>
                </div>

                <button type="button" className="bg-blue-500 text-white hover:bg-blue-600 rounded-lg w-full p-3.5 mb-4" onClick={() => navigateToList()}>
                    Search
                </button>
            </div>
        </div>
    )
}

export default RehabSearchForm