import React, { useEffect } from 'react'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PageContentContainer from '../../components/PageContentContainer';

import ScrollToTopButton from '../../components/ScrollToTopButton';

import { appendScript, removeScript } from "../../func";

// import businesses_list from "../../../business";
import FeaturedListing from '../../components/FeaturedListing';
import RehabSearchForm from '../../components/RehabSearchForm';

const OpioidAddiction = () => {

  useEffect(() => {
    //js/plugins-core/handy-collapse.js

    // const script = document.createElement("script");
    // script.src = "/js/plugins-core/handy-collapse.js";
    // script.async = true;
    // script.id = "handy-collapse";
    // document.body.appendChild(script);
    appendScript("/js/plugins-core/handy-collapse.js", "handy-collapse");
    setTimeout(() => {
      appendScript("/js/plugins-script/handy-collapse.js", "handy-collapse-function");
    }, 100)

    return () => {
      // var elem = document.getElementById("handy-collapse");
      // elem.remove();
      removeScript("handy-collapse");
      removeScript("handy-collapse-function");
    }

  }, []);

  // const featuredListing = () => {
  //   return (
  //     <div className="shadow-front-2 dark:bg-foreground p-6 rounded-lg mb-7">
  //       <div className="flex justify-between mb-6">
  //         <h4 className="text-base font-medium text-gray-700 dark:text-gray-100">
  //           Feature Listings
  //         </h4>
  //         {/* <a href="#" className="text-gray-500 dark:text-gray-400 text-sm">More</a> */}
  //       </div>

  //       {
  //         businesses_list.map(({ alias, name, image_url, is_closed }, index) => (
  //           index < 5 && <Link to={`/details/${alias}`} key={index} className="flex mb-6">
  //             <img className="w-24 h-auto flex-shrink-0 rounded-lg mr-4" src={image_url} alt={name} />
  //             <div>
  //               <h6 className="text-gray-700 dark:text-gray-300 font-medium mb-2">
  //                 {name}
  //               </h6>
  //               <div className="dark:bg-gray-900 rounded-full justify-center inline-flex items-center">
  //                 {
  //                   !is_closed ?
  //                     (<span className="bg-green-600 text-xs font-medium text-white px-3 py-1 rounded-full">Open</span>)
  //                     :
  //                     (<span className="bg-red-100 text-xs font-medium dark:text-gray-400 px-3 py-1 rounded-full">Close</span>)
  //                 }
  //               </div>
  //               {/* <div className="bg-gray-100 dark:bg-gray-900 rounded-full px-3 py-1 inline-flex items-center">
  //                 <svg className="w-3 h-auto text-green-500 mr-2" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  //                   <path d="M4.6498 2.39995H7.3498V1.64995C7.3498 1.40151 7.55043 1.19995 7.7998 1.19995C8.04918 1.19995 8.2498 1.40151 8.2498 1.64995V2.39995H8.9998C9.66168 2.39995 10.1998 2.93714 10.1998 3.59995V9.59995C10.1998 10.2618 9.66168 10.8 8.9998 10.8H2.9998C2.33699 10.8 1.7998 10.2618 1.7998 9.59995V3.59995C1.7998 2.93714 2.33699 2.39995 2.9998 2.39995H3.7498V1.64995C3.7498 1.40151 3.95043 1.19995 4.1998 1.19995C4.44918 1.19995 4.6498 1.40151 4.6498 1.64995V2.39995ZM2.6998 9.59995C2.6998 9.76495 2.83405 9.89995 2.9998 9.89995H8.9998C9.1648 9.89995 9.2998 9.76495 9.2998 9.59995V4.79995H2.6998V9.59995Z" fill="currentColor"></path>
  //                 </svg>
  //                 <span className="text-xs font-medium dark:text-gray-400">Instant Booking</span>
  //               </div> */}
  //             </div>
  //           </Link>
  //         ))
  //       }

  //       {/* <a href="#" className="flex mb-6">
  //         <img className="w-24 h-auto flex-shrink-0 rounded-lg mr-4" src="img/Image/landing-page-image/building-2.jpg" alt="" />
  //         <div>
  //           <h6 className="text-gray-700 dark:text-gray-300 font-medium mb-2">
  //             Duplex Apartment with Pool
  //           </h6>
  //           <div className="bg-gray-100 dark:bg-gray-900 rounded-full px-3 py-1 inline-flex items-center">
  //             <svg className="w-3 h-auto text-green-500 mr-2" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  //               <path d="M4.6498 2.39995H7.3498V1.64995C7.3498 1.40151 7.55043 1.19995 7.7998 1.19995C8.04918 1.19995 8.2498 1.40151 8.2498 1.64995V2.39995H8.9998C9.66168 2.39995 10.1998 2.93714 10.1998 3.59995V9.59995C10.1998 10.2618 9.66168 10.8 8.9998 10.8H2.9998C2.33699 10.8 1.7998 10.2618 1.7998 9.59995V3.59995C1.7998 2.93714 2.33699 2.39995 2.9998 2.39995H3.7498V1.64995C3.7498 1.40151 3.95043 1.19995 4.1998 1.19995C4.44918 1.19995 4.6498 1.40151 4.6498 1.64995V2.39995ZM2.6998 9.59995C2.6998 9.76495 2.83405 9.89995 2.9998 9.89995H8.9998C9.1648 9.89995 9.2998 9.76495 9.2998 9.59995V4.79995H2.6998V9.59995Z" fill="currentColor"></path>
  //             </svg>

  //             <span className="text-xs font-medium dark:text-gray-400">Instant Booking</span>
  //           </div>
  //         </div>
  //       </a>
  //       <a href="#" className="flex mb-6">
  //         <img className="w-24 h-auto flex-shrink-0 rounded-lg mr-4" src="img/Image/event-landing-page/concert.jpg" alt="" />
  //         <div>
  //           <h6 className="text-gray-700 dark:text-gray-300 font-medium mb-2">
  //             Tomorrowland Concert
  //           </h6>
  //           <div className="bg-gray-100 dark:bg-gray-900 rounded-full px-3 py-1 inline-flex items-center">
  //             <svg className="w-3 h-auto text-green-500 mr-2" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  //               <path d="M4.6498 2.39995H7.3498V1.64995C7.3498 1.40151 7.55043 1.19995 7.7998 1.19995C8.04918 1.19995 8.2498 1.40151 8.2498 1.64995V2.39995H8.9998C9.66168 2.39995 10.1998 2.93714 10.1998 3.59995V9.59995C10.1998 10.2618 9.66168 10.8 8.9998 10.8H2.9998C2.33699 10.8 1.7998 10.2618 1.7998 9.59995V3.59995C1.7998 2.93714 2.33699 2.39995 2.9998 2.39995H3.7498V1.64995C3.7498 1.40151 3.95043 1.19995 4.1998 1.19995C4.44918 1.19995 4.6498 1.40151 4.6498 1.64995V2.39995ZM2.6998 9.59995C2.6998 9.76495 2.83405 9.89995 2.9998 9.89995H8.9998C9.1648 9.89995 9.2998 9.76495 9.2998 9.59995V4.79995H2.6998V9.59995Z" fill="currentColor"></path>
  //             </svg>

  //             <span className="text-xs font-medium dark:text-gray-400">Instant Booking</span>
  //           </div>
  //         </div>
  //       </a>
  //       <a href="#" className="flex">
  //         <img className="w-24 h-auto flex-shrink-0 rounded-lg mr-4" src="img/Image/landing-page-image/palace.jpg" alt="" />
  //         <div>
  //           <h6 className="text-gray-700 dark:text-gray-300 font-medium mb-2">
  //             Luxary Hotel For Rent
  //           </h6>
  //           <div className="bg-gray-100 dark:bg-gray-900 rounded-full px-3 py-1 inline-flex items-center">
  //             <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-auto text-yellow-500 mr-1" viewBox="0 0 20 20" fill="currentColor">
  //               <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
  //             </svg>

  //             <span className="text-xs font-medium dark:text-gray-400">Instant Booking</span>
  //           </div>
  //         </div>
  //       </a> */}
  //     </div>
  //   )
  // }

  return (
    <>
      <Header />

      <PageContentContainer>

        <>
          <section className="dark:bg-background mb-12">
            <div className="relative">
              <img className="w-full object-cover h-52" src="/img/cover-2.png" alt="" />
              <div className="absolute top-1/2 w-full -translate-y-1/2 text-center mb-4">
                <h1 className="capitalize text-4xl text-white font-semibold">What is Alcohol Abuse & Alcoholism?</h1>
                <p className="text-white mt-2">Discover the signs, symptoms and causes of alcoholism and how treatment can help you live a life of recovery and freedom from alcohol.</p>
              </div>
            </div>
          </section>

          <div className="container mx-auto px-5">
            <div className="grid grid-cols-3 gap-7 pb-10">

              <div className="lg:col-span-2 col-span-3">
                <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7">
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building.jpg" alt="" /> */}
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-2.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-3.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-4.jpg" alt="" /> */}
                  <div className="p-6 border-b border-gray-200 dark:border-gray-800">
                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100">
                      What is Alcohol Abuse & Alcoholism?
                    </h5>
                    <h5 className="text-gray-500 lg:text-md text-md font-semibold dark:text-gray-100 mb-6">
                      Discover the signs, symptoms and causes of alcoholism and how treatment can help you live a life of recovery and freedom from alcohol.
                    </h5>

                    {/* <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequuntur quo perspiciatis deleniti debitis aspernatur eos voluptatibus veritatis, labore excepturi similique nisi quod repudiandae aliquid laborum fuga quos pariatur delectus quae.</p> */}

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Alcohol is one of the most widely-used substances in the world. Drinking is commonly normalized, and embraced by many as an accepted form of social engagement. However, regularly drinking even moderate amounts of alcohol may be associated with adverse effects and may put people at risk of certain short- and long-term health consequences. Across the U.S., nearly 70% of people ages 18 or older reported that they drank alcohol in the past year while more than half reported that they drank in the past month according to the 2019 National Survey on Drug Use and Health (NSDUH).1
                    </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Because it is relatively easy to get and socially acceptable to consume (for those of legal drinking age), it can be a challenge to have people evaluate their own drinking behavior and know whether the amount of and frequency with which they drink places them at-risk for alcoholism. To help individuals better quantify their drinking patterns, the U.S. Department of Health and Human Services and U.S. Department of Agriculture’s jointly-issued 2015-2020 Dietary Guidelines for Americans defines “moderate” drinking as up to 1 drink per day for women and up to 2 drinks per day for men.2
                    </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Additional metrics may also be helpful for gauging potentially problematic drinking. For instance, the National Institute on Alcohol Abuse and Alcoholism (NIAAA) defines binge drinking as a pattern of consumption that brings a person’s blood alcohol concentration (BAC) to 0.08% or higher.2 Typically, this is around 5 or more drinks for males and 4 or more drinks for women in about 2 hours.2
                    </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      The NIAAA describes heavy use as more than 4 drinks for men or more than 3 drinks for women on any given day.2 According to the NSDUH, in the past month of the survey, 25.8% of people ages 18 or older reported that they engaged in binge drinking while 6.3% reported engaging in heavy alcohol use. Further, an estimated 14.1 million Americans aged 18 and over were reported to have an alcohol use disorder (AUD).1
                    </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Although not everyone who drinks alcohol will become addicted or develop an alcohol use disorder, understanding the risks of heavy drinking and binge drinking may help some slow the progression of their problematic drinking behaviors. Learn more about alcoholism below and how professional addiction treatment can help those struggling with alcohol abuse work toward recovery.
                    </p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      What is Alcoholism?
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Alcoholism, or alcohol use disorder, is a chronic condition characterized by an inability to slow, stop, or otherwise control the use of alcohol despite the negative consequences of continued use.1 AUD is diagnosed based on an individual meeting certain criteria outlined by the Diagnostic and Statistical Manual of Mental Disorders (DSM-5).
                    </p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      Signs and Symptoms of Alcoholism
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Individuals who meet at least 2 of 11 criteria within the same 12-month period may be diagnosed with an alcohol use disorder. People who meet these diagnostic criteria may have experienced or exhibited the following types of signs, symptoms, and behaviors within the last year:3
                    </p>

                    <ul className=" mb-5">
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Had times when you drank more (or longer) than planned.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Wanting to stop or cut down on drinking but weren’t able to do so.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Spending a considerable amount of time getting alcohol, drinking, or getting over the effects of drinking.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Wanting an alcoholic beverage so much so that you couldn’t think of anything else.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Drinking (or being sick from drinking) hindered your ability to meet home, school, or job obligations.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Continuing to drink, despite drinking causing problems with family or friends.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Spending less time or giving up activities that are important or interesting to you or that gave you pleasure for the purpose of consuming alcohol.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          During or after drinking, getting into situations where you increased your risk for getting hurt (driving a car, for example).
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Continuing to drink, despite it making you feel anxious, depressed, or contributing to a separate health problem, or after having experienced a memory blackout.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Needing more alcohol to achieve the desired effect or the regular amount that you drink, no longer has the same effect.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Experiencing withdrawal symptoms when the effects of alcohol wear off, like restlessness, sweating, shakiness, sleeping, seizure, racing heart; or sensed things that were not present.
                        </div>
                      </li>
                    </ul>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      Effects of Alcohol Use
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Even if an individual doesn’t abuse alcohol regularly, they can still experience several potentially negative physical and psychological effects. There are a number of different short and long-term effects of alcohol consumption which can affect the body in many different ways.
                    </p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      Short-Term Effects of Alcohol Use
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      In the short-term, the effects of alcohol can range from mild, such as slight euphoria, to more severe symptoms such as passing out or vomiting.5 Depending on how much drinking has taken place, other short-term effects of alcohol can include: 5,6
                    </p>

                    <ul className=" mb-5">
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Trouble concentrating.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Loss of coordination; clumsiness.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Dulled sensory perception; increased reaction time.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Decreased visual acuity; visual disturbances including double vision.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Lowered inhibitions.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Loss of critical judgement.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Fluctuating moods.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Reduced core body temperature.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Raised blood pressure.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Slowed breathing progressing to respiratory arrest.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Loss of consciousness; coma; death.
                        </div>
                      </li>
                    </ul>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Chronic physical and mental health issues can also be caused by drinking too much over time. Drinking large amounts of alcohol for many years can damage the brain and nervous system, heart, liver and pancreas.7
                    </p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      Long-Term Effects of Alcohol Use
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Heavy drinking can contribute to or cause issues such as cardiovascular disease, multiple types of cancer, and/or liver damage.1,6,8 Other long-term health risks associated with alcohol misuse include:7
                    </p>

                    <ul className=" mb-5">
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          High blood pressure.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Increased blood cholesterol levels.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Stroke.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Pancreatitis.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Depression.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Dementia.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Sexual problems (e.g., impotence, premature ejaculation).
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Infertility.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Heart attack.
                        </div>
                      </li>
                    </ul>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Long-term alcohol misuse can also make individuals more vulnerable to serious infections by weakening their immune system.7 It can also weaken a person’s bones, putting them at risk of fracturing or breaking them.
                    </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Regular misuse of alcohol is also associated with the development of physiological dependence and an unpleasant (and potentially dangerous) acute alcohol withdrawal syndrome when drinking slows or stops.
                    </p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      What is Alcohol Withdrawal?
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      A person who has been abusing alcohol regularly may develop significant alcohol dependence, which places them at risk of experiencing several symptoms of alcohol withdrawal if they suddenly quit drinking.9 Typically, symptoms occur within 8 hours after the last drink and peak by 24 to 72 hours.9 In some cases, certain symptoms may not begin until days after the last drink and go on for weeks.
                    </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Alcohol detox and medical withdrawal management is often the first step in the rehabilitation process for people with significant alcohol dependence. The acute alcohol withdrawal syndrome may consist of symptoms that range from mild to dangerous, and left unmanaged, heavy alcohol users may experience uncomfortable and potentially severe symptoms.
                    </p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      Symptoms of Alcohol Withdrawal
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Exactly which symptoms are experienced may vary with each individual. Some of the more common alcohol withdrawal symptoms include :9
                    </p>

                    <ul className=" mb-5">
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Depression.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Anxiety.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Irritability.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Fatigue.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Nightmares.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Jumpiness or shakiness.
                        </div>
                      </li>
                    </ul>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Other symptoms may include:9
                    </p>

                    <ul className=" mb-5">
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Headache.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Insomnia.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Increased body temperature.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Increased sweating.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Nausea and vomiting.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Loss of appetite.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Rapid heart rate.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Hallucination.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Agitation.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Profound confusion.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Generalized tremors.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Seizures.
                        </div>
                      </li>
                    </ul>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Symptoms can vary person-to-person in terms of severity. A supervised, medical detox can help people avoid unnecessary discomfort or life-threatening complications from withdrawal. Medical detox is a challenging but necessary element of early alcohol recovery. The supervision and care of a professional detox program helps keep the individual as safe and comfortable as possible as their body clears itself of alcohol’s influence.9
                    </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Each detox facility may have its own particular set of plans and protocols. However, in general, a medical detox plan may include medications, close patient monitoring, and caring support from the treatment team at the core of treatment plan at this early stage of recovery.
                    </p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      Treatment Options for Alcoholism
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Following detox completion and successful withdrawal management, many will continue with additional alcohol addiction treatment to continue working toward recovery. Several individual factors may help to determine the type of treatment that will be most suitable for you such as:
                    </p>

                    <ul className=" mb-5">
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Current alcohol use and corresponding level of physical alcohol dependence.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Any previous attempts to quit.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Any additional substance use.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Any co-occurring medical and/or mental health conditions.
                        </div>
                      </li>
                    </ul>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Though treatment settings may vary, all incorporate a variety of recovery programming such as behavioral therapies, individual and group counseling, medications to manage AUD, coping skills education, and relapse prevention classes. Patients may experience rehab in a variety of settings including short- and long-term residential treatment and outpatient programming.10
                    </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Inpatient and residential alcohol treatment centers provide 24-hour care, giving patients access to on-call medical and psychiatric services during their stay.10 Inpatient/residential alcohol treatment centers typically offer 30- to 90-day programs in order to allow patients to focus solely on their recovery without the distractions of their everyday lives.
                    </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Outpatient treatment can vary in structure, level of care, and time commitment—from partial hospitalization programs (PHP) to intensive outpatient (IOP) to scheduled office visits with a therapist or counselor. These types of programs may be most suitable for individuals with strong social support systems and those not able to miss work for treatment.10
                    </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Regardless of the exact treatment setting, individuals seeking help for alcohol use disorders can expect to be exposed to a variety of behavioral therapies as they work toward recovery. These types of evidence-based therapies provide an opportunity for individuals to modify behaviors that can contribute to excessive drinking and learn new coping methods to avoid relapse.11
                    </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Types of behavioral treatments for alcoholism include cognitive behavioral therapy, motivational enhancement therapy, dialectal behavioral therapy, contingency management, marital and family counseling, and 12-step facilitation.11 Along with these therapies, patients may be administered certain treatment medications as part of a long-term alcohol recovery strategy.11
                    </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      There are three medications that have been approved by the U.S. Food and Drug Administration (FDA) to be used to treat alcohol dependence.11 These medications include acamprosate, disulfiram, and naltrexone.11 Though they all work differently, each has been shown to decrease continued drinking behavior. When taken regularly, these medications can be a valuable component of managing the chronic issue of alcoholism, similar to how daily medications may be used to manage other chronic health conditions such as asthma or diabetes.11
                    </p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      Determining the Right Treatment
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      If you or someone you care about is ready to seek treatment for alcoholism, there are a few questions you may want to consider when researching alcohol rehab facilities:11
                    </p>

                    <ul className=" mb-5">
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>How does the program work with individuals who have relapsed?</b> Because relapse is often part of the recovery process, it’s important to know what the protocol is in these situations so you can know what to expect.</div>
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>Is treatment tailored to fit each individual based on their unique needs?</b> It’s important to know that your personal needs within this process are met in order to increase your chances for success.</div>
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>What kind of treatment does the program offer?</b> This will help you to determine if the facility only uses one approach to treatment, if they use a variety of current methods, if they offer medications with their treatment, and if they address co-occurring mental health disorders.</div>
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>Is treatment success measured?</b> Knowing this information may help you determine which program resonates with you the most.</div>
                        </div>
                      </li>
                    </ul>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      As operator of Alcoholrehab.com, American Addiction Centers (AAC) offers a toll-free hotline so that you may speak with our admissions navigators about your treatment options any time of day. AAC operates a nationwide network of treatment centers and is dedicated to making recovery accessible to all persons in need.
                    </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Our approach to alcoholism treatment is rooted in evidence-based therapies and tailored to fit each individual’s unique needs. With locations across the country, we’re able to provide a number of unique settings as your backdrop for recovery. We are also equipped to treat co-occurring disorders throughout our network of facilities via an integrated approach to treatment.
                    </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      To improve your overall experience, patients can also participate in a number of alternative therapies such as yoga, equine, music and art therapies while at one of our facilities. Depending on location, these programs are open to all patients and are overseen by licensed therapists.
                    </p>

                    <div>
                      <button type="button" data-hc-control="content01" aria-expanded="false" className="p-5 w-full text-left border-b border-t border-gray-200 dark:border-foreground accordion-border-bottom-none flex justify-between text-base lg:text-lg font-medium text-gray-800 dark:text-gray-200" aria-controls="basicContent01">
                        Sources
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-800 dark:text-gray-400 ml-5 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                        </svg>
                      </button>
                      <div id="basicContent01" className="px-5 border-b border-gray-200 dark:border-foreground dark:text-gray-200" data-hc-content="content01" aria-hidden="true" style={{ maxHeight: '0px', visibility: 'hidden', overflow: 'hidden' }}>
                        <div className="pt-3 dark:text-gray-300 text-gray-500 leading-[24px]">
                          <b>[1]</b>. National Institute on Alcohol Abuse and Alcoholism. (2020). <a className='text-blue-500 font-bold hover:text-blue-500' href="https://www.niaaa.nih.gov/publications/brochures-and-fact-sheets/alcohol-facts-and-statistics">Alcohol Facts and Statistics</a>.
                        </div>
                        <div className="pt-3 dark:text-gray-300 text-gray-500 leading-[24px]">
                          <b>[2]</b>. National Institute on Alcohol Abuse and Alcoholism. (n.d.). <a className='text-blue-500 font-bold hover:text-blue-500' href="">Drinking Levels Defined</a>.
                        </div>
                        <div className="pt-3 dark:text-gray-300 text-gray-500 leading-[24px]">
                          <b>[3]</b>. American Psychiatric Association. (2013). Alcohol Use Disorder. Diagnostic and Statistical Manual of Mental Disorders, Vol 5; 490-491.
                        </div>
                        <div className="pt-3 dark:text-gray-300 text-gray-500 leading-[24px]">
                          <b>[4]</b>. National Institute on Alcohol Abuse and Alcoholism. (2007). <a className='text-blue-500 font-bold hover:text-blue-500' href="https://pubs.niaaa.nih.gov/publications/aa72/aa72.htm#:~:text=Most%20of%20the%20ethanol%20in,CHO)%2C%20a%20known%20carcinogen.">Alcohol Metabolism: An Update</a>. Alcohol Alert, No. 72.
                        </div>
                        <div className="pt-3 dark:text-gray-300 text-gray-500 leading-[24px]">
                          <b>[5]</b>. Herron, J.H, & Brennan, T.K. (2015). The ASAM Essentials of Addiction Medicine, Second Edition.
                        </div>
                        <div className="pt-3 dark:text-gray-300 text-gray-500 leading-[24px]">
                          <b>[6]</b>. Piano M. R. (2017). <a className='text-blue-500 font-bold hover:text-blue-500' href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5513687/">Alcohol’s Effects on the Cardiovascular System</a>. Alcohol Research: current reviews, 38(2), 219–241.
                        </div>
                        <div className="pt-3 dark:text-gray-300 text-gray-500 leading-[24px]">
                          <b>[7]</b>. National Health Services. (2018). <a className='text-blue-500 font-bold hover:text-blue-500' href="https://www.nhs.uk/conditions/alcohol-misuse/risks/">Alcohol Misuse</a>.
                        </div>
                        <div className="pt-3 dark:text-gray-300 text-gray-500 leading-[24px]">
                          <b>[8]</b>. National Institute on Alcohol Abuse and Alcoholism. (n.d.). <a className='text-blue-500 font-bold hover:text-blue-500' href="https://www.niaaa.nih.gov/alcohols-effects-health/alcohols-effects-body">Alcohol’s Effects on the Body</a>.
                        </div>
                        <div className="pt-3 dark:text-gray-300 text-gray-500 leading-[24px]">
                          <b>[9]</b>. National Library of Medicine, MedlinePlus. (2020). <a className='text-blue-500 font-bold hover:text-blue-500' href="https://medlineplus.gov/ency/article/000764.htm">Alcohol Withdrawal</a>.
                        </div>
                        <div className="pt-3 dark:text-gray-300 text-gray-500 leading-[24px]">
                          <b>[10]</b>. National Institute on Drug Abuse. (2020). <a href="https://www.drugabuse.gov/publications/principles-drug-addiction-treatment-research-based-guide-third-edition/drug-addiction-treatment-in-united-states/types-treatment-programs">Types of Treatment Programs</a>.
                        </div>
                        <div className="pt-3 dark:text-gray-300 text-gray-500 leading-[24px]">
                          <b>[11]</b>. National Institute on Alcohol Abuse and Alcoholism. (2014). <a className='text-blue-500 font-bold hover:text-blue-500' href="https://www.niaaa.nih.gov/publications/brochures-and-fact-sheets/treatment-alcohol-problems-finding-and-getting-help#4">Treatment for Alcohol Problems: Finding and Getting Help</a>.
                        </div>
                      </div>
                    </div>

                    {/* <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam vel ea sunt molestias facere quidem! Unde, repellendus dolores neque voluptatibus id, sunt impedit, nobis molestiae quisquam aut aliquid rem sed.
                      Doloremque, voluptatum voluptas iure facere, laudantium molestiae sint quam corporis illum ad amet recusandae vitae ea, accusamus dicta! Expedita accusantium quo reprehenderit! Repellendus quod eos tenetur nesciunt deleniti, cumque enim.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda facilis eum aliquam delectus deleniti corrupti nulla veniam cumque velit, veritatis eos quia dolorum enim ut quidem? Beatae officia aliquam voluptas!
                      Dolorum quia at maxime aliquam distinctio, molestias ratione nesciunt quod cupiditate laudantium aspernatur consequatur eaque vero id eius rerum libero dolore nobis modi. Dicta, illum architecto expedita explicabo laboriosam perferendis.
                      Debitis maiores pariatur enim voluptatum? Optio ipsa dignissimos, dolore, debitis adipisci veniam sed et labore, odio ab incidunt nihil officiis temporibus ut nemo aliquid ad eos rerum qui deserunt ipsam!</p> */}
                  </div>
                </div>
              </div>

              <div className="lg:col-span-1 col-span-3">
                <RehabSearchForm />

                {/* {featuredListing()} */}
                {<FeaturedListing items={5}/>}

              </div>

            </div>
          </div>

        </>

        <ScrollToTopButton />

        <Footer />
      </PageContentContainer>

    </>
  )
}

export default OpioidAddiction