import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getCookie, eraseCookie, base64_decode, checkRole } from '../../func';

const AdminSideBar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [user_id, setUserID] = useState(0);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [page, setPage] = useState('');

    useEffect(() => {
        //console.log("location",location);
        //console.log("location.pathname", location.pathname);
        let x = location.pathname.split('/');
        //console.log(x[x.length-1]);
        setPage(x[x.length - 1]);

        const token = getCookie("token");

        if (token === null) {
            // navigate(`/admin/login`, { replace: true });
            navigate(`/login`, { replace: true });
        }
        else {
            const parts = base64_decode(token).split("|");
            setUserID(parts[0]);
            setName(parts[1]);
            setEmail(parts[2]);
            setRole(parts[3]);
        }
    }, []);

    const logout = () => {
        setTimeout(() => {
            localStorage.removeItem("isLoggedIn");
            eraseCookie("token");
            // navigate(`/admin/login`, { replace: true });
            navigate(`/login`, { replace: true });
        }, 500);
    }

    return (
        <>
            <div id="demo" className="dashboard-sidebar ps z-50 w-64 transition-all bg-gray-800 dark:bg-foreground text-gray-100 fixed lg:left-0 top-0 -left-64 h-full flex flex-col">
                <Link to={(role !== "" && role === "admin") ? "/admin/dashboard" : "/admin/add_listing"} className="px-4 p-8 flex items-center">
                    <img className="w-7 h-7 mr-2" src="/img/OR-logo.webp" alt="" />
                    <p className="text-lg font-semibold">Opioid Rehab</p>
                </Link>
                <div className="px-2">
                    {/* className="p-6 min-h-[85%]" */}
                    <ul className="mb-4">
                        {/* <p className="uppercase text-gray-500 mb-3 text-sm ml-4">pages</p> */}
                        {(role !== "" && role === "admin") && (<li className={`text-white flex items-center mb-2 ${page === 'dashboard' ? 'active' : ''}`}>
                            <Link to={`/admin/dashboard`} className="flex items-center hover:bg-gray-700 hover:text-blue-500 flex-1 py-2 px-3 rounded-lg">
                                <svg className="mr-2 w-5 h-5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.14285 2C8.08928 2 8.85714 2.74618 8.85714 3.66667V9.22222C8.85714 10.1424 8.08928 10.8889 7.14285 10.8889H3.71428C2.7675 10.8889 2 10.1424 2 9.22222V3.66667C2 2.74618 2.7675 2 3.71428 2H7.14285ZM7.14285 13.1111C8.08928 13.1111 8.85714 13.8576 8.85714 14.7778V15.8889C8.85714 16.809 8.08928 17.5556 7.14285 17.5556H3.71428C2.7675 17.5556 2 16.809 2 15.8889V14.7778C2 13.8576 2.7675 13.1111 3.71428 13.1111H7.14285Z" fill="currentColor" />
                                    <path d="M16.2857 2C17.2321 2 18 2.74618 18 3.66667V4.77778C18 5.69792 17.2321 6.44444 16.2857 6.44444H12.8571C11.9107 6.44444 11.1429 5.69792 11.1429 4.77778V3.66667C11.1429 2.74618 11.9107 2 12.8571 2H16.2857ZM16.2857 8.66667C17.2321 8.66667 18 9.41319 18 10.3333V15.8889C18 16.809 17.2321 17.5556 16.2857 17.5556H12.8571C11.9107 17.5556 11.1429 16.809 11.1429 15.8889V10.3333C11.1429 9.41319 11.9107 8.66667 12.8571 8.66667H16.2857Z" fill="#B2DDFF" />
                                </svg>
                                Dashboard
                            </Link>
                        </li>)}
                        {/* <li className="flex items-center  text-white mb-2">
                        <a href="dashboard-all-listing.html" className="flex items-center flex-1 py-2 px-3 rounded-lg hover:bg-gray-700 hover:text-blue-500">
                            <svg className="mr-2 w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">                                
                                <path d="M152.1 38.16C161.9 47.03 162.7 62.2 153.8 72.06L81.84 152.1C77.43 156.9 71.21 159.8 64.63 159.1C58.05 160.2 51.69 157.6 47.03 152.1L7.029 112.1C-2.343 103.6-2.343 88.4 7.029 79.03C16.4 69.66 31.6 69.66 40.97 79.03L63.08 101.1L118.2 39.94C127 30.09 142.2 29.29 152.1 38.16V38.16zM152.1 198.2C161.9 207 162.7 222.2 153.8 232.1L81.84 312.1C77.43 316.9 71.21 319.8 64.63 319.1C58.05 320.2 51.69 317.6 47.03 312.1L7.029 272.1C-2.343 263.6-2.343 248.4 7.029 239C16.4 229.7 31.6 229.7 40.97 239L63.08 261.1L118.2 199.9C127 190.1 142.2 189.3 152.1 198.2V198.2zM224 96C224 78.33 238.3 64 256 64H480C497.7 64 512 78.33 512 96C512 113.7 497.7 128 480 128H256C238.3 128 224 113.7 224 96V96zM224 256C224 238.3 238.3 224 256 224H480C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H256C238.3 288 224 273.7 224 256zM160 416C160 398.3 174.3 384 192 384H480C497.7 384 512 398.3 512 416C512 433.7 497.7 448 480 448H192C174.3 448 160 433.7 160 416zM0 416C0 389.5 21.49 368 48 368C74.51 368 96 389.5 96 416C96 442.5 74.51 464 48 464C21.49 464 0 442.5 0 416z" fill="currentColor" />
                            </svg>
                            All Listing
                        </a>
                    </li> */}
                        {(role !== "" && (role === "admin" || role === "author")) && (<li className={`flex items-center mb-2 ${page === 'add_post' ? 'active' : ''}`}>
                            <Link to={`/admin/add_post`} className="flex items-center flex-1 py-2 px-3 rounded-lg hover:bg-gray-700 hover:text-blue-500">
                                <svg className="mr-2 w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    {/*! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                                    <path d="M0 64C0 28.65 28.65 0 64 0H224V128C224 145.7 238.3 160 256 160H384V299.6L289.3 394.3C281.1 402.5 275.3 412.8 272.5 424.1L257.4 484.2C255.1 493.6 255.7 503.2 258.8 512H64C28.65 512 0 483.3 0 448V64zM256 128V0L384 128H256zM564.1 250.1C579.8 265.7 579.8 291 564.1 306.7L534.7 336.1L463.8 265.1L493.2 235.7C508.8 220.1 534.1 220.1 549.8 235.7L564.1 250.1zM311.9 416.1L441.1 287.8L512.1 358.7L382.9 487.9C378.8 492 373.6 494.9 368 496.3L307.9 511.4C302.4 512.7 296.7 511.1 292.7 507.2C288.7 503.2 287.1 497.4 288.5 491.1L303.5 431.8C304.9 426.2 307.8 421.1 311.9 416.1V416.1z" fill="currentColor" />
                                </svg>Add Post
                            </Link>
                        </li>)}
                        {(role !== "" && role !== "author") && (<li className={`flex items-center mb-2 ${page === 'add_listing' ? 'active' : ''}`}>
                            <Link to={`/admin/add_listing`} className="flex items-center flex-1 py-2 px-3 rounded-lg hover:bg-gray-700 hover:text-blue-500">
                                <svg className="mr-2 w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                    {/*! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                                    <path d="M336 64h-53.88C268.9 26.8 233.7 0 192 0S115.1 26.8 101.9 64H48C21.5 64 0 85.48 0 112v352C0 490.5 21.5 512 48 512h288c26.5 0 48-21.48 48-48v-352C384 85.48 362.5 64 336 64zM192 64c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S160 113.7 160 96C160 78.33 174.3 64 192 64zM96 392c-13.25 0-24-10.75-24-24S82.75 344 96 344s24 10.75 24 24S109.3 392 96 392zM112 288C107.9 288 103.8 286.4 100.7 283.3l-32-32c-6.25-6.25-6.25-16.38 0-22.62s16.38-6.25 22.62 0L112 249.4l52.69-52.69c6.25-6.25 16.38-6.25 22.62 0s6.25 16.38 0 22.62l-64 64C120.2 286.4 116.1 288 112 288zM304 384h-128C167.2 384 160 376.8 160 368C160 359.2 167.2 352 176 352h128c8.801 0 16 7.199 16 16C320 376.8 312.8 384 304 384zM304 288h-96C199.2 288 192 280.8 192 272C192 263.2 199.2 256 208 256h96C312.8 256 320 263.2 320 272C320 280.8 312.8 288 304 288z" fill="currentColor" />
                                </svg>
                                Add Listing
                            </Link>
                        </li>)}
                        {(role !== "" && role === "admin") && (<li className={`flex items-center mb-2 ${page === 'users' ? 'active' : ''}`}>
                            <Link to={`/admin/users`} className="flex items-center flex-1 py-2 px-3 rounded-lg hover:bg-gray-700 hover:text-blue-500">
                                <svg className="mr-2 w-5 h-5" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.8296 11.6H8.57036C5.49461 11.6 3 13.988 3 16.9331C3 17.5221 3.49886 18 4.11407 18H16.2866C16.9018 18.0009 17.4 17.5239 17.4 16.9331C17.4 13.988 14.9057 11.6 11.8296 11.6Z" fill="currentColor"></path>
                                    <path d="M14.2 6C14.2 8.20906 12.4094 10 10.2 10C7.99064 10 6.20001 8.20937 6.20001 6C6.20001 3.79063 7.99095 2 10.2 2C12.4094 2 14.2 3.79094 14.2 6Z" fill="#D0D5DD"></path>
                                </svg> Users
                            </Link>
                        </li>)}
                    </ul>
                    {(role !== "" && role !== "author") && (<ul className="mb-4">
                        <p className="uppercase text-gray-500 mb-3 text-sm ml-4">listing</p>
                        {(role !== "" && role === "admin") && (<li className={`flex items-center text-white mb-2 ${page === 'all_listings' ? 'active' : ''}`}>
                            <Link to={`/admin/all_listings`} className="flex items-center flex-1 py-2 px-3 rounded-lg hover:bg-gray-700 hover:text-blue-500">
                                <svg className="mr-2 w-5 h-5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.26598 2.16147C9.73163 1.94618 10.2692 1.94618 10.7348 2.16147L17.5664 5.31891C17.832 5.44079 18.0008 5.70642 18.0008 5.97206C18.0008 6.29083 17.832 6.55647 17.5664 6.68147L10.7348 9.83788C10.2692 10.0535 9.73163 10.0535 9.26598 9.83788L2.43533 6.68147C2.16995 6.55647 2 6.29083 2 5.97206C2 5.70642 2.16991 5.44079 2.43533 5.31891L9.26598 2.16147ZM17.5664 13.3193C17.832 13.4412 18.0008 13.7068 18.0008 13.9725C18.0008 14.2912 17.832 14.5569 17.5664 14.6819L10.7348 17.8383C10.2692 18.0539 9.73163 18.0539 9.26598 17.8383L2.43533 14.6819C2.16995 14.5569 2 14.2912 2 13.9725C2 13.7068 2.16991 13.4412 2.43533 13.3193L4.09792 12.5505L8.84721 14.7444C9.5785 15.085 10.4223 15.085 11.1536 14.7444L15.9038 12.5505L17.5664 13.3193Z" fill="currentColor" />
                                    <path d="M11.1536 10.7442L15.9038 8.55032L17.5664 9.31911C17.832 9.44099 18.0008 9.70663 18.0008 9.97227C18.0008 10.291 17.832 10.5567 17.5664 10.6817L10.7348 13.8381C10.2692 14.0537 9.73163 14.0537 9.26598 13.8381L2.43533 10.6817C2.16995 10.5567 2 10.291 2 9.97227C2 9.70663 2.16991 9.44099 2.43533 9.31911L4.09792 8.55032L8.84721 10.7442C9.5785 11.0848 10.4223 11.0848 11.1536 10.7442Z" fill="#D0D5DD" />
                                </svg>
                                All Listing
                            </Link>
                        </li>)}
                        <li className={`flex items-center text-white mb-2 ${page === 'my_listing' ? 'active' : ''}`}>
                            <Link to={`/admin/my_listing`} className="flex items-center flex-1 py-2 px-3 rounded-lg hover:bg-gray-700 hover:text-blue-500">
                                <svg className="mr-2 w-5 h-5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.26598 2.16147C9.73163 1.94618 10.2692 1.94618 10.7348 2.16147L17.5664 5.31891C17.832 5.44079 18.0008 5.70642 18.0008 5.97206C18.0008 6.29083 17.832 6.55647 17.5664 6.68147L10.7348 9.83788C10.2692 10.0535 9.73163 10.0535 9.26598 9.83788L2.43533 6.68147C2.16995 6.55647 2 6.29083 2 5.97206C2 5.70642 2.16991 5.44079 2.43533 5.31891L9.26598 2.16147ZM17.5664 13.3193C17.832 13.4412 18.0008 13.7068 18.0008 13.9725C18.0008 14.2912 17.832 14.5569 17.5664 14.6819L10.7348 17.8383C10.2692 18.0539 9.73163 18.0539 9.26598 17.8383L2.43533 14.6819C2.16995 14.5569 2 14.2912 2 13.9725C2 13.7068 2.16991 13.4412 2.43533 13.3193L4.09792 12.5505L8.84721 14.7444C9.5785 15.085 10.4223 15.085 11.1536 14.7444L15.9038 12.5505L17.5664 13.3193Z" fill="currentColor" />
                                    <path d="M11.1536 10.7442L15.9038 8.55032L17.5664 9.31911C17.832 9.44099 18.0008 9.70663 18.0008 9.97227C18.0008 10.291 17.832 10.5567 17.5664 10.6817L10.7348 13.8381C10.2692 14.0537 9.73163 14.0537 9.26598 13.8381L2.43533 10.6817C2.16995 10.5567 2 10.291 2 9.97227C2 9.70663 2.16991 9.44099 2.43533 9.31911L4.09792 8.55032L8.84721 10.7442C9.5785 11.0848 10.4223 11.0848 11.1536 10.7442Z" fill="#D0D5DD" />
                                </svg>
                                My Listing
                            </Link>
                        </li>
                        <li className={`flex items-center text-white mb-2 ${page === 'my_incomplete_listing' ? 'active' : ''}`}>
                            <Link to={`/admin/my_incomplete_listing`} className="flex items-center flex-1 py-2 px-3 rounded-lg hover:bg-gray-700 hover:text-blue-500">
                                <svg className="mr-2 w-5 h-5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.26598 2.16147C9.73163 1.94618 10.2692 1.94618 10.7348 2.16147L17.5664 5.31891C17.832 5.44079 18.0008 5.70642 18.0008 5.97206C18.0008 6.29083 17.832 6.55647 17.5664 6.68147L10.7348 9.83788C10.2692 10.0535 9.73163 10.0535 9.26598 9.83788L2.43533 6.68147C2.16995 6.55647 2 6.29083 2 5.97206C2 5.70642 2.16991 5.44079 2.43533 5.31891L9.26598 2.16147ZM17.5664 13.3193C17.832 13.4412 18.0008 13.7068 18.0008 13.9725C18.0008 14.2912 17.832 14.5569 17.5664 14.6819L10.7348 17.8383C10.2692 18.0539 9.73163 18.0539 9.26598 17.8383L2.43533 14.6819C2.16995 14.5569 2 14.2912 2 13.9725C2 13.7068 2.16991 13.4412 2.43533 13.3193L4.09792 12.5505L8.84721 14.7444C9.5785 15.085 10.4223 15.085 11.1536 14.7444L15.9038 12.5505L17.5664 13.3193Z" fill="currentColor" />
                                    <path d="M11.1536 10.7442L15.9038 8.55032L17.5664 9.31911C17.832 9.44099 18.0008 9.70663 18.0008 9.97227C18.0008 10.291 17.832 10.5567 17.5664 10.6817L10.7348 13.8381C10.2692 14.0537 9.73163 14.0537 9.26598 13.8381L2.43533 10.6817C2.16995 10.5567 2 10.291 2 9.97227C2 9.70663 2.16991 9.44099 2.43533 9.31911L4.09792 8.55032L8.84721 10.7442C9.5785 11.0848 10.4223 11.0848 11.1536 10.7442Z" fill="#D0D5DD" />
                                </svg>
                                My Incomplete Listing
                            </Link>
                        </li>
                        {/* <li className="flex items-center  text-white mb-2">
                        <a href="dashboard-reviews.html" className="flex items-center flex-1 py-2 px-3 rounded-lg hover:bg-gray-700 hover:text-blue-500">
                            <svg className="mr-2 w-5 h-5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.99042 15.7432L6.10882 17.8835C5.78183 18.0616 5.38519 18.0335 5.08544 17.8117C4.78569 17.5898 4.63431 17.2086 4.69789 16.8337L5.44272 12.253L2.28718 9.05967C2.02649 8.79096 1.93384 8.39726 2.04829 8.03481C2.16244 7.67548 2.46431 7.41301 2.82734 7.3599L7.1746 6.69748L9.12448 2.56271C9.28495 2.21979 9.62103 2.00163 9.96317 2L9.99042 15.7432ZM14.3867 17.9991C14.3534 18.0023 14.3201 18.0023 14.2868 17.9991H14.3867Z" fill="currentColor" />
                                <path d="M5.70004 17.9991C5.66976 18.0023 5.63645 18.0023 5.60315 17.9991H5.70004ZM9.99037 15.7432V2.00001L9.9934 2C10.3658 2 10.7049 2.21851 10.8654 2.56271L12.8122 6.69748L17.1631 7.3599C17.5234 7.41301 17.8262 7.67548 17.9413 8.03481C18.0563 8.39726 17.9625 8.79096 17.7021 9.05967L14.5471 12.253L15.292 16.8337C15.3525 17.2086 15.2011 17.5898 14.9014 17.8117C14.6016 18.0335 14.205 18.0616 13.881 17.8835L9.9934 15.7432H9.99037Z" fill="#D0D5DD" />
                            </svg>
                            Reviews
                        </a>
                    </li> */}
                    </ul>)}
                    <ul className="mb-4">
                        <p className="uppercase text-gray-500 mb-3 text-sm ml-4">account</p>
                        <li className={`flex items-center text-white mb-2 ${page === 'profile' ? 'active' : ''}`}>
                            <Link to="/admin/profile" className="flex items-center flex-1 py-2 px-3 rounded-lg hover:bg-gray-700 hover:text-blue-500">
                                <svg className="mr-2 w-5 h-5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.8296 11.6H8.57036C5.49461 11.6 3 13.988 3 16.9331C3 17.5221 3.49886 18 4.11407 18H16.2866C16.9018 18.0009 17.4 17.5239 17.4 16.9331C17.4 13.988 14.9057 11.6 11.8296 11.6Z" fill="currentColor" />
                                    <path d="M14.2 6C14.2 8.20906 12.4094 10 10.2 10C7.99064 10 6.20001 8.20937 6.20001 6C6.20001 3.79063 7.99095 2 10.2 2C12.4094 2 14.2 3.79094 14.2 6Z" fill="#D0D5DD" />
                                </svg>
                                My Profile
                            </Link>
                        </li>
                        <li className={`flex items-center text-white mb-2 ${page === 'change_password' ? 'active' : ''}`}>
                            <Link to={`/admin/change_password`} className="flex items-center flex-1 py-2 px-3 rounded-lg hover:bg-gray-700 hover:text-blue-500">
                                <svg className="mr-2 w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                                </svg>
                                Change Password
                            </Link>
                        </li>
                        <li className="flex items-center  text-white mb-2">
                            <div className="flex items-center flex-1 py-2 px-3 rounded-lg hover:bg-gray-700 hover:text-blue-500" onClick={() => logout()}>
                                <svg className="mr-2 w-5 h-5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.7545 10.6395L13.8409 15.7537C13.6031 16.0011 13.2544 16.0687 12.9543 15.9265C12.6542 15.7837 12.4596 15.4576 12.4596 15.0973L12.4564 12.4236L8.09183 12.4236C7.48863 12.4236 7 11.8847 7 11.2202V8.81353C7 8.14906 7.48897 7.61018 8.09183 7.61018L12.4564 7.61022L12.4596 4.90268C12.4596 4.5425 12.6542 4.21639 12.9543 4.07349C13.2545 3.93131 13.6032 3.99889 13.8409 4.24625L18.7545 9.36049C19.0818 9.66554 19.0818 10.2973 18.7545 10.6395Z" fill="currentColor" />
                                    <path d="M4.5 18H6.83333C7.47865 18 8 17.4893 8 16.8571C8 16.225 7.47865 15.7143 6.83333 15.7143H4.5C3.85578 15.7143 3.33333 15.2025 3.33333 14.5714V5.42857C3.33333 4.7975 3.85578 4.28571 4.5 4.28571H6.83333C7.47865 4.28571 8 3.77393 8 3.14286C8 2.51179 7.47865 2 6.83333 2H4.5C2.56698 2 1 3.535 1 5.42857V14.5714C1 16.4643 2.56698 18 4.5 18Z" fill="#D0D5DD" />
                                </svg>
                                logout
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="px-4 pb-6 mt-auto">
                    <div className="flex items-center">
                        <img className="w-8 h-8 rounded-full border border-white" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAMAAAANmfvwAAAAMFBMVEX////S19vR1try8vIAAADQ1dnZ3eDf4uXl5+rW2t75+vrt7/D19vfn6uzx8vQYGShNh6tnAAAA5UlEQVQ4jd2SSQLDIAhFhQZn7f1vWzWolHTYdFVW4ecxijG/t1qcta7Ud/+jo2GIRC6+IjwhEPjiQ/tA8lciEACdFart30ETgE3N08uNQVA5GoEid4DmP+XxLUokMcZhF0RM7ARQVIhUTgGdRoQykgDajdiBAE0/XZHASJrNsi/ajRw1G3aMiKfJjMxmuDCWjejajADsGQM8I1xoNzeT7ELcrhhp5l3tVo5ZM21hTaCRtAS17q1k3cpeVFBp5cOWcQyw1ltpHINYVB+BIEmhXbJ8s7GrYpT5y/H+j92+mjma3Y9P9gDGnQaRgDV0MgAAAABJRU5ErkJggg==" alt="" />
                        <div className="ml-2">
                            <h6 className="text-base font-semibold">{name}</h6>
                            <p className="text-xs font-normal text-gray-300">{email}</p>
                        </div>
                    </div>
                </div>
                {/* <div className="ps__rail-x" style={{ left: '0px', bottom: '0px' }}>
                    <div className="ps__thumb-x" tabIndex={0} style={{ left: '0px', width: '0px' }} />
                </div>
                <div className="ps__rail-y" style={{ top: '0px', height: '343px', right: '0px' }}>
                    <div className="ps__thumb-y" tabIndex={0} style={{ top: '0px', height: '154px' }} />
                </div> */}
            </div>
            <div className="dashboard-sidebar-overlay fixed w-full h-full inset-0 z-40 cursor-pointer bg-black opacity-70 hidden"></div>
        </>
    )
}

export default AdminSideBar