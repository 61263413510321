import React, { useEffect } from 'react'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PageContentContainer from '../../components/PageContentContainer';

import ScrollToTopButton from '../../components/ScrollToTopButton';

import { appendScript, removeScript } from "../../func";

// import businesses_list from "../../../business";
import FeaturedListing from '../../components/FeaturedListing';
import RehabSearchForm from '../../components/RehabSearchForm';

const FindOpioidTreatment = () => {

  useEffect(() => {
    appendScript("/js/plugins-core/handy-collapse.js", "handy-collapse");
    setTimeout(() => {
      appendScript("/js/plugins-script/handy-collapse.js", "handy-collapse-function");
    }, 100)

    return () => {
      removeScript("handy-collapse");
      removeScript("handy-collapse-function");
    }

  }, []);

  return (
    <>
      <Header />

      <PageContentContainer>

        <>
          <section className="dark:bg-background mb-12">
            <div className="relative">
              <img className="w-full object-cover h-52" src="/img/cover-2.png" alt="" />
              <div className="absolute top-1/2 w-full -translate-y-1/2 text-center mb-4">
                <h1 className="capitalize text-4xl text-white font-semibold">Find Opioid Treatment</h1>
                <p className="text-white mt-2">Opioid Rehab & Addiction Treatment Programs</p>
              </div>
            </div>
          </section>

          <div className="container mx-auto px-5">
            <div className="grid grid-cols-3 gap-7 pb-10">

              <div className="lg:col-span-2 col-span-3">
                <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7">
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building.jpg" alt="" /> */}
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-2.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-3.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-4.jpg" alt="" /> */}
                  <div className="p-6 border-b border-gray-200 dark:border-gray-800">

                    <button type="button" data-hc-control="content001" style={{ marginTop: "-2px" }} aria-expanded="false" className="px-2 py-2 mb-4 w-full text-left border-b border-t border-gray-200 dark:border-foreground accordion-border-bottom-none flex justify-between text-base lg:text-lg font-medium text-gray-800 dark:text-gray-200" aria-controls="basicContent001">
                      Jump To Section
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-800 dark:text-gray-400 ml-5 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                      </svg>
                    </button>
                    <div id="basicContent001" className="px-5 mb-4 border-b border-gray-200 dark:border-foreground dark:text-gray-200" data-hc-content="content001" aria-hidden="true" style={{ maxHeight: '0px', visibility: 'hidden', overflow: 'hidden' }}>
                      <p className="text-gray-500 text-base dark:text-gray-300 mb-5"><a href="#What-Are-Opioids" className='hover:text-blue-500' style={{ cursor: "pointer" }}>What Are Opioids?</a></p>
                      <p className="text-gray-500 text-base dark:text-gray-300 mb-5"><a href="#Opioid-Addiction-Treatment-And-Rehab-Options" className='hover:text-blue-500' style={{ cursor: "pointer" }}>Opioid Addiction Treatment & Rehab Options</a></p>
                      <p className="text-gray-500 text-base dark:text-gray-300 mb-5"><a href="#How-Long-Does-Opioid-Rehab-Last" className='hover:text-blue-500' style={{ cursor: "pointer" }}>How Long Does Opioid Rehab Last?</a></p>
                      <p className="text-gray-500 text-base dark:text-gray-300 mb-5"><a href="#Opioid-Addiction-Medications" className='hover:text-blue-500' style={{ cursor: "pointer" }}>Opioid Addiction Medications</a></p>
                      <p className="text-gray-500 text-base dark:text-gray-300 mb-5"><a href="#Opioid-Rehab-Cost-And-Insurance-Coverage" className='hover:text-blue-500' style={{ cursor: "pointer" }}>Opioid Rehab Cost & Insurance Coverage</a></p>
                      {/* <p className="text-gray-500 text-base dark:text-gray-300 mb-5"><a href="#" className='hover:text-blue-500' style={{cursor: "pointer"}}>Find Opioid Treatment Centers Near You</a></p> */}
                      <p className="text-gray-500 text-base dark:text-gray-300 mb-5"><a href="#Frequently-Asked-Questions-Regarding-Opioid-And-Opioid-Rehab" className='hover:text-blue-500' style={{ cursor: "pointer" }}>Frequently Asked Questions Regarding Opioid & Opioid Rehab</a></p>
                    </div>

                    {/* <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit
                    </h5> */}

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Opioid rehab is a common way for those suffering from opioid use disorders to recover from their substance use disorders and improve their wellbeing. Learn more about how opioid rehab can help you or your loved one below.</p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5" id="What-Are-Opioids">
                      What Are Opioids?
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Opioids are a class of drugs that are extracted or synthesized from the <b>opium poppy plant</b>.<sup>1</sup> Some forms of these drugs are prescribed as medications to <b>treat severe acute pain</b> (surgery, fractures, etc.) or “breakthrough pain” associated with cancer, while other forms of these drugs are synthesized and consumed illegally.<sup>1</sup> Opioids work by attaching (binding) to opioid cells (receptors) in the brain, thus activating these receptors and as a result, block  pain signals from the brain to the body, resulting in reduced pain sensation.<sup>2,3</sup> When opioids bind to their receptors, dopamine is released, causing feelings of euphoria (extreme pleasure).  These short-lived euphoric feelings create an intense desire for more dopamine, thus creating reinforcement for continued opioid use. This cycle can often lead to opioid misuse or addiction.<sup>2,4</sup></p>

                    <ul className=" mb-5">
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>Opiates</b> specifically refer to drugs within the class of opioids that are naturally derived, such as heroin or morphine.</div>
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>Opioids</b> represent a broader class of pain medications, that include naturally derived, synthetic (man-made) and semi-synthetic (man-made from a naturally occurring substance).<sup>2</sup></div>
                        </div>
                      </li>
                    </ul>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Different types of opioids can include:<sup>1,2,5,6,7</sup></p>

                    <ul className=" mb-5">
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div>Prescription opioids, including <b>codeine, hydrocodone (Vicodin), morphine, tramadol, and oxycodone (OxyContin)</b>.</div>
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div>Illicit opioids, including heroin.</div>
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div>Fentanyl, which is a prescription opioid but it can also be made and distributed illicitly.</div>
                        </div>
                      </li>
                    </ul>

                    <div className="rounded-lg shadow-front-2 dark:bg-background mb-7 shadow-front-1 hover:shadow-front-4">
                      <div className="rounded-lg p-6 border border-gray-200 dark:border-gray-800">
                        <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                          Signs of Opioid Addiction
                        </h5>

                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                          Opioid addiction or opioid use disorder (OUD) is a chronic medical condition that affects the brain and body. It occurs when an individual cannot cut back their opioid use or quit opioid use despite the negative consequences on their lives and others around them. In order to be diagnosed with an opioid use disorder, you must demonstrate at least two symptoms within a year.<sup>8</sup> Symptoms include:<sup>8</sup>
                        </p>

                        <ul className=" mb-5">
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Cutting back or quitting social activities or hobbies that interfere with opioid use.
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Developing a tolerance or needing to take larger amounts of opioids to feel an effect.
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Experiencing withdrawal symptoms when you cut back or stop opioid use.
                            </div>
                          </li>

                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Having strong cravings to take opioids.
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Inability to cut back or control your use, even if you want to.
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Not being able to stop taking opioids even after it has caused or worsened a physical or mental health issue, or issues with social or personal relationships.
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Ongoing opioid use interferes with your ability to function at home, school, or work.
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Spending a lot of time getting, using, or coming down from opioids.
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Taking a higher dose of opioids or taking opioids for longer than planned or prescribed.
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Using opioids repeatedly in situations that can be dangerous, such as while driving.
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">While an OUD can look different from person to person, there are some other warning signs that may indicate a loved one might be struggling with opioids. These can include:<sup>5,8</sup></p>

                    <ul className=" mb-5">
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Behavior or mood changes.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          A difference in eating or sleeping habits.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Social isolation.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Legal issues.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Less attention to hygiene.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Poor attendance at school or work.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Running out of or losing prescription opioids frequently.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Skipping important meetings or appointments.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Spending time with a different social group.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Tracks (puncture marks) from needle injections on arms, or wearing long sleeves even in hot weather, to cover tracks.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Unexplained money issues.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Visiting multiple doctors or pharmacies to get opioid prescriptions.
                        </div>
                      </li>
                    </ul>

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6" id="Opioid-Addiction-Treatment-And-Rehab-Options">
                      Opioid Addiction Treatment & Rehab Options
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Since opioid addiction can leave a lasting impact on the brain and alter brain functioning, opioid treatment programs need to teach participants a new set of skills to avoid relapse and address any other issues that may be present, including mental health disorders.<sup>9,10</sup> Opioid addiction help can be offered in a variety of ways, including:<sup>9,10</sup>
                    </p>

                    <ul className=" mb-5">
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>Drug detoxification</b>, where patients are medically supervised and stabilized during the withdrawal process after stopping use of opioids.</div>
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>Inpatient treatment</b>, where intensive care is provided along with around-the-clock supervision while you stay at the facility for the duration of treatment. This typically lasts between 3 and 6 weeks.</div>
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>Outpatient treatment</b>, where you live at home and participate in regular home, school, and work responsibilities while attending scheduled appointments that can take place at opioid treatment centers, a clinic, or a doctor’s office. Treatment frequency and intensity can be stepped down as you progress.</div>
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>Counseling</b>, which can be provided in individual and group sessions, typically uses behavioral therapy techniques to increase motivation towards treatment and sobriety, develop relapse prevention skills, incorporate healthy activities to replace substance use, and build peer support.</div>
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>Family therapy</b> may be incorporated if you wish and can help strengthen relationships and improve communication skills.</div>
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>Medication-assisted treatment (MAT)</b>, which uses medications to help ease withdrawal effects in the short-term and can also be used in the long-term to help alleviate urges and cravings in hopes of preventing relapse. Frequently Suboxone is used in the treatment of OUDs. MAT is often combined with behavioral therapy to further treatment goals and maintain sobriety.</div>
                        </div>
                      </li>
                    </ul>

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6" id="How-Long-Does-Opioid-Rehab-Last">
                      How Long Does Opioid Rehab Last?
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      The time spent in opioid rehab is different for everyone, depending on the severity of your OUD and other needs, as well as the progress that you make in treatment.<sup>9</sup> Studies have shown that spending longer periods of time in treatment typically result in better results.<sup>9</sup> Spending at least 90 days in inpatient and/or outpatient treatment has been shown to be more effective at reducing or stopping substance use.<sup>9</sup> However, treatment length should be tailored to meet your unique needs.<sup>9</sup>
                    </p>


                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6" id="Opioid-Addiction-Medications">
                      Opioid Addiction Medications
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      There are multiple opioid addiction medications that can be used for MAT. Opioid addiction treatment centers take multiple factors into account when choosing which medication best suits your individual needs. These factors include your physical health, substance abuse and mental health history, employment, medication interactions, treatment compliance, and personal preferences.<sup>11</sup> Commonly prescribed opioid addiction medications include:<sup>9,10,11,12</sup>
                    </p>

                    <ul className=" mb-5">
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>Methadone</b>, a long-acting synthetic opioid agonist, meaning that it attaches to and activates the same (opioid receptors) in the brain as other opioids do. When methadone occupies and activates opioid receptors in the brain, it does so more slowly than other opioids such as heroin and fentanyl in an opioid-dependent person. As a result, treatment doses of methadone ease withdrawal symptoms and reduce cravings but does not produce the euphoric high associated with opioids of abuse.</div>
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>Buprenorphine</b>, a synthetic partial opioid agonist, meaning it binds to opioid receptors the same way methadone does, but activates them more slowly. Like methadone, buprenorphine also reduces withdrawal symptoms and cravings, making it easier to detox from opioids without causing a high. It also blocks the rewarding effects of any other opioids used, making it a safe and effective maintenance medicine that can help support long-term recovery.</div>
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>Naloxone</b>, an opioid antagonist or blocker meaning that it blocks opioids (oxycodone, morphine, fentanyl, heroin, etc.) at the receptor in the brain, resulting in acute opioid withdrawal if opioids are in an individual’s bloodstream. When naloxone (its brand name Narcan) is used on its own, it is a life-saving medication that can reverse the effects of opioid overdose. Depending on your location, local pharmacies or community programs may have this medication available.</div>
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>Suboxone</b>, a brand name prescription medication composed of buprenorphine and naloxone. Suboxone, when taken as prescribed, works to alleviate opioid withdrawal because of buprenorphine, but if an individual relapses and uses opioids while taking Suboxone, they will experience opioid withdrawal symptoms because of naloxone.</div>
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>Naltrexone</b>, an opioid antagonist that is longer acting than naloxone and therefore is used to prevent cravings and urges in long-term opioid treatment in hopes of preventing relapse.</div>
                        </div>
                      </li>
                    </ul>

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6" id="Opioid-Rehab-Cost-And-Insurance-Coverage">
                      Opioid Rehab Cost & Insurance Coverage
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">The cost of opioid rehab depends on your insurance carrier, your provider, and various other factors. However, most opioid rehab centers (including American Addiction Centers’ various nationwide treatment facilities) accept health insurance for the treatment of opioid use disorders. Find out below whether your insurance may cover the total or partial cost of rehabilitation.</p>

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6" id="Frequently-Asked-Questions-Regarding-Opioid-And-Opioid-Rehab">
                      Frequently Asked Questions Regarding Opioid & Opioid Rehab
                    </h5>

                    <button type="button" data-hc-control="content01" style={{ marginTop: "-2px" }} aria-expanded="false" className="px-5 py-2 w-full text-left border-b border-t border-gray-200 dark:border-foreground accordion-border-bottom-none flex justify-between text-base lg:text-lg font-medium text-gray-800 dark:text-gray-200" aria-controls="basicContent01">
                      Opioid Rehab Success Rates & Statistics
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-800 dark:text-gray-400 ml-5 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                      </svg>
                    </button>
                    <div id="basicContent01" className="px-5 border-b border-gray-200 dark:border-foreground dark:text-gray-200" data-hc-content="content01" aria-hidden="true" style={{ maxHeight: '0px', visibility: 'hidden', overflow: 'hidden' }}>
                      <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Success rates can vary widely between individuals and treatment facilities, making it difficult to provide accurate statistics. However, the success rate of treatment for opioid use disorders is like that of other chronic medical illnesses, such as asthma or high blood pressure.9 Estimates show that substance abuse treatment has a 40-60% success rate, although relapse can be common and may require new treatment strategies for the individual.<sup>9</sup></p>
                    </div>

                    <button type="button" data-hc-control="content02" style={{ marginTop: "-2px" }} aria-expanded="false" className="px-5 py-2 w-full text-left border-b border-t border-gray-200 dark:border-foreground accordion-border-bottom-none flex justify-between text-base lg:text-lg font-medium text-gray-800 dark:text-gray-200" aria-controls="basicContent02">
                      Signs It's Time to Try Rehab
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-800 dark:text-gray-400 ml-5 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                      </svg>
                    </button>
                    <div id="basicContent02" className="px-5 border-b border-gray-200 dark:border-foreground dark:text-gray-200" data-hc-content="content02" aria-hidden="true" style={{ maxHeight: '0px', visibility: 'hidden', overflow: 'hidden' }}>
                      <p className="text-gray-500 text-base dark:text-gray-300 mb-5">If you or a loved one is struggling with an opioid addiction, starting rehab early is a good idea. You don’t have to wait until things get worse to get treatment. But some signs that can indicate it is time for rehab include:</p>

                      <ul className=" mb-5">
                        <li className="mb-1">
                          <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                            <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                            </svg>
                            Family or social relationships are suffering because of opioid use.
                          </div>
                        </li>
                        <li className="mb-1">
                          <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                            <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                            </svg>
                            Opioid use is affecting your physical or mental health.
                          </div>
                        </li>
                        <li className="mb-1">
                          <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                            <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                            </svg>
                            Opioid use is impacting your performance at school or work.
                          </div>
                        </li>
                        <li className="mb-1">
                          <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                            <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                            </svg>
                            Use of opioids has caused legal problems.
                          </div>
                        </li>
                        <li className="mb-1">
                          <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                            <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                            </svg>
                            You are stealing or doing other illegal things to get opioids.
                          </div>
                        </li>
                        <li className="mb-1">
                          <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                            <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                            </svg>
                            You’re unable to stop using opioids on your own.
                          </div>
                        </li>
                        <li className="mb-1">
                          <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                            <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                            </svg>
                            You can’t stop thinking about opioids.
                          </div>
                        </li>
                        <li className="mb-1">
                          <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                            <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                            </svg>
                            You have overdosed.
                          </div>
                        </li>
                      </ul>
                    </div>

                    <button type="button" data-hc-control="content03" style={{ marginTop: "-2px" }} aria-expanded="false" className="px-5 py-2 w-full text-left border-b border-t border-gray-200 dark:border-foreground accordion-border-bottom-none flex justify-between text-base lg:text-lg font-medium text-gray-800 dark:text-gray-200" aria-controls="basicContent03">
                      How to Choose the Best Opioid Treatment Program
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-800 dark:text-gray-400 ml-5 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                      </svg>
                    </button>
                    <div id="basicContent03" className="px-5 border-b border-gray-200 dark:border-foreground dark:text-gray-200" data-hc-content="content03" aria-hidden="true" style={{ maxHeight: '0px', visibility: 'hidden', overflow: 'hidden' }}>
                      <p className="text-gray-500 text-base dark:text-gray-300 mb-5">While it may be tempting to Google “opioid treatment centers near me,” finding the best treatment program for your needs should involve some more factors than location. Some things to think about when choosing an opioid treatment program to meet your needs include:</p>

                      <ul className=" mb-5">
                        <li className="mb-1">
                          <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                            <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                            </svg>
                            Whether they accept your insurance.
                          </div>
                        </li>
                        <li className="mb-1">
                          <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                            <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                            </svg>
                            If they offer MAT.
                          </div>
                        </li>
                        <li className="mb-1">
                          <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                            <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                            </svg>
                            If you have co-occurring mental or physical health issues, as you may want to find out if they can address these in treatment.
                          </div>
                        </li>
                        <li className="mb-1">
                          <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                            <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                            </svg>
                            Whether the facility holds national or state accreditation or certification, and if the staff is licensed or certified.
                          </div>
                        </li>
                        <li className="mb-1">
                          <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                            <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                            </svg>
                            Your family’s ability to participate in your treatment (if you would like them to be involved).
                          </div>
                        </li>
                        <li className="mb-1">
                          <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                            <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                            </svg>
                            If you are LGBTQ+, a veteran or first responder, an older adult, adolescent, woman, or trauma survivor, you may want to ask if the facility has specialized treatment programs to meet these needs.
                          </div>
                        </li>
                      </ul>

                    </div>

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mt-10 mb-4">
                      Sources
                    </h5>

                    <ul className=" mb-5">
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <div><b className="mr-2">1.</b> Drug Enforcement Administration. (2017). <a className="text-blue-500" href="https://www.dea.gov/sites/default/files/2018-06/drug_of_abuse.pdf">Drugs of abuse: A DEA resource guide.</a></div>
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <div><b className="mr-2">2.</b> Centers for Disease Control and Prevention. (2021, January 26). <a className="text-blue-500" href="https://www.dea.gov/sites/default/files/2018-06/drug_of_abuse.pdf">Commonly used terms.</a></div>
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <div><b className="mr-2">3.</b> National Institute on Drug Abuse. (n.d.). <a className="text-blue-500" href="https://www.cdc.gov/opioids/basics/terms.html">Opioids.</a></div>
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <div><b className="mr-2">4.</b> National Institute on Drug Abuse. (2021). <a className="text-blue-500" href="https://teens.drugabuse.gov/drug-facts/prescription-pain-medications-opioids">Prescription pain medication (opioids).</a></div>
                        </div>
                      </li>

                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <div><b className="mr-2">5.</b> American Society of Anesthesiologists. (2021). <a className="text-blue-500" href="https://www.asahq.org/madeforthismoment/pain-management/opioid-treatment/opioid-abuse/">Opioid abuse.</a></div>
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <div><b className="mr-2">6.</b> S. Department of Health and Human Services. (2020, August 30). <a className="text-blue-500" href="https://www.hhs.gov/opioids/prevention/index.html">What are opioids?</a></div>
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <div><b className="mr-2">7.</b> Centers for Disease Control and Prevention. (2021, March 16). <a className="text-blue-500" href="https://www.cdc.gov/opioids/basics/index.html">Opioid basics.</a></div>
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <div><b className="mr-2">8.</b> American Psychiatric Association. (2013). Diagnostic and statistical manual of mental disorders (5th ed.). Arlington, VA: American Psychiatric Publishing.</div>
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <div><b className="mr-2">9.</b> National Institute on Drug Abuse. (2018, January). <a className="text-blue-500" href="https://nida.nih.gov/sites/default/files/675-principles-of-drug-addiction-treatment-a-research-based-guide-third-edition.pdf">Principles of drug addiction treatment: A research-based guide (Third edition).</a></div>
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <div><b className="mr-2">10.</b> National Institute on Drug Abuse. (2019, January). <a className="text-blue-500" href="https://nida.nih.gov/download/1136/treatment-approaches-drug-addiction-drugfacts.pdf">Treatment approaches for drug addiction DrugFacts.</a></div>
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <div><b className="mr-2">11.</b> Substance Abuse and Mental Health Services Administration. <a className="text-blue-500" href="https://store.samhsa.gov/sites/default/files/SAMHSA_Digital_Download/PEP21-02-01-002.pdf">Medications for Opioid Use Disorder</a>. Treatment Improvement Protocol (TIP) Series 63 Publication No. PEP21-02-01-002. Rockville, MD: Substance Abuse and Mental Health Services Administration, 2021.</div>
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <div><b className="mr-2">12.</b> National Institute on Drug Abuse. (2017, March). <a className="text-blue-500" href="https://nida.nih.gov/sites/default/files/opioid_naloxone.pdf">Naloxone for opioid overdose: Life-saving science.</a></div>
                        </div>
                      </li>
                    </ul>

                  </div>
                </div>
              </div>

              <div className="lg:col-span-1 col-span-3">
                <RehabSearchForm />

                {/* {featuredListing()} */}
                {<FeaturedListing items={5}/>}

              </div>

            </div>
          </div>

        </>

        <ScrollToTopButton />

        <Footer />
      </PageContentContainer>

    </>
  )
}

export default FindOpioidTreatment