import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { base64_encode } from '../../func';
import axios from 'axios';

const SearchByName = () => {
    const [rehabName, setRehabName] = useState("");
    const [options, setOptions] = useState([]);
    const [showOptions, setShowOptions] = useState(false);

    useEffect(() => {
        return () => {
            setRehabName("");
            setOptions([]);
        };
    }, [])

    useEffect(() => {
        search(rehabName.trim());
    }, [rehabName])

    const search = (text) => {
        if (text !== "" && text.length >= 2) {
            const json = JSON.stringify({ "search": text });
            const url = `${process.env.REACT_APP_BASE_API}/api.php?action=autocomplete`;

            axios.post(url, JSON.stringify({ params: json }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.data.success === "true") {
                    setOptions(response.data.results);
                }
                else {
                    setOptions([]);
                }
            }).catch((error) => {
                console.error(`Error: ${error}`);
            });

            setShowOptions(true);
        }
        else {
            setShowOptions(false);
        }
    }

    return (
        <section>
            <div className="mx-auto">
                <div className="grid md:grid-cols-2 items-center py-5" data-aos="fade-up" data-aos-duration={800} style={{ backgroundColor: "#1570ef" }}>
                    <div className="lg:text-left text-center aos-init custom-padding col-span-1">{/**py-11 px-10**/}
                        <div className='mx-auto newsletter-text'>
                            <h2 className="lg:text-3xl text-2xl font-medium capitalize text-gray-100 dark:text-gray-100">
                                Search Rehab By Name
                            </h2>
                            {/* <p className="text-base font-normal text-gray-100 dark:text-gray-200">
                                Get important update to your email.
                            </p> */}
                        </div>
                    </div>
                    <div className="col-span-1 custom-padding">{/**py-11 px-10**/}
                        {/* <img  src="/img/newsletter.svg" alt="" /> */}
                        {/* <img className="max-w-full w-[405px] mx-auto card" src="/img/newsletter.png" alt="" /> */}
                        {/* <div className="mx-auto"> */}
                        <div className='search-container'>
                            <div className="inline-flex bg-white dark:bg-foreground text-gray-400 p-2.5 mx-auto rounded-lg shadow-front-4 card newsletter-input" style={{ width: "75%" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-w-5 w-5 mx-2 shrink-0" fill="none" viewBox="0 0 30 30" stroke="currentColor" strokeWidth={2}>
                                    {/* <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" /> */}
                                    <path d="M13 3C7.489 3 3 7.489 3 13s4.489 10 10 10a9.947 9.947 0 0 0 6.322-2.264l5.971 5.971a1 1 0 1 0 1.414-1.414l-5.97-5.97A9.947 9.947 0 0 0 23 13c0-5.511-4.489-10-10-10zm0 2c4.43 0 8 3.57 8 8s-3.57 8-8 8-8-3.57-8-8 3.57-8 8-8z" />
                                </svg>
                                <input type="text" placeholder="Enter rehab name" className="focus:outline-none w-full dark:text-gray-100 dark:bg-foreground py-2" value={rehabName} onChange={(e) => setRehabName(e.target.value)} onMouseEnter={() => { options.length > 0 && setShowOptions(true) }} />
                                {/* <button type="button" className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-[11px] rounded-lg ml-0 lg:ml-10" onClick={() => Subscribe()}>
                                    Subscribe
                                </button> */}
                            </div>

                            {(showOptions && rehabName.trim().length >= 2) &&
                                (<ul className='mt-2 p-2.5 card bg-white dark:bg-foreground text-black dark:text-gray-100 autocomplete' onMouseLeave={()=> setTimeout(() => { setShowOptions(false) }, 1000)}>
                                    {(options.length > 0) ?
                                        options.map((option, index) => (
                                            <li key={index}>
                                                <Link to={`/details/${base64_encode(option["listing_id"].toString())}/${option["alias"]}`}>
                                                    <div className='text-sm container-div'>
                                                        <p className='text-blue-500'>{option["option"]}</p>
                                                        <p className='text-xs'>{option["address"]}</p>
                                                    </div>
                                                </Link>
                                            </li>
                                        ))
                                        :
                                        (<li>No match found</li>)
                                    }
                                </ul>)
                            }
                        </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SearchByName