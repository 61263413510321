import React from 'react'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PageContentContainer from '../../components/PageContentContainer';

import ScrollToTopButton from '../../components/ScrollToTopButton';

// import businesses_list from "../../../business";
import FeaturedListing from '../../components/FeaturedListing';
import RehabSearchForm from '../../components/RehabSearchForm';

const SignsOfOpioidOverdose = () => {

  return (
    <>
      <Header />

      <PageContentContainer>

        <>
          <section className="dark:bg-background mb-12">
            <div className="relative">
              <img className="w-full object-cover h-52" src="/img/cover-2.png" alt="" />
              <div className="absolute top-1/2 w-full -translate-y-1/2 text-center mb-4">
                <h1 className="capitalize text-4xl text-white font-semibold">Signs Of Opioid Overdose</h1>
                <p className="text-white mt-2">Opioid Overdose Basics: Recognizing Opioid Overdose</p>
              </div>
            </div>
          </section>

          <div className="container mx-auto px-5">
            <div className="grid grid-cols-3 gap-7 pb-10">

              <div className="lg:col-span-2 col-span-3">
                <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7">
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-3.jpg" alt="" /> */}
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-2.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-3.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-4.jpg" alt="" /> */}
                  <div className="p-6 border-b border-gray-200 dark:border-gray-800">
                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      Opioid Overdose Basics: Recognizing Opioid Overdose
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Sometimes it can be difficult to tell if a person is just very high, or experiencing an overdose. The following will present some information on how to tell the difference. If you’re having a hard time telling the difference, it is best to treat the situation like an overdose – it could save someone’s life.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">If someone is really high and using downers like heroin, or pills:</p>

                    <ul className=" mb-5">
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Pupils will contract and appear small
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Muscles are slack and droopy
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          They might “nod out”
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Scratch a lot due to itchy skin
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Speech may be slurred
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          They might be out of it, but they will respond to outside stimuli like loud noise or a light shake from a concerned friend.
                        </div>
                      </li>
                    </ul>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">If you are worried that someone is getting too high, it is important that you don’t leave them alone. If the person is still conscious, walk them around, keep them awake, and monitor their breathing.</p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      The following are signs of an overdose:
                    </h4>

                    <ul className=" mb-5">
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Loss of consciousness
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Unresponsive to outside stimulus
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Awake, but unable to talk
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Breathing is very slow and shallow, erratic, or has stopped
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          For lighter skinned people, the skin tone turns bluish purple, for darker skinned people, it turns grayish or ashen.
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Choking sounds, or a snore-like gurgling noise (sometimes called the “death rattle”)
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Vomiting
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Body is very limp
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Face is very pale or clammy
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Fingernails and lips turn blue or purplish black
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Pulse (heartbeat) is slow, erratic, or not there at all
                        </div>
                      </li>
                    </ul>

                    <div className="pt-3 dark:text-gray-400 text-gray-500 leading-[24px]">
                      <b>If someone is making unfamiliar sounds while “sleeping” it is worth trying to wake him or her up.</b> Many loved ones of users think a person was snoring, when in fact the person was overdosing. These situations are a missed opportunity to intervene and save a life.
                    </div>

                    <div className="pt-3 dark:text-gray-400 text-gray-500 leading-[24px] mb-5">
                      <b>It is rare for someone to die immediately from an overdose.</b> When people survive, it’s because someone was there to respond.
                    </div>

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6 pt-6">
                      The most important thing is to act right away!
                    </h5>

                  </div>
                </div>
              </div>

              <div className="lg:col-span-1 col-span-3">
                <RehabSearchForm />

                {/* {featuredListing()} */}
                {<FeaturedListing items={5}/>}

              </div>

            </div>
          </div>

        </>

        <ScrollToTopButton />

        <Footer />
      </PageContentContainer>

    </>
  )
}

export default SignsOfOpioidOverdose