import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { base64_encode } from '../../func';

import { Link } from 'react-router-dom';

const FeaturedListing = ({ items }) => {
    const [featuredList, setFeaturedListing] = useState([]);

    useEffect(() => {
        fetchFeaturedListing(items);
    }, []);

    const fetchFeaturedListing = (limit) => {
        const json = JSON.stringify({ "limit": limit });

        axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_featured_listing`, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                setFeaturedListing(response.data.featured_listing);
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        }).finally(()=>{
          window["initializeSwiper"]();
      });
    }

    return (
        (featuredList !== undefined && featuredList.length > 0) && (<div className="shadow-front-2 dark:bg-foreground p-6 rounded-lg mb-7">
            <div className="flex justify-between mb-6">
                <h4 className="text-base font-medium text-gray-700 dark:text-gray-100">
                    Feature Listings
                </h4>
                {/* <a href="#" className="text-gray-500 dark:text-gray-400 text-sm">More</a> */}
            </div>

            {
                featuredList.map(({ listing_id, title, alias, address, rating, reviews }, index) => (
                    <Link to={`/details/${base64_encode(listing_id.toString())}/${alias}`} key={index} className="flex mb-6">
                        {/* <img className="w-24 h-auto flex-shrink-0 rounded-lg mr-4" src={``} alt={title} /> */}
                        <img className="w-24 h-24 flex-shrink-0 object-cover rounded-lg mr-4" src={`https://maps.googleapis.com/maps/api/staticmap?autoscale=2&size=400x400&maptype=roadmap&key=AIzaSyDQQPvj2WRZrF3bBXyrrGI2vVvojSDhjSs&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0x0a1d38%7Clabel:1%7C4040+${encodeURIComponent(address)}`} alt="" />
                        <div>
                            <h6 className="text-md text-gray-700 dark:text-gray-300 font-medium hover:text-blue-500" style={{ lineHeight: "1.3rem" }}>
                                {title}
                            </h6>
                            <div className="text-sm text-base dark:text-gray-400">{`${address}`}</div>
                            {/* <div className="dark:bg-gray-900 rounded-full justify-center inline-flex items-center">
                                {
                                    !is_closed ?
                                        (<span className="bg-green-600 text-xs font-medium text-white px-3 py-1 rounded-full">Open</span>)
                                        :
                                        (<span className="bg-red-100 text-xs font-medium dark:text-gray-400 px-3 py-1 rounded-full">Close</span>)
                                }
                            </div> */}
                            {/* <div className="bg-gray-100 dark:bg-gray-900 rounded-full px-3 py-1 inline-flex items-center">
                  <svg className="w-3 h-auto text-green-500 mr-2" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.6498 2.39995H7.3498V1.64995C7.3498 1.40151 7.55043 1.19995 7.7998 1.19995C8.04918 1.19995 8.2498 1.40151 8.2498 1.64995V2.39995H8.9998C9.66168 2.39995 10.1998 2.93714 10.1998 3.59995V9.59995C10.1998 10.2618 9.66168 10.8 8.9998 10.8H2.9998C2.33699 10.8 1.7998 10.2618 1.7998 9.59995V3.59995C1.7998 2.93714 2.33699 2.39995 2.9998 2.39995H3.7498V1.64995C3.7498 1.40151 3.95043 1.19995 4.1998 1.19995C4.44918 1.19995 4.6498 1.40151 4.6498 1.64995V2.39995ZM2.6998 9.59995C2.6998 9.76495 2.83405 9.89995 2.9998 9.89995H8.9998C9.1648 9.89995 9.2998 9.76495 9.2998 9.59995V4.79995H2.6998V9.59995Z" fill="currentColor"></path>
                  </svg>
                  <span className="text-xs font-medium dark:text-gray-400">Instant Booking</span>
                </div> */}
                        </div>
                    </Link>
                ))
            }

            {/* <a href="#" className="flex mb-6">
          <img className="w-24 h-auto flex-shrink-0 rounded-lg mr-4" src="img/Image/landing-page-image/building-2.jpg" alt="" />
          <div>
            <h6 className="text-gray-700 dark:text-gray-300 font-medium mb-2">
              Duplex Apartment with Pool
            </h6>
            <div className="bg-gray-100 dark:bg-gray-900 rounded-full px-3 py-1 inline-flex items-center">
              <svg className="w-3 h-auto text-green-500 mr-2" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.6498 2.39995H7.3498V1.64995C7.3498 1.40151 7.55043 1.19995 7.7998 1.19995C8.04918 1.19995 8.2498 1.40151 8.2498 1.64995V2.39995H8.9998C9.66168 2.39995 10.1998 2.93714 10.1998 3.59995V9.59995C10.1998 10.2618 9.66168 10.8 8.9998 10.8H2.9998C2.33699 10.8 1.7998 10.2618 1.7998 9.59995V3.59995C1.7998 2.93714 2.33699 2.39995 2.9998 2.39995H3.7498V1.64995C3.7498 1.40151 3.95043 1.19995 4.1998 1.19995C4.44918 1.19995 4.6498 1.40151 4.6498 1.64995V2.39995ZM2.6998 9.59995C2.6998 9.76495 2.83405 9.89995 2.9998 9.89995H8.9998C9.1648 9.89995 9.2998 9.76495 9.2998 9.59995V4.79995H2.6998V9.59995Z" fill="currentColor"></path>
              </svg>

              <span className="text-xs font-medium dark:text-gray-400">Instant Booking</span>
            </div>
          </div>
        </a>
        <a href="#" className="flex mb-6">
          <img className="w-24 h-auto flex-shrink-0 rounded-lg mr-4" src="img/Image/event-landing-page/concert.jpg" alt="" />
          <div>
            <h6 className="text-gray-700 dark:text-gray-300 font-medium mb-2">
              Tomorrowland Concert
            </h6>
            <div className="bg-gray-100 dark:bg-gray-900 rounded-full px-3 py-1 inline-flex items-center">
              <svg className="w-3 h-auto text-green-500 mr-2" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.6498 2.39995H7.3498V1.64995C7.3498 1.40151 7.55043 1.19995 7.7998 1.19995C8.04918 1.19995 8.2498 1.40151 8.2498 1.64995V2.39995H8.9998C9.66168 2.39995 10.1998 2.93714 10.1998 3.59995V9.59995C10.1998 10.2618 9.66168 10.8 8.9998 10.8H2.9998C2.33699 10.8 1.7998 10.2618 1.7998 9.59995V3.59995C1.7998 2.93714 2.33699 2.39995 2.9998 2.39995H3.7498V1.64995C3.7498 1.40151 3.95043 1.19995 4.1998 1.19995C4.44918 1.19995 4.6498 1.40151 4.6498 1.64995V2.39995ZM2.6998 9.59995C2.6998 9.76495 2.83405 9.89995 2.9998 9.89995H8.9998C9.1648 9.89995 9.2998 9.76495 9.2998 9.59995V4.79995H2.6998V9.59995Z" fill="currentColor"></path>
              </svg>

              <span className="text-xs font-medium dark:text-gray-400">Instant Booking</span>
            </div>
          </div>
        </a>
        <a href="#" className="flex">
          <img className="w-24 h-auto flex-shrink-0 rounded-lg mr-4" src="img/Image/landing-page-image/palace.jpg" alt="" />
          <div>
            <h6 className="text-gray-700 dark:text-gray-300 font-medium mb-2">
              Luxary Hotel For Rent
            </h6>
            <div className="bg-gray-100 dark:bg-gray-900 rounded-full px-3 py-1 inline-flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-auto text-yellow-500 mr-1" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>

              <span className="text-xs font-medium dark:text-gray-400">Instant Booking</span>
            </div>
          </div>
        </a> */}
        </div>)
    )
}

export default FeaturedListing