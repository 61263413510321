import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Alert from '../../../components/Alert';

import { getCookie, setCookie, checkRole } from '../../../func';

import { Link, useNavigate } from "react-router-dom";

const Register = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    useEffect(() => {
        //const checkLoginStatus = getCookie("token");
        //console.log(checkRole());

        if (getCookie("token") !== undefined && getCookie("token") !== null) {
            //user is already loggedin
            if (checkRole(getCookie("token")) === "admin")
                navigate(`/admin/dashboard`, { replace: true });
            else
                navigate(`/admin/add_listing`, { replace: true });
        }
    }, [])

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 5000);
    }

    const validateUser = (e) => {
        e.preventDefault();

        if (email.trim() !== "" && password.trim() !== "" && username !== "") {
            const json = JSON.stringify({ "username": username.trim(), "email": email.trim(), "password": password.trim() });

            axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=register`, JSON.stringify({ params: json }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.data.success === "true") {
                    handleMessage({ type: 'success', message: response.data.message, show: true });

                    setTimeout(() => {
                        navigate(`/login`, { replace: true });
                    }, 3000);
                }
                else {
                    handleMessage({ type: 'danger', message: response.data.message, show: true });
                }
            }).catch((error) => {
                console.error(`Error: ${error}`);
            });
        }
        else {
            if (email.trim() === "" && password.trim() === "" && username.trim() === "")
                handleMessage({ type: 'danger', message: "Please provide email and password to signin", show: true });
            else if (email.trim())
                handleMessage({ type: 'danger', message: "Please provide email to signin", show: true });
            else if (password.trim() === "")
                handleMessage({ type: 'danger', message: "Please provide password to signin", show: true });
            else if (username.trim() === "") {
                handleMessage({ type: 'danger', message: "Please provide username", show: true });
            }
        }
    }

    return (
        <section>
            <div className="mx-auto" style={{ backgroundColor: "#f5f5f5" }}>
                <div className="grid lg:grid-cols-1 grid-cols-1 gap-10 p-10 items-center rounded-lg">
                    {/* <img className="w-full lg:block hidden rounded-2xl" src="/img/sessions.png" alt="" style={{maxHeight: "500px", width: "auto"}} /> */}
                    {/* <div className='mx-auto'>
                        <Alert type={alert.type} message={alert.message} show={alert.show} />
                    </div> */}

                    <form className="mx-auto bg-white p-8" style={{ borderRadius: "0.5rem", maxWidth: "360px" }} onSubmit={validateUser}>
                        <div className="text-center mb-4">
                            <Alert type={alert.type} message={alert.message} show={alert.show} />
                            <Link to={"/"}>
                                <img className="w-20 mx-auto h-auto mb-4" src="/img/OR-logo.webp" alt="" />
                            </Link>
                            {/* <p className="text-gray-700 font-semibold text-lg mb-6">Accommo</p> */}
                            <h1 className="text-gray-700 text-2xl capitalize font-semibold mb-3">
                                Register your account
                            </h1>
                            {/* <p className="text-base text-gray-500 mb-7">
                                If you haven’t any account, please
                                <a href="/sign-up.html" className="text-blue-500 font-medium">Register</a>
                            </p> */}
                        </div>
                        <div>
                            <label className="text-gray-700 font-medium mb-1">User Name <sup>*</sup></label>
                            <div className="relative mb-2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-4 text-gray-400 absolute top-1/2 -translate-y-1/2 ml-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path d="M15.1235 17V15.2222C15.1235 14.2793 14.7515 13.3749 14.0893 12.7081C13.4271 12.0413 12.5291 11.6667 11.5926 11.6667H4.53087C3.59443 11.6667 2.69633 12.0413 2.03417 12.7081C1.372 13.3749 1 14.2793 1 15.2222L1 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M8.06174 8.11111C10.0118 8.11111 11.5926 6.51923 11.5926 4.55556C11.5926 2.59188 10.0118 1 8.06174 1C6.11169 1 4.53087 2.59188 4.53087 4.55556C4.53087 6.51923 6.11169 8.11111 8.06174 8.11111Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                                <input type="text" className="border pl-9 border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg dark:bg-foreground py-4 focus:outline-none w-full" placeholder="Enter username" value={username} onChange={(e) => setUsername(e.target.value)} />
                            </div>
                            <label className="text-gray-700 font-medium mb-1">Email <sup>*</sup></label>
                            <div className="relative mb-2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-4 text-gray-400 absolute top-1/2 -translate-y-1/2 ml-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                </svg>
                                <input type="email" className="border pl-9 border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg dark:bg-foreground py-4 focus:outline-none w-full" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())} />
                            </div>
                            <label className="text-gray-700 font-medium mb-1">Password <sup>*</sup></label>
                            <div className="relative mb-5">
                                <svg className="h-5 w-4 text-gray-400 absolute top-1/2 -translate-y-1/2 ml-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                                </svg>
                                <input type="password" className="border pl-9 border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg dark:bg-foreground py-4 focus:outline-none w-full" placeholder="Enter password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <div className="flex justify-between flex-wrap mb-3">
                                {/* <div className="mb-3">
                                    <input type="checkbox" id="vehicle1" name="vehicle1" defaultValue="Bike" className="border border-gray-500" />
                                    <label htmlFor="vehicle1" className="text-gray-500 font-normal">
                                        Remember me?</label>
                                </div> */}
                                <Link to={'/login'} className="text-red-500 mb-3">Already have an account? Login</Link>
                            </div>
                            <button type="sumbit" className="w-full flex justify-center items-center bg-blue-500 hover:bg-blue-600 rounded-lg text-white p-4 mb-2">
                                Register
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        </section>
    )
}

export default Register