import React from 'react'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PageContentContainer from '../../components/PageContentContainer';

import ScrollToTopButton from '../../components/ScrollToTopButton';

// import businesses_list from "../../../business";
import FeaturedListing from '../../components/FeaturedListing';
import RehabSearchForm from '../../components/RehabSearchForm';

const OpioidAddictionRiskFactors = () => {

  return (
    <>
      <Header />

      <PageContentContainer>

        <>
          <section className="dark:bg-background mb-12">
            <div className="relative">
              <img className="w-full object-cover h-52" src="/img/cover-2.png" alt="" />
              <div className="absolute top-1/2 w-full -translate-y-1/2 text-center mb-4">
                <h1 className="capitalize text-4xl text-white font-semibold">Opioid Addiction Risk Factors</h1>
                <p className="text-white mt-2">7 Risk Factors For Opioid use And Addiction</p>
              </div>
            </div>
          </section>

          <div className="container mx-auto px-5">
            <div className="grid grid-cols-3 gap-7 pb-10">

              <div className="lg:col-span-2 col-span-3">
                <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7">
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-4.jpg" alt="" /> */}
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-2.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-3.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-4.jpg" alt="" /> */}
                  <div className="p-6 border-b border-gray-200 dark:border-gray-800">
                    {/* <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit
                    </h5> */}

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Prescription opioid use is becoming more and more controversial as the opioid epidemic continues to be a crisis in the United States. Individuals are also becoming more educated on the dangers of using opioids and the risks they may bring. Anyone can become addicted to opioids, even when only using drugs as prescribed. However, some people are more prone to use and addiction due to certain risk factors. Being aware of these factors can help individuals determine if taking opioids is worth the risk. Let’s explore seven risk factors associated with opioid dependence and addiction.</p>

                    {/* <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi voluptates accusantium molestias magnam beatae modi dolores corrupti aliquam saepe fugit, vero repudiandae praesentium eum iste quia numquam ipsum, reiciendis consectetur!
                      Illo velit distinctio magnam corrupti quasi vero reprehenderit. Ab assumenda enim ipsam quia velit facere, maxime cumque eveniet eligendi ea officiis! Optio totam sit animi hic dolorum obcaecati illum asperiores.
                      Impedit deserunt illo accusantium? Nobis ex modi cum hic sed distinctio possimus placeat quos nam aut. Laborum obcaecati quis, rem exercitationem eum aliquam non? At magnam placeat molestias cum tempora!
                      Rerum sint praesentium voluptas deserunt officia nobis, porro recusandae necessitatibus omnis vero beatae perspiciatis expedita aperiam reiciendis eaque nostrum quis consequuntur iste eum ad sequi corrupti! Explicabo nobis sit praesentium.</p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam vel ea sunt molestias facere quidem! Unde, repellendus dolores neque voluptatibus id, sunt impedit, nobis molestiae quisquam aut aliquid rem sed.
                      Doloremque, voluptatum voluptas iure facere, laudantium molestiae sint quam corporis illum ad amet recusandae vitae ea, accusamus dicta! Expedita accusantium quo reprehenderit! Repellendus quod eos tenetur nesciunt deleniti, cumque enim.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda facilis eum aliquam delectus deleniti corrupti nulla veniam cumque velit, veritatis eos quia dolorum enim ut quidem? Beatae officia aliquam voluptas!
                      Dolorum quia at maxime aliquam distinctio, molestias ratione nesciunt quod cupiditate laudantium aspernatur consequatur eaque vero id eius rerum libero dolore nobis modi. Dicta, illum architecto expedita explicabo laboriosam perferendis.
                      Debitis maiores pariatur enim voluptatum? Optio ipsa dignissimos, dolore, debitis adipisci veniam sed et labore, odio ab incidunt nihil officiis temporibus ut nemo aliquid ad eos rerum qui deserunt ipsam!</p> */}

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      1. Genetics
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Individuals who have a family history of drug or alcohol use and addiction are more likely to develop a substance use disorder themselves. In fact, studies have shown that up to half a person’s risk of developing an addiction is associated with his or her genetic makeup. The more instances of addiction in a person’s close family, the more at risk he or she is for opioid or other substance use and addiction.</p>

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      2. Previous History Of Substance Use
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">People who have a past history of substance or alcohol use or addiction of any kind are more susceptible to opioid addiction. If you have a history of substance addiction – even tobacco addiction – be sure to tell your healthcare provider before beginning an opioid medication.</p>

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      3. Environmental Influences
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Several environmental factors can increase a person’s risk of developing a substance use disorder. These environmental factors can be both past and present and may include:
                    </p>

                    <ul className=" mb-5">
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          exposure to sexual, emotional, or physical use
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          being brought up in a household where drug and alcohol use was present
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          stressors such as loss of a loved one or divorce
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          being around peers who use drugs or alcohol
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          easy access to addictive substances
                        </div>
                      </li>
                    </ul>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Being exposed to one or many of these environmental factors can contribute to the development of opioid use or addiction.</p>

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      4. Psychological Factors
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Psychological factors such as mental health disorders can increase a person’s risk of opioid use and addiction. For example, a person who is struggling with depression may turn to substances as a way to cope with his or her depressive symptoms. Likewise, someone with anxiety may try taking opioids in an attempt to calm down and feel relaxed. Unfortunately, addiction can often worsen mental illness and make it more difficult to successfully recover from both conditions.</p>

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      5. Age
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Studies have shown that both younger individuals (ages 18-30) and older individuals (ages 50 and older) are more susceptible to opioid use and addiction. Additionally, people ages 16 to 45 are more at risk for opioid-related deaths.</p>

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      6. Chronic Pain
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">A primary reason people are prescribed opioids is to treat and manage chronic pain. Conditions that may cause chronic pain include cancer, arthritis, back problems, and other long-term health maladies. Unfortunately, the longer a person suffers from pain, the longer he or she will likely take opioids in an attempt to manage it. Extended periods of opioid use can greatly increase a person’s risk of developing a dependence on and addiction to these substances.</p>

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      7. Method Of Use
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">How a person uses opioids can also affect his or her risk of developing an addiction to the substance. For example, if a person crushes up an opioid medication and snorts it in order to get a more intense high, this can quickly lead to tolerance and dependence on the drug. Additionally, injecting or smoking opioids such as heroin can also drastically increase a person’s risk of addiction.</p>

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      Getting Help For Opioid use And Addiction
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">If you or someone you know is struggling with opioid addiction, it’s important to know that help is available and recovery is possible. A substance use disorder can be debilitating, but seeking treatment can help you reclaim your life.  With our help, you can overcome addiction and lead a fulfilling life in recovery. To learn more about the risk factors associated with opioid use and addiction, contact a treatment specialist today.</p>
                  </div>
                </div>
              </div>

              <div className="lg:col-span-1 col-span-3">
                <RehabSearchForm />

                {/* {featuredListing()} */}
                {<FeaturedListing items={5}/>}

              </div>

            </div>
          </div>

        </>

        <ScrollToTopButton />

        <Footer />
      </PageContentContainer>

    </>
  )
}

export default OpioidAddictionRiskFactors