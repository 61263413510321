import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Alert from '../../../components/Alert';

import { Link, useNavigate } from "react-router-dom";
import { getCookie, checkRole, base64_decode, roleBasedBreadCrumbUrl } from '../../../func';
import AdminSideBar from '../../../components/AdminSideBar';
import AdminHeader from '../../../components/AdminHeader';

const AddListing = () => {
    const navigate = useNavigate();
    //const [alias, setAlias] = useState('');

    const [user_id, setUserID] = useState(0);

    const [title, setTitle] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postalCode, setPostalCode] = useState('');

    const [phone, setPhone] = useState('');
    const [website, setWebsite] = useState('');

    const [treatmentTypePoints, setTreatmentTypePoints] = useState('');
    const [paymentInfoPoints, setPaymentInfoPoints] = useState('');
    const [specialityServicePoints, setSpecialityServicePoints] = useState('');

    const [address, setAddress] = useState('');
    const [description, setDescription] = useState('');
    const [paymentInfo, setPaymentInfo] = useState('');
    const [keyPoints, setKeyPoints] = useState('');

    const [images, setImages] = useState([]);
    const [rating, setRating] = useState(0);
    const [activeteListing, setActivateListing] = useState(1);

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    // useEffect(() => {
    // console.log("title", title);
    // console.log("address", address);
    // console.log("city", city);
    // console.log("state", state);
    // console.log("address", address);
    // console.log("postalCode", postalCode);
    // console.log("phone", phone);
    // console.log("website", website);
    // console.log("treatmentTypePoints", treatmentTypePoints);
    // console.log("paymentInfoPoints", paymentInfoPoints);
    // console.log("specialityServicePoints", specialityServicePoints);
    // console.log("description", description);
    // console.log("paymentInfo", paymentInfo);
    // console.log("keyPoints", keyPoints);
    // console.log("images", images);
    // console.log("activeteListing", activeteListing);
    // }, [title, address, postalCode, phone, website, treatmentTypePoints, paymentInfoPoints, specialityServicePoints, description, paymentInfo, keyPoints, images, activeteListing])

    useEffect(() => {
        const token = getCookie("token");
        // console.log("token", token);

        if (token === null) {
            // navigate(`/admin/login`, { replace: true });
            navigate(`/login`, { replace: true });
        }
        else {
            if (getCookie("token") !== undefined && getCookie("token") !== null) {
                if (checkRole(getCookie("token")) === "admin" || checkRole(getCookie("token")) === "member") {
                    const parts = base64_decode(token).split("|");
                    setUserID(parts[0]);
                }
                else if (checkRole(getCookie("token")) === "author") {
                    navigate(`/admin/add_post`, { replace: true });
                }
                else {
                    navigate(`/login`, { replace: true });
                }
            }
        }
    }, []);

    useEffect(() => {
        if (address.trim() !== "") {
            let address_parts = address.split(",");
            if (address_parts.length > 0 && address_parts.length === 4) {
                setStreetAddress(address_parts[0].trim());
                setCity(address_parts[1].trim());
                setState(address_parts[2].trim());
                setPostalCode(address_parts[3].trim());
            }
        }
    }, [address]);

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 10000);
    }

    // const logout = () => {
    //     setTimeout(() => {
    //         eraseCookie("token");
    //         navigate(`/admin/login`, { replace: true });
    //     }, 500);
    // }

    const clearFields = () => {
        setTitle('');
        setAddress('');
        setStreetAddress('');
        setCity('');
        setState('');
        setPostalCode('');
        setDescription('');
        setPaymentInfo('');
        setKeyPoints('');
        setPhone('');
        setWebsite('');
        setImages([]);
        setTreatmentTypePoints('');
        setPaymentInfoPoints('');
        setSpecialityServicePoints('');
        setRating(0);
        setActivateListing(0);
    }

    const changeHandler = (e) => {
        let files = e.target.files;
        //console.log("files: ", files);

        var allFiles = [];
        for (var i = 0; i < files.length; i++) {

            let file = files[i];

            let ext = '';
            switch (file.type) {
                case "image/x-png":
                    ext = "png"
                    break;
                case "image/png":
                    ext = "png"
                    break;
                case "image/jpeg":
                    ext = "jpg"
                    break;
                case "image/webp":
                    ext = "webp"
                    break;
                default:
                    break;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let fileInfo = {
                    name: file.name.replace(`.${ext}`, ''),
                    type: file.type,
                    extention: ext,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result,
                    file: file,
                };

                allFiles.push(fileInfo);

                if (allFiles.length == files.length) {
                    console.log(allFiles);
                    //if (this.props.multiple) this.props.onDone(allFiles);
                    //else this.props.onDone(allFiles[0]);
                    setImages(allFiles);
                }
            }
        }
    }

    const removeImage = (i) => {
        let array = [...images];
        array.splice(i, 1);
        setImages(array);
    }

    const submitListing = () => {
        //console.log("submitListing clicked");
        //handleMessage({ type: 'danger', message: "Please provide listing title!", show: true });

        if (title.trim() !== "" && streetAddress.trim() !== "" && city.trim() !== "" && state.trim() !== "" && postalCode.trim() !== "" && phone.trim() !== "" && website.trim() !== "" && treatmentTypePoints.trim() !== "" && paymentInfoPoints.trim() !== "" && specialityServicePoints.trim() !== "" && address.trim() !== "" && description.trim() !== "" && paymentInfo.trim() !== "" && keyPoints.trim() !== "" && images.length > 0 && rating !== "" && user_id !== "" && activeteListing !== "") {
            const json = JSON.stringify({ "title": title.trim(), "roadorstreet": streetAddress.trim(), "city": city.trim(), "state": state.trim(), "postalCode": postalCode.trim(), "phone": phone.trim(), "website": website.trim(), "treatmentTypePoints": treatmentTypePoints.trim().split('\n').map(s => s.trim()).filter(n => n).join("||"), "paymentInfoPoints": paymentInfoPoints.trim().split('\n').map(s => s.trim()).filter(n => n).join("||"), "specialityServicePoints": specialityServicePoints.trim().split('\n').map(s => s.trim()).filter(n => n).join("||"), "address": address.trim(), "description": description.trim(), "paymentInfoSummery": paymentInfo.trim().split('\n').map(s => s.trim()).filter(n => n).join("||"), "keyPoints": keyPoints.trim().split('\n').map(s => s.trim()).filter(n => n).join("||"), "images": images, "rating": rating.trim(), "user_id": user_id.toString().trim(), "active": activeteListing ? 1 : 0 });
            //console.log(json);

            axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=add_listing`, JSON.stringify({ params: json }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.data.success === "true") {
                    handleMessage({ type: 'success', message: response.data.message, show: true });
                    //console.log(response.data.listing);
                    clearFields();
                    setTimeout(() => {
                        navigate(`/admin/add_review/${response.data.listing.listing_id}`, { replace: true });
                    }, 3000);
                }
                else {
                    handleMessage({ type: 'danger', message: response.data.message, show: true });
                }
            }).catch((error) => {
                console.error(`Error: ${error}`);
            });
            //handleMessage({ type: 'success', message: "Thank you for providing data", show: true });
        }
        else {
            if (title.trim() === "") {
                // console.log("Please provide listing title!");
                handleMessage({ type: 'danger', message: "Please provide listing title!", show: true });
            }
            else if (streetAddress.trim() === "") {
                // console.log("Please provide road or street!");
                handleMessage({ type: 'danger', message: "Please provide road or street!", show: true });
            }
            else if (city.trim() === "") {
                // console.log("Please provide city!");
                handleMessage({ type: 'danger', message: "Please provide city!", show: true });
            }
            else if (state.trim() === "") {
                // console.log("Please provide state!");
                handleMessage({ type: 'danger', message: "Please provide state!", show: true });
            }
            else if (postalCode.trim() === "") {
                // console.log("Please provide postal code!");
                handleMessage({ type: 'danger', message: "Please provide postal code!", show: true });
            }
            else if (phone.trim() === "") {
                // console.log("Please provide phone number!");
                handleMessage({ type: 'danger', message: "Please provide phone number!", show: true });
            }
            else if (website.trim() === "") {
                // console.log("Please provide website!");
                handleMessage({ type: 'danger', message: "Please provide website!", show: true });
            }
            else if (treatmentTypePoints.trim() === "") {
                // console.log("Please provide treatment type!");
                handleMessage({ type: 'danger', message: "Please provide treatment type!", show: true });
            }
            else if (paymentInfoPoints.trim() === "") {
                // console.log("Please provide payment info!");
                handleMessage({ type: 'danger', message: "Please provide payment info!", show: true });
            }
            else if (specialityServicePoints.trim() === "") {
                // console.log("Please provide speciality service!");
                handleMessage({ type: 'danger', message: "Please provide speciality service!", show: true });
            }
            else if (address.trim() === "") {
                console.log("Please provide address!");
                handleMessage({ type: 'danger', message: "Please provide address!", show: true });
            }
            else if (paymentInfo.trim() === "") {
                // console.log("Please provide payemnt info!");
                handleMessage({ type: 'danger', message: "Please provide payemnt info summery!", show: true });
            }
            else if (keyPoints.trim() === "") {
                // console.log("Please provide key points!");
                handleMessage({ type: 'danger', message: "Please provide key points!", show: true });
            }
        }
    }

    // const sideBar = () => {
    //     return (
    //         <div id="demo" className="dashboard-sidebar ps z-50 w-64 transition-all bg-gray-800 dark:bg-foreground text-gray-100 fixed lg:left-0 top-0 -left-64 h-full ps--active-y" style={{}}>
    //             <a href="/" className="px-6 p-8 flex items-center">
    //                 <img className="w-7 h-7 mr-2" src="/img/OR-logo.webp" alt="" />
    //                 <p className="text-lg font-semibold">Opioid Rehab</p>
    //             </a>
    //             <div className="p-6 min-h-[85%]">
    //                 <ul className="mb-10">
    //                     {/* <p className="uppercase text-gray-500 mb-3 text-sm ml-4">pages</p> */}
    //                     <li className=" text-white flex items-center mb-2">
    //                         <a href="/dashboard-home.html" className="flex items-center hover:bg-gray-700 hover:text-blue-500 flex-1 py-2 px-3 rounded-lg">
    //                             <svg className="mr-2 w-5 h-5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                                 <path d="M7.14285 2C8.08928 2 8.85714 2.74618 8.85714 3.66667V9.22222C8.85714 10.1424 8.08928 10.8889 7.14285 10.8889H3.71428C2.7675 10.8889 2 10.1424 2 9.22222V3.66667C2 2.74618 2.7675 2 3.71428 2H7.14285ZM7.14285 13.1111C8.08928 13.1111 8.85714 13.8576 8.85714 14.7778V15.8889C8.85714 16.809 8.08928 17.5556 7.14285 17.5556H3.71428C2.7675 17.5556 2 16.809 2 15.8889V14.7778C2 13.8576 2.7675 13.1111 3.71428 13.1111H7.14285Z" fill="currentColor" />
    //                                 <path d="M16.2857 2C17.2321 2 18 2.74618 18 3.66667V4.77778C18 5.69792 17.2321 6.44444 16.2857 6.44444H12.8571C11.9107 6.44444 11.1429 5.69792 11.1429 4.77778V3.66667C11.1429 2.74618 11.9107 2 12.8571 2H16.2857ZM16.2857 8.66667C17.2321 8.66667 18 9.41319 18 10.3333V15.8889C18 16.809 17.2321 17.5556 16.2857 17.5556H12.8571C11.9107 17.5556 11.1429 16.809 11.1429 15.8889V10.3333C11.1429 9.41319 11.9107 8.66667 12.8571 8.66667H16.2857Z" fill="#B2DDFF" />
    //                             </svg>
    //                             Dashboard
    //                         </a>
    //                     </li>
    //                     <li className="flex items-center  text-white mb-2">
    //                         <a href="dashboard-all-listing.html" className="flex items-center flex-1 py-2 px-3 rounded-lg hover:bg-gray-700 hover:text-blue-500">
    //                             <svg className="mr-2 w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    //                                 {/*! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
    //                                 <path d="M152.1 38.16C161.9 47.03 162.7 62.2 153.8 72.06L81.84 152.1C77.43 156.9 71.21 159.8 64.63 159.1C58.05 160.2 51.69 157.6 47.03 152.1L7.029 112.1C-2.343 103.6-2.343 88.4 7.029 79.03C16.4 69.66 31.6 69.66 40.97 79.03L63.08 101.1L118.2 39.94C127 30.09 142.2 29.29 152.1 38.16V38.16zM152.1 198.2C161.9 207 162.7 222.2 153.8 232.1L81.84 312.1C77.43 316.9 71.21 319.8 64.63 319.1C58.05 320.2 51.69 317.6 47.03 312.1L7.029 272.1C-2.343 263.6-2.343 248.4 7.029 239C16.4 229.7 31.6 229.7 40.97 239L63.08 261.1L118.2 199.9C127 190.1 142.2 189.3 152.1 198.2V198.2zM224 96C224 78.33 238.3 64 256 64H480C497.7 64 512 78.33 512 96C512 113.7 497.7 128 480 128H256C238.3 128 224 113.7 224 96V96zM224 256C224 238.3 238.3 224 256 224H480C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H256C238.3 288 224 273.7 224 256zM160 416C160 398.3 174.3 384 192 384H480C497.7 384 512 398.3 512 416C512 433.7 497.7 448 480 448H192C174.3 448 160 433.7 160 416zM0 416C0 389.5 21.49 368 48 368C74.51 368 96 389.5 96 416C96 442.5 74.51 464 48 464C21.49 464 0 442.5 0 416z" fill="currentColor" />
    //                             </svg>
    //                             All Listing
    //                         </a>
    //                     </li>
    //                     <li className="flex items-center active  mb-2">
    //                         <a href="dashboard-addlisting-2.html" className="flex items-center flex-1 py-2 px-3 rounded-lg hover:bg-gray-700 hover:text-blue-500">
    //                             <svg className="mr-2 w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
    //                                 {/*! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
    //                                 <path d="M336 64h-53.88C268.9 26.8 233.7 0 192 0S115.1 26.8 101.9 64H48C21.5 64 0 85.48 0 112v352C0 490.5 21.5 512 48 512h288c26.5 0 48-21.48 48-48v-352C384 85.48 362.5 64 336 64zM192 64c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S160 113.7 160 96C160 78.33 174.3 64 192 64zM96 392c-13.25 0-24-10.75-24-24S82.75 344 96 344s24 10.75 24 24S109.3 392 96 392zM112 288C107.9 288 103.8 286.4 100.7 283.3l-32-32c-6.25-6.25-6.25-16.38 0-22.62s16.38-6.25 22.62 0L112 249.4l52.69-52.69c6.25-6.25 16.38-6.25 22.62 0s6.25 16.38 0 22.62l-64 64C120.2 286.4 116.1 288 112 288zM304 384h-128C167.2 384 160 376.8 160 368C160 359.2 167.2 352 176 352h128c8.801 0 16 7.199 16 16C320 376.8 312.8 384 304 384zM304 288h-96C199.2 288 192 280.8 192 272C192 263.2 199.2 256 208 256h96C312.8 256 320 263.2 320 272C320 280.8 312.8 288 304 288z" fill="currentColor" />
    //                             </svg>
    //                             Add Listing
    //                         </a>
    //                     </li>
    //                 </ul>
    //                 <ul className="mb-10">
    //                     <p className="uppercase text-gray-500 mb-3 text-sm ml-4">listing</p>
    //                     <li className="flex items-center  text-white mb-2">
    //                         <a href="dashboard-mylisting.html" className="flex items-center flex-1 py-2 px-3 rounded-lg hover:bg-gray-700 hover:text-blue-500">
    //                             <svg className="mr-2 w-5 h-5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                                 <path d="M9.26598 2.16147C9.73163 1.94618 10.2692 1.94618 10.7348 2.16147L17.5664 5.31891C17.832 5.44079 18.0008 5.70642 18.0008 5.97206C18.0008 6.29083 17.832 6.55647 17.5664 6.68147L10.7348 9.83788C10.2692 10.0535 9.73163 10.0535 9.26598 9.83788L2.43533 6.68147C2.16995 6.55647 2 6.29083 2 5.97206C2 5.70642 2.16991 5.44079 2.43533 5.31891L9.26598 2.16147ZM17.5664 13.3193C17.832 13.4412 18.0008 13.7068 18.0008 13.9725C18.0008 14.2912 17.832 14.5569 17.5664 14.6819L10.7348 17.8383C10.2692 18.0539 9.73163 18.0539 9.26598 17.8383L2.43533 14.6819C2.16995 14.5569 2 14.2912 2 13.9725C2 13.7068 2.16991 13.4412 2.43533 13.3193L4.09792 12.5505L8.84721 14.7444C9.5785 15.085 10.4223 15.085 11.1536 14.7444L15.9038 12.5505L17.5664 13.3193Z" fill="currentColor" />
    //                                 <path d="M11.1536 10.7442L15.9038 8.55032L17.5664 9.31911C17.832 9.44099 18.0008 9.70663 18.0008 9.97227C18.0008 10.291 17.832 10.5567 17.5664 10.6817L10.7348 13.8381C10.2692 14.0537 9.73163 14.0537 9.26598 13.8381L2.43533 10.6817C2.16995 10.5567 2 10.291 2 9.97227C2 9.70663 2.16991 9.44099 2.43533 9.31911L4.09792 8.55032L8.84721 10.7442C9.5785 11.0848 10.4223 11.0848 11.1536 10.7442Z" fill="#D0D5DD" />
    //                             </svg>
    //                             My Listing
    //                         </a>
    //                     </li>
    //                     <li className="flex items-center  text-white mb-2">
    //                         <a href="dashboard-reviews.html" className="flex items-center flex-1 py-2 px-3 rounded-lg hover:bg-gray-700 hover:text-blue-500">
    //                             <svg className="mr-2 w-5 h-5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                                 <path d="M9.99042 15.7432L6.10882 17.8835C5.78183 18.0616 5.38519 18.0335 5.08544 17.8117C4.78569 17.5898 4.63431 17.2086 4.69789 16.8337L5.44272 12.253L2.28718 9.05967C2.02649 8.79096 1.93384 8.39726 2.04829 8.03481C2.16244 7.67548 2.46431 7.41301 2.82734 7.3599L7.1746 6.69748L9.12448 2.56271C9.28495 2.21979 9.62103 2.00163 9.96317 2L9.99042 15.7432ZM14.3867 17.9991C14.3534 18.0023 14.3201 18.0023 14.2868 17.9991H14.3867Z" fill="currentColor" />
    //                                 <path d="M5.70004 17.9991C5.66976 18.0023 5.63645 18.0023 5.60315 17.9991H5.70004ZM9.99037 15.7432V2.00001L9.9934 2C10.3658 2 10.7049 2.21851 10.8654 2.56271L12.8122 6.69748L17.1631 7.3599C17.5234 7.41301 17.8262 7.67548 17.9413 8.03481C18.0563 8.39726 17.9625 8.79096 17.7021 9.05967L14.5471 12.253L15.292 16.8337C15.3525 17.2086 15.2011 17.5898 14.9014 17.8117C14.6016 18.0335 14.205 18.0616 13.881 17.8835L9.9934 15.7432H9.99037Z" fill="#D0D5DD" />
    //                             </svg>
    //                             Reviews
    //                         </a>
    //                     </li>
    //                 </ul>
    //                 <ul className="mb-10">
    //                     <p className="uppercase text-gray-500 mb-3 text-sm ml-4">account</p>
    //                     <li className="flex items-center  text-white mb-2">
    //                         <a href="dashboard-myprofile.html" className="flex items-center flex-1 py-2 px-3 rounded-lg hover:bg-gray-700 hover:text-blue-500">
    //                             <svg className="mr-2 w-5 h-5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                                 <path d="M11.8296 11.6H8.57036C5.49461 11.6 3 13.988 3 16.9331C3 17.5221 3.49886 18 4.11407 18H16.2866C16.9018 18.0009 17.4 17.5239 17.4 16.9331C17.4 13.988 14.9057 11.6 11.8296 11.6Z" fill="currentColor" />
    //                                 <path d="M14.2 6C14.2 8.20906 12.4094 10 10.2 10C7.99064 10 6.20001 8.20937 6.20001 6C6.20001 3.79063 7.99095 2 10.2 2C12.4094 2 14.2 3.79094 14.2 6Z" fill="#D0D5DD" />
    //                             </svg>
    //                             My Profile
    //                         </a>
    //                     </li>
    //                     <li className="flex items-center  text-white mb-2">
    //                         <div className="flex items-center flex-1 py-2 px-3 rounded-lg hover:bg-gray-700 hover:text-blue-500" onClick={()=>logout()}>
    //                             <svg className="mr-2 w-5 h-5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                                 <path d="M18.7545 10.6395L13.8409 15.7537C13.6031 16.0011 13.2544 16.0687 12.9543 15.9265C12.6542 15.7837 12.4596 15.4576 12.4596 15.0973L12.4564 12.4236L8.09183 12.4236C7.48863 12.4236 7 11.8847 7 11.2202V8.81353C7 8.14906 7.48897 7.61018 8.09183 7.61018L12.4564 7.61022L12.4596 4.90268C12.4596 4.5425 12.6542 4.21639 12.9543 4.07349C13.2545 3.93131 13.6032 3.99889 13.8409 4.24625L18.7545 9.36049C19.0818 9.66554 19.0818 10.2973 18.7545 10.6395Z" fill="currentColor" />
    //                                 <path d="M4.5 18H6.83333C7.47865 18 8 17.4893 8 16.8571C8 16.225 7.47865 15.7143 6.83333 15.7143H4.5C3.85578 15.7143 3.33333 15.2025 3.33333 14.5714V5.42857C3.33333 4.7975 3.85578 4.28571 4.5 4.28571H6.83333C7.47865 4.28571 8 3.77393 8 3.14286C8 2.51179 7.47865 2 6.83333 2H4.5C2.56698 2 1 3.535 1 5.42857V14.5714C1 16.4643 2.56698 18 4.5 18Z" fill="#D0D5DD" />
    //                             </svg>
    //                             logout
    //                         </div>
    //                     </li>
    //                 </ul>
    //             </div>
    //             <div className="p-6 mt-auto">
    //                 <div className="flex items-center">
    //                     <img className="w-10 h-10 rounded-full border border-white" src="/img/faces/2.jpg" alt="" />
    //                     <div className="ml-2">
    //                         <h6 className="text-base font-semibold">Gage Paquette</h6>
    //                         <p className="text-sm font-normal text-gray-300">gagep@accommo.com</p>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="ps__rail-x" style={{ left: '0px', bottom: '0px' }}>
    //                 <div className="ps__thumb-x" tabIndex={0} style={{ left: '0px', width: '0px' }} />
    //             </div>
    //             <div className="ps__rail-y" style={{ top: '0px', height: '343px', right: '0px' }}>
    //                 <div className="ps__thumb-y" tabIndex={0} style={{ top: '0px', height: '154px' }} />
    //             </div>
    //         </div>
    //     )
    // }

    // const header = () => {
    //     return (
    //         <div className="fixed lg:left-64 left-0 z-30 shadow-front-2 bg-white h-20 lg:w-[calc(100%-256px)] w-full px-7 py-4 flex flex-col justify-center dark:bg-foreground dark:text-gray-200 top-0" style={{}}>
    //             <div className="flex justify-between">
    //                 <div className="flex items-center">
    //                     <button type="button" className="mr-3 lg:hidden block dashboard-header-btn">
    //                         <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
    //                             <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
    //                         </svg>
    //                     </button>
    //                 </div>
    //                 <div className="flex items-center">
    //                     <div className="dropdown mr-3" data-dropdown>
    //                         <button className="link hover:text-blue-500 flex items-center" data-dropdown-button>
    //                             <div className="relative lg:mr-3 mr-0">
    //                                 <img className="w-8 h-8 rounded-full border border-white" src="/img/faces/10.jpg" data-dropdown-button alt="" />
    //                                 <div className="w-2 h-2 bg-green-500 rounded-full border absolute bottom-0 right-0" />
    //                             </div>
    //                             <span data-dropdown-button className="lg:block hidden">My Account</span>
    //                             <svg className="w-3 h-auto ml-2" width={10} height={6} data-dropdown-button viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                                 <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74712 0.525274 9.25335 0.525274 9.56583 0.826613C9.87831 1.12795 9.87831 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
    //                             </svg>
    //                         </button>
    //                         <div className="dropdown-menu bg-white dark:bg-foreground dark:text-gray-300 text-gray-700">
    //                             <div>
    //                                 <div className="dropdown-links max-w-xs w-48 rounded-md py-3">
    //                                     <a href="listing-list.html" className="link hover:text-blue-500 px-4 py-1 flex items-center text-sm text-gray-700 dark:text-gray-300"><svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
    //                                         <path strokeLinecap="round" strokeLinejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" /></svg>Account
    //                                     </a>
    //                                     <a href="listing-list.html" className="link hover:text-blue-500 px-4 py-1 flex items-center text-sm text-gray-700 dark:text-gray-300">
    //                                         <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
    //                                             <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
    //                                             <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
    //                                         </svg>
    //                                         Order Tracking
    //                                     </a>
    //                                     <a href="listing-list.html" className="link hover:text-blue-500 px-4 py-1 flex items-center text-sm text-gray-700 dark:text-gray-300">
    //                                         <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
    //                                             <path strokeLinecap="round" strokeLinejoin="round" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
    //                                         </svg>
    //                                         My Voucher
    //                                     </a>
    //                                     <a href="listing-list.html" className="link hover:text-blue-500 px-4 py-1 flex items-center text-sm text-gray-700 dark:text-gray-300">
    //                                         <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
    //                                             <path strokeLinecap="round" strokeLinejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
    //                                         </svg>
    //                                         My Wishlist
    //                                     </a>
    //                                     <a href="listing-list.html" className="link hover:text-blue-500 px-4 py-1 flex items-center text-sm text-gray-700 dark:text-gray-300">
    //                                         <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
    //                                             <path strokeLinecap="round" strokeLinejoin="round" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
    //                                         </svg>
    //                                         Settings
    //                                     </a>
    //                                     <div className="link hover:text-blue-500 px-4 py-1 flex items-center text-sm text-gray-700 dark:text-gray-300" style={{cursor: "pointer"}} onClick={()=>logout()}>
    //                                         <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
    //                                             <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
    //                                         </svg>
    //                                         Sign Out
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <button type="button" className="inline-flex items-center px-3 py-2 rounded-lg border border-gray-200 text-gray-700 dark:text-gray-300">
    //                         <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 lg:mr-2 mr-0" viewBox="0 0 20 20" fill="currentColor">
    //                             <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" /></svg><span className="lg:flex hidden">Add Listing</span>
    //                     </button>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    return (
        <>
            {/* {sideBar()}
            {header()} */}
            <AdminSideBar />
            <AdminHeader />

            <div className="dark:bg-background lg:ml-64 ml-0 mt-20 min-h-[calc(100vh-80px)] p-7">
                <section className="dark:bg-background">
                    <div className="container mx-auto">
                        <div className="flex text-gray-400 text-sm items-center mb-4">
                            {/* {
                                (getCookie("token") !== null && base64_decode(getCookie("token")).split("|")[3] === "admin")
                                    ?
                                    (<Link to={"/admin/dashboard"} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                                    :
                                    (<Link to={"/admin/add_listing"} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                            } */}
                            {
                                getCookie("token") !== null && (<Link to={roleBasedBreadCrumbUrl(base64_decode(getCookie("token")).split("|")[3])} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                            }
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 mx-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                            </svg>
                            <span>Add Listing</span>
                        </div>
                        <Alert type={alert.type} message={alert.message} show={alert.show} />
                        <div className="bg-white shadow-front-2 dark:bg-foreground rounded-lg p-6 mb-7">
                            <h4 className="text-gray-700 dark:text-gray-300 text-xl font-semibold mb-3">
                                Basic Information
                            </h4>
                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Listing Title <sup className="text-red-500">*</sup></label>
                                    <input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="Type a title" value={title} onChange={(e) => setTitle(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="bg-white shadow-front-2 dark:bg-foreground rounded-lg p-6 mb-7">
                            <h4 className="text-gray-700 dark:text-gray-300 text-xl font-semibold mb-3">
                                Locations
                            </h4>
                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                <div className="lg:col-span-2 col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Address <sup className="text-red-500">*</sup></label>
                                    <input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. Bloosom" value={address} onChange={(e) => setAddress(e.target.value)} />
                                </div>
                                <div className="lg:col-span-1 col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Road or Street <sup className="text-red-500">*</sup></label>
                                    <input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. 12 San Pedro Street" value={streetAddress} onChange={(e) => setStreetAddress(e.target.value)} />
                                </div>
                                <div className="lg:col-span-1 col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">City<sup className="text-red-500">*</sup></label>
                                    <input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. 12 San Pedro Street" value={city} onChange={(e) => setCity(e.target.value)} />
                                </div>
                                <div className="lg:col-span-1 col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">State<sup className="text-red-500">*</sup></label>
                                    <div className="relative mb-3">
                                        <select name="state" className="bg-white w-full border border-gray-200 dark:bg-foreground dark:border-gray-800 relative appearance-none rounded-lg px-4 py-[0.969rem] focus:outline-blue-300 text-gray-400 focus:outline-none" value={state} onChange={(e) => setState(e.target.value)}>
                                            <option value="-1">Select State</option>
                                            <option value="AL">Alabama, AL</option>
                                            <option value="AK">Alaska, AK</option>
                                            <option value="AZ">Arizona, AZ</option>
                                            <option value="AR">Arkansas, AR</option>
                                            <option value="CA">California, CA</option>
                                            <option value="CO">Colorado, CO</option>
                                            <option value="CT">Connecticut, CT</option>
                                            <option value="DE">Delaware, DE</option>
                                            <option value="DC">District Of Columbia, DC</option>
                                            <option value="FL">Florida, FL</option>
                                            <option value="GA">Georgia, GA</option>
                                            <option value="HI">Hawaii, HI</option>
                                            <option value="ID">Idaho, ID</option>
                                            <option value="IL">Illinois, IL</option>
                                            <option value="IN">Indiana, IN</option>
                                            <option value="IA">Iowa, IA</option>
                                            <option value="KS">Kansas, KS</option>
                                            <option value="KY">Kentucky, KY</option>
                                            <option value="LA">Louisiana, LA</option>
                                            <option value="ME">Maine, ME</option>
                                            <option value="MD">Maryland, MD</option>
                                            <option value="MA">Massachusetts, MA</option>
                                            <option value="MI">Michigan, MI</option>
                                            <option value="MN">Minnesota, MN</option>
                                            <option value="MS">Mississippi, MS</option>
                                            <option value="MO">Missouri, MO</option>
                                            <option value="MT">Montana, MT</option>
                                            <option value="NE">Nebraska, NE</option>
                                            <option value="NV">Nevada, NV</option>
                                            <option value="NH">New Hampshire, NH</option>
                                            <option value="NJ">New Jersey, NJ</option>
                                            <option value="NM">New Mexico, NM</option>
                                            <option value="NY">New York, NY</option>
                                            <option value="NC">North Carolina, NC</option>
                                            <option value="ND">North Dakota, ND</option>
                                            <option value="OH">Ohio, OH</option>
                                            <option value="OK">Oklahoma, OK</option>
                                            <option value="OR">Oregon, OR</option>
                                            <option value="PA">Pennsylvania, PA</option>
                                            <option value="RI">Rhode Island, RI</option>
                                            <option value="SC">South Carolina, SC</option>
                                            <option value="SD">South Dakota, SD</option>
                                            <option value="TN">Tennessee, TN</option>
                                            <option value="TX">Texas, TX</option>
                                            <option value="UT">Utah, UT</option>
                                            <option value="VT">Vermont, VT</option>
                                            <option value="VA">Virginia, VA</option>
                                            <option value="WA">Washington, WA</option>
                                            <option value="WV">West Virginia, WV</option>
                                            <option value="WI">Wisconsin, WI</option>
                                            <option value="WY">Wyoming, WY</option>
                                        </select>
                                        <svg width={10} height={6} className="w-3 h-3 text-gray-400 absolute right-3 top-1/2 -translate-y-1/2 z-10" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74713 0.525274 9.25335 0.525274 9.56583 0.826613C9.87832 1.12795 9.87832 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="lg:col-span-1 col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Zip Code<sup className="text-red-500">*</sup></label>
                                    <input type="number" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. ACB1258" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="bg-white shadow-front-2 dark:bg-foreground rounded-lg p-6 mb-7">
                            <h4 className="text-gray-700 dark:text-gray-300 text-xl font-semibold mb-3">
                                Details
                            </h4>
                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Descriptions <sup className="text-red-500">*</sup></label>
                                    <textarea className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" rows={10} placeholder="Write your listing details here....." value={description} onChange={(e) => setDescription(e.target.value)} />
                                </div>
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Payment Info Summery<sup className="text-red-500">*</sup></label>
                                    <textarea className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" rows={4} placeholder="Write your payment info here....." value={paymentInfo} onChange={(e) => setPaymentInfo(e.target.value)} />
                                </div>
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Key Points <sup className="text-red-500">*</sup></label>
                                    <textarea className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" rows={4} placeholder="Write your listing key points here....." value={keyPoints} onChange={(e) => setKeyPoints(e.target.value)} />
                                </div>
                                <div className="lg:col-span-1 col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Phone</label>
                                    <input type="phone" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. +1234567890" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                </div>
                                <div className="lg:col-span-1 col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Website</label>
                                    <input type="url" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. https://opioid-rehab.com/" value={website} onChange={(e) => setWebsite(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="bg-white shadow-front-2 dark:bg-foreground rounded-lg p-6 mb-7">
                            <h4 className="text-gray-700 dark:text-gray-300 text-xl font-semibold mb-3">
                                Media and Attachment
                            </h4>
                            <div>
                                <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Photos or Images <sup className="text-red-500">*</sup></label>
                                <label htmlFor="upload-img" className="flex flex-col justify-center items-center cursor-pointer bg-gray-100 dark:bg-background dark:border-gray-800 border border-gray-200 rounded-lg py-10">
                                    <svg className="mb-2" width={43} height={42} viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_780_19100)">
                                            <path d="M9.95 35C4.73084 35 0.5 30.9688 0.5 26C0.5 22.075 3.13616 18.7375 6.81312 17.5063C6.80459 17.3375 6.8 17.1687 6.8 17C6.8 11.475 11.4987 7 17.3 7C21.1916 7 24.5844 9.01562 26.4022 12.0125C27.3997 11.3187 28.6072 11 29.9 11C33.3781 11 36.2 13.6312 36.2 17C36.2 17.7625 36.0491 18.4875 35.78 19.1625C39.6125 19.9 42.5 23.1313 42.5 27C42.5 31.4188 38.7397 35 34.1 35H9.95ZM15.1344 21.4375C14.5241 22.025 14.5241 22.975 15.1344 23.5063C15.7512 24.1438 16.7487 24.1438 17.3066 23.5063L19.925 21.1187V29.5C19.925 30.3312 20.6272 31 21.5 31C22.3728 31 23.075 30.3312 23.075 29.5V21.1187L25.6344 23.5063C26.2512 24.1438 27.2487 24.1438 27.8066 23.5063C28.4759 22.975 28.4759 22.025 27.8066 21.4375L22.5566 16.4375C21.9987 15.8562 21.0012 15.8562 20.3844 16.4375L15.1344 21.4375Z" fill="#98A2B3" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_780_19100">
                                                <rect width={42} height={42} fill="white" transform="translate(0.5)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <h6 className="text-base font-medium text-gray-700 dark:text-gray-400 mb-4">
                                        Drag and Drop Images
                                    </h6>
                                    <div className="text-base text-gray-700 dark:text-gray-400 mb-4">OR</div>
                                    <div className="bg-blue-500 text-white p-4 rounded-lg">Browse Images</div>
                                    <input type="file" id="upload-img" className="hidden" onChange={changeHandler} accept="image/x-png,image/gif,image/jpeg" multiple />
                                </label>
                            </div>
                            <div style={{ display: "flex", overflow: "overlay", paddingBottom: "30px", paddingLeft: "8px" }}>
                                {images.map((image, index) => (
                                    <div className="mt-2 p-4 mr-2 card" style={{ display: "flex", alignItems: "center", minHeight: "100px" }} key={index}>
                                        <img style={{ maxWidth: "120px" }} src={image["base64"]} />
                                        <i className="fa fa-window-close ml-2" style={{ color: "red", marginBottom: "80px" }} onClick={() => removeImage(index)}></i>
                                    </div>
                                ))}

                            </div>
                        </div>
                        <div className="bg-white shadow-front-2 dark:bg-foreground rounded-lg p-6 mb-7">
                            <h4 className="text-gray-700 dark:text-gray-300 text-xl font-semibold mb-3">
                                Features Details
                            </h4>
                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Treatment Type<sup className="text-red-500">*</sup></label>
                                    <textarea className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" rows={5} placeholder="Write your Treatment Type Points here....." value={treatmentTypePoints} onChange={(e) => setTreatmentTypePoints(e.target.value)} />
                                </div>
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Payment Info <sup className="text-red-500">*</sup></label>
                                    <textarea className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" rows={5} placeholder="Write your Payment Info Points here....." value={paymentInfoPoints} onChange={(e) => setPaymentInfoPoints(e.target.value)} />
                                </div>
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Speciality Service <sup className="text-red-500">*</sup></label>
                                    <textarea className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" rows={5} placeholder="Write your Specility Service Points here....." value={specialityServicePoints} onChange={(e) => setSpecialityServicePoints(e.target.value)} />
                                </div>
                                <div className="lg:col-span-1 col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Rating <sup className="text-red-500">*</sup></label>
                                    <input type="number" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. 4" value={rating} onChange={(e) => setRating(e.target.value)} />
                                </div>
                                <div className="lg:col-span-1 col-span-2">
                                    {/* <div className="flex justify-between items-center mb-7">
                                        <h4 className="text-gray-700 dark:text-gray-300 text-xl font-semibold">
                                            Features
                                        </h4>
                                        <input type="checkbox" name="" className="custom-switch" />
                                    </div> */}
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Activate Listing</label>
                                    <div className="mt-5" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <input type="checkbox" className="custom-switch" checked={Number(activeteListing) === 1 ? true : false} onChange={(e) => setActivateListing(e.target.checked)} />
                                        <label className="text-base text-gray-700 dark:text-gray-200 ml-5">{Number(activeteListing) === 1 ? "Active" : "De-Active"}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap sm:justify-start justify-center">
                            <button type="button" className="px-6 py-5 bg-blue-500 hover:bg-blue-600 shadow-glow-blue text-white rounded-lg mr-4 mb-4" onClick={() => submitListing()}>
                                Submit List
                            </button>
                            <button type="button" className="px-6 py-[17px] mb-4 border border-gray-200 dark:border-gray-800 text-gray-500 dark:text-gray-200 rounded-lg mr-4" onClick={() => clearFields()}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default AddListing