import React, { useState } from 'react'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PageContentContainer from '../../components/PageContentContainer';

import ScrollToTopButton from '../../components/ScrollToTopButton';

//import businesses_list from "../../../business";
import FeaturedListing from '../../components/FeaturedListing';
import RehabSearchForm from '../../components/RehabSearchForm';

const EffectsOfOpioidAddiction = () => {

  const [tabToShow, setTabToShow] = useState("Constipation");
  const [tabLongTermToShow, setLongTermTabToShow] = useState("Death");

  const onBrain = () => {
    return (
      <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7">
        <div className="p-6 border-b border-gray-200 dark:border-gray-800">
          <div className="bg-blue-500 text-white font-medium mr-2 lg:text-3xl text-2xl p-3.5" style={{ marginRight: "0px" }}>
            <div className="text-center">Opioid Side Effects on the Brain</div>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-7 my-5'>
            <div className='grid-cols-1'>
              <h5 className="text-gray-700 dark:text-gray-100" style={{ fontSize: "2.3rem", lineHeight: "2.4rem" }}>
                Short-Term Mental Effects
              </h5>

              <p className="text-gray-500 text-base dark:text-gray-300 mb-5">The effect of opioid addiction can vary based on the severity of the addiction and what type of opioid is used.</p>

              <p className="text-gray-500 text-base dark:text-gray-300 mb-5 mb-20">Studies have also shown significant size changes in several critical areas of the brain, even when clients take opioids as prescribed. These changes remained even months after clients discontinued opioids.</p>
            </div>
          </div>

          <h4 className="text-gray-700 font-semibold dark:text-gray-100 text-center my-4" style={{ fontSize: "2rem", lineHeight: "2.1rem" }}>
            Common Mental Effects
          </h4>

          <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-7 mb-20">
            <div className="relative group overflow-hidden rounded-lg text-center">
              <h5 className="text-gray-700 font-semibold dark:text-gray-100 my-4" style={{ fontSize: "1.5rem", lineHeight: "1.7rem" }}>
                Euphoria
              </h5>
            </div>
            <div className="relative group overflow-hidden rounded-lg text-center">
              <h5 className="text-gray-700 font-semibold dark:text-gray-100 my-4" style={{ fontSize: "1.5rem", lineHeight: "1.7rem" }}>
                Pain-Relief
              </h5>
            </div>
            <div className="relative group overflow-hidden rounded-lg text-center">
              <h5 className="text-gray-700 font-semibold dark:text-gray-100 my-4" style={{ fontSize: "1.5rem", lineHeight: "1.7rem" }}>
                Confusion
              </h5>
            </div>
            <div className="relative group overflow-hidden rounded-lg text-center">
              <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 my-4">
                Drowsiness
              </h5>
            </div>
          </div>


          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-7 my-5'>
            <div className='grid-cols-1'></div>
            <div className='grid-cols-1'>
              <h5 className="text-gray-700 dark:text-gray-100 my-5" style={{ fontSize: "2.3rem", lineHeight: "2.4rem" }}>
                Long-Term Mental Effects
              </h5>

              <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Opioids lead to <a className='text-blue-500 hover:text-blue-500' href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2851054/">drastic changes</a> to the way the brain functions and how it causes a dependence to be built. Over time, the frequent use of opioids leads to neurological changes that alters the brain’s reward system. This promotes continued drug use, forming a cyclical problem that creates and feeds addiction.</p>

              <p className="text-gray-500 text-base dark:text-gray-300 mb-5 mb-20">Repeated exposure to escalating doses of opioids causes the brain to function normally when drugs are present and abnormally when they are not.</p>
            </div>
          </div>

          <h4 className="text-gray-700 font-semibold dark:text-gray-100 text-center my-4" style={{ fontSize: "2rem", lineHeight: "2.1rem" }}>
            Opioid users exhibit a higher rate of:
          </h4>

          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-7 mb-10">
            <div className="relative group overflow-hidden rounded-lg text-center">
              <h5 className="text-gray-700 font-semibold dark:text-gray-100 my-4" style={{ fontSize: "1.5rem", lineHeight: "1.7rem" }}>
                Anxiety
              </h5>
            </div>
            <div className="relative group overflow-hidden rounded-lg text-center">
              <h5 className="text-gray-700 font-semibold dark:text-gray-100 my-4" style={{ fontSize: "1.5rem", lineHeight: "1.7rem" }}>
                Depression
              </h5>
            </div>
            <div className="relative group overflow-hidden rounded-lg text-center">
              <h5 className="text-gray-700 font-semibold dark:text-gray-100 my-4" style={{ fontSize: "1.5rem", lineHeight: "1.7rem" }}>
                Bipolar
              </h5>
            </div>
          </div>

          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-7 my-5'>
            <div className='grid-cols-1'>
              <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Opioid users are <a className='text-blue-500 hover:text-blue-500' href="https://newsroom.heart.org/news/alarming-number-of-heart-infections-tied-to-opioid-epidemic">10 times more likely</a> to use inpatient mental health services and those with a dual diagnosis (co-occurring mental health disorder) have significantly higher amounts of emergency room visits.</p>

              <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Overall, there are a number of problematic opioid side effects that can change and alter the brain; however, the problem doesn’t stop there. Opioids can have a major effect on the way the body operates as well.</p>
            </div>
          </div>

        </div>
      </div>
    )
  }


  const onBody = () => {
    return (
      <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7">
        <div className="p-6 border-b border-gray-200 dark:border-gray-800">
          <div className="bg-blue-500 text-white font-medium mr-2 lg:text-3xl text-2xl p-3.5" style={{ marginRight: "0px" }}>
            <div className="text-center">Opioid Side Effects on the Body</div>
          </div>

          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-7 my-5'>
            <div className='grid-cols-1'>
              <h5 className="text-gray-700 dark:text-gray-100 my-5" style={{ fontSize: "2.3rem", lineHeight: "2.4rem" }}>
                Short-Term Physical Effects
              </h5>

              <p className="text-gray-500 text-base dark:text-gray-300 mb-20">Opioid abuse has numerous side effects on the body like slowed breathing, sweating, muscle aches, chills, and GI bleeding. Many of the serious effects are capable of setting in shortly after taking the drugs.</p>
            </div>
          </div>

          <h4 className="text-gray-700 font-semibold dark:text-gray-100 text-center my-4" style={{ fontSize: "2rem", lineHeight: "2.1rem" }}>
            Most Common Physical Effects
          </h4>

          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-7">

            <div className="relative group overflow-hidden rounded-lg text-center" onClick={() => setTabToShow("Constipation")}>
              <h5 className={(tabToShow !== "" && tabToShow === "Constipation") ? "border border-gray-800 dark:border-gray-800 text-gray-700 lg:text-xl text-xl font-semibold dark:text-gray-100 my-4" : "text-gray-700 lg:text-xl text-xl font-semibold dark:text-gray-100 my-4"} style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", paddingBottom: "10px" }}>
                Constipation
              </h5>
            </div>
            <div className="relative group overflow-hidden rounded-lg text-center" onClick={() => setTabToShow("Vomiting")}>
              <h5 className={(tabToShow !== "" && tabToShow === "Vomiting") ? "border border-gray-800 dark:border-gray-800 text-gray-700 lg:text-xl text-xl font-semibold dark:text-gray-100 my-4" : "text-gray-700 lg:text-xl text-xl font-semibold dark:text-gray-100 my-4"} style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", paddingBottom: "10px" }}>
                Vomiting
              </h5>
            </div>
            <div className="relative group overflow-hidden rounded-lg text-center" onClick={() => setTabToShow("Hypoxia")}>
              <h5 className={(tabToShow !== "" && tabToShow === "Hypoxia") ? "border border-gray-800 dark:border-gray-800 text-gray-700 lg:text-xl text-xl font-semibold dark:text-gray-100 my-4" : "text-gray-700 lg:text-xl text-xl font-semibold dark:text-gray-100 my-4"} style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", paddingBottom: "10px" }}>
                Hypoxia
              </h5>
            </div>

            {/* <div className="relative group overflow-hidden rounded-lg text-center">
              <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 my-4">
                Constipation
              </h5>
            </div>
            <div className="relative group overflow-hidden rounded-lg text-center">
              <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 my-4">
                Vomiting
              </h5>
            </div>
            <div className="relative group overflow-hidden rounded-lg text-center">
              <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 my-4">
                Hypoxia
              </h5>
            </div> */}
          </div>

          {tabToShow !== "" && (
            <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-20">

              {tabToShow === "Constipation" && (<div className="p-6 border border-gray-800 dark:border-gray-800">
                <p className="text-gray-500 text-base dark:text-gray-300 mb-5 mb-5">Constipation is a problem that frequently happens when taking opioid medications. It is something that occurs in 45% to 90% of users.</p>
              </div>)}

              {tabToShow === "Vomiting" && (<div className="p-6 border border-gray-800 dark:border-gray-800">
                <p className="text-gray-500 text-base dark:text-gray-300 mb-5 mb-5">Stomach issues like nausea and vomiting can be common side effects of opioid use and occurs in about 25% of users.</p>
              </div>)}

              {tabToShow === "Hypoxia" && (<div className="p-6 border border-gray-800 dark:border-gray-800">
                <p className="text-gray-500 text-base dark:text-gray-300 mb-5 mb-5">Opioid misuse can cause a respiratory condition called Hypoxia, a problem that occurs when too little oxygen reaches the brain. The condition doesn’t allow enough oxygen in the tissues to sustain bodily function. Hypoxia itself can lead to both short-term and long-term problems including permanent brain damage, coma, and death.</p>
              </div>)}
            </div>)}

          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-7 my-5'>
            <div className='grid-cols-1'></div>
            <div className='grid-cols-1'>
              <h5 className="text-gray-700 dark:text-gray-100 my-5" style={{ fontSize: "2.3rem", lineHeight: "2.4rem" }}>
                Long-Term Physical Effects
              </h5>

              <p className="text-gray-500 text-base dark:text-gray-300 mb-5">The most serious long-term physical effects of opioids occur when users switch from oral opioids to injectable opioids, like heroin. More than 80% of injectable opioid users started with prescribed opioid pills.</p>

              <p className="text-gray-500 text-base dark:text-gray-300 mb-5 mb-20">One of the most dangerous potential side effects of opioid abuse; however, is an overdose. </p>
            </div>
          </div>

          <h4 className="text-gray-700 font-semibold dark:text-gray-100 text-center my-4" style={{ fontSize: "2rem", lineHeight: "2.1rem" }}>
            Severe Long-Term Effects
          </h4>

          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-7">
            <div className="relative group overflow-hidden rounded-lg text-center" onClick={() => setLongTermTabToShow("Death")}>
              <h5 className={(tabLongTermToShow !== "" && tabLongTermToShow === "Death") ? "border border-gray-800 dark:border-gray-800 text-gray-700 lg:text-xl text-xl font-semibold dark:text-gray-100 my-4" : "text-gray-700 lg:text-xl text-xl font-semibold dark:text-gray-100 my-4"} style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", paddingBottom: "10px" }}>
                Death
              </h5>
            </div>
            <div className="relative group overflow-hidden rounded-lg text-center" onClick={() => setLongTermTabToShow("Infectious-Endocarditis")}>
              <h5 className={(tabLongTermToShow !== "" && tabLongTermToShow === "Infectious-Endocarditis") ? "border border-gray-800 dark:border-gray-800 text-gray-700 lg:text-xl text-xl font-semibold dark:text-gray-100 my-4" : "text-gray-700 lg:text-xl text-xl font-semibold dark:text-gray-100 my-4"} style={{ height: "100%", width: "100%" }}>
                Infectious Endocarditis
              </h5>
            </div>
            <div className="relative group overflow-hidden rounded-lg text-center" onClick={() => setLongTermTabToShow("Hepatitis-C")}>
              <h5 className={(tabLongTermToShow !== "" && tabLongTermToShow === "Hepatitis-C") ? "border border-gray-800 dark:border-gray-800 text-gray-700 lg:text-xl text-xl font-semibold dark:text-gray-100 my-4" : "text-gray-700 lg:text-xl text-xl font-semibold dark:text-gray-100 my-4"} style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", paddingBottom: "10px" }}>
                Hepatitis C
              </h5>
            </div>
          </div>

          {tabLongTermToShow !== "" && (
            <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7">

              {tabLongTermToShow === "Death" && (<div className="p-6 border border-gray-800 dark:border-gray-800">
                <p className="text-gray-500 text-base dark:text-gray-300 mb-5 mb-5">“Opioids account for more deaths than any other medication.”</p>
                <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Typically, opioids increase the risk of death through other causes including: accidents due to impairment, overdose (especially when using benzodiazepines simultaneously), and contracting infectious diseases through intravenous drug use.</p>
              </div>)}

              {tabLongTermToShow === "Infectious-Endocarditis" && (<div className="p-6 border border-gray-800 dark:border-gray-800">
                <p className="text-gray-500 text-base dark:text-gray-300 mb-5 mb-5">Infectious endocarditis is a life-threatening infection of the heart. While previously seen more frequently in elderly adults, the infection has been on the rise in young people who use injectable opioids, such as heroin.</p>
                <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Nearly 34,000 people are treated for this problem each year and 20% die from the infection. For those who do survive, they are often left with lasting cardiovascular issues.</p>
              </div>)}

              {tabLongTermToShow === "Hepatitis-C" && (<div className="p-6 border border-gray-800 dark:border-gray-800">
                <p className="text-gray-500 text-base dark:text-gray-300 mb-5 mb-5">With so many users taking injectable opioids, the risk of bloodborne infections is serious. Over the last decade the Center for Disease Control and Prevention (CDC) has reported a 350% increase in new Hepatitis C diagnosis. Many of these occur because of the opioid epidemic.</p>
              </div>)}

            </div>
          )}

        </div>
      </div>
    )
  }

  return (
    <>
      <Header />

      <PageContentContainer>

        <>
          <section className="dark:bg-background mb-12">
            <div className="relative">
              <img className="w-full object-cover h-52" src="/img/cover-2.png" alt="" />
              <div className="absolute top-1/2 w-full -translate-y-1/2 text-center mb-4">
                <h1 className="capitalize text-4xl text-white font-semibold">Effect Of Opioid Addiction</h1>
                <p className="text-white mt-2">Guide to Opioid Side Effects on the Brain & Body</p>
              </div>
            </div>
          </section>

          <div className="container mx-auto px-5">
            <div className="grid grid-cols-3 gap-7 pb-10">

              <div className="lg:col-span-2 col-span-3">
                <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7">
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-2.jpg" alt="" /> */}
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-2.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-3.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-4.jpg" alt="" /> */}
                  <div className="p-6 border-b border-gray-200 dark:border-gray-800">
                    {/* <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit
                    </h5> */}

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Over 10 million Americans misused opioids in the United States during 2018, according to the Department of Health and Human Services. A long-term addiction can lead to irreversible damage to your brain and body. Opioid addiction is long-lasting chronic disease that can cause major health, social, and financial problems.</p>

                    {/* <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam vel ea sunt molestias facere quidem! Unde, repellendus dolores neque voluptatibus id, sunt impedit, nobis molestiae quisquam aut aliquid rem sed.
                      Doloremque, voluptatum voluptas iure facere, laudantium molestiae sint quam corporis illum ad amet recusandae vitae ea, accusamus dicta! Expedita accusantium quo reprehenderit! Repellendus quod eos tenetur nesciunt deleniti, cumque enim.
                    </p> */}
                  </div>
                </div>

                {onBrain()}

                {onBody()}
              </div>

              <div className="lg:col-span-1 col-span-3">
                <RehabSearchForm />

                {/* {featuredListing()} */}
                {<FeaturedListing items={5}/>}

              </div>

            </div>
          </div>

        </>

        <ScrollToTopButton />

        <Footer />
      </PageContentContainer>

    </>
  )
}

export default EffectsOfOpioidAddiction